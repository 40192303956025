import { type RefProxy } from 'pdfjs-dist/types/src/display/api';
import * as pdfjs from 'pdfjs-dist';
import { type PDFDocumentProxy, type PDFPageProxy } from 'pdfjs-dist';
import {
    PDFArray,
    type PDFContext,
    PDFDict, PDFDocument, PDFName,
    PDFNumber, PDFRef, PDFString,
    type PDFPage
} from 'pdf-lib';
import type { UploadedFile } from '@/components/ui/input-file';
import _ from 'lodash';
import { type Tuple } from '@/types/utils';
import { type SortableItem } from '@/types/sortable';
import { clone } from '@/composables/utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

PDFArray.prototype.clone = function(context?: PDFContext) {
    // @ts-expect-error 2341
    const clone = PDFArray.withContext(context ?? this.context);
    for (let idx = 0, len = this.size(); idx < len; idx++) {
        // @ts-expect-error 2341
        clone.push(this.array[idx].clone(context ?? this.context));
    }
    return clone;
};

PDFDict.prototype.clone = function(context?: PDFContext) {
    const clone = PDFDict.withContext(context ?? this.context);
    const entries = this.entries();
    for (let idx = 0, len = entries.length; idx < len; idx++) {
        const [key, value] = entries[idx];
        clone.set(key, value.clone(context ?? this.context));
    }
    return clone;
};

const THUMBNAIL_WIDTH = 250;
const PREVIEW_WIDTH = 1250;

interface Base<T, S> { type: T; spec: S }
// These are types from the PDF 1.7 reference manual; Adobe
// Table 151 – Destination syntax
// (Coordinates origin is bottom left of page)
type XYZ = Base<'XYZ', [left: number, top: number, zoom: number]>;
type Fit = Base<'Fit', []>;
type FitH = Base<'FitH', [top: number]>;
type FitV = Base<'FitV', [left: number]>;
type FitR = Base<'FitR', [left: number, bottom: number, right: number, top: number]>;
type FitB = Base<'FitB', []>;
type FitBH = Base<'FitBH', [top: number]>;
type FitBV = Base<'FitBV', [left: number]>;

type PdfLocation = XYZ | Fit | FitH | FitV | FitR | FitB | FitBH | FitBV;

export interface PdfDestination {
    pageIndex: number;
    location: PdfLocation;
}

export interface PdfFile extends UploadedFile {
    color: string;
    proxy: PDFDocumentProxy;
    document: PDFDocument;
    pages: PdfPage[];
    outline: PdfOutlineNode[];
}

export type PdfItemType = 'page' | 'chapter';

export interface PdfItemBase {
    id: string;
    type: PdfItemType;
    file_id: string;
    thumbnail_url: string;
    preview_url?: string;
}

export interface PdfPage extends PdfItemBase {
    type: 'page';
    page_number: number;
}

export interface PdfChapter extends PdfItemBase {
    type: 'chapter';
    title: string;
    page_range: {
        start: number;
        end: number;
    };
    pages: Array<SortableItem<PdfPage>>;
    collapsed?: boolean;
}

export type PdfItem = PdfPage | PdfChapter;

export interface PdfOutlineNode {
    title: string;
    bold: boolean;
    italic: boolean;
    color: Uint8ClampedArray;
    dest: string | any[] | null;
    destination?: PdfDestination | null;
    url: string | null;
    unsafeUrl?: string;
    newWindow?: boolean;
    count?: number;
    items: PdfOutlineNode[];
}

function isRefProxy(obj: unknown): obj is RefProxy {
    return Boolean(typeof obj === 'object' && obj && 'gen' in obj && 'num' in obj);
}

async function getDestinationArray(
    doc: PDFDocumentProxy,
    dest: string | any[] | null
): Promise<any[] | null> {
    return typeof dest === 'string' ? await doc.getDestination(dest) : dest;
}

async function getDestinationRef(
    doc: PDFDocumentProxy,
    destArray: any[] | null
): Promise<RefProxy | null> {
    if (destArray && isRefProxy(destArray[0])) {
        return destArray[0];
    }
    if (destArray && typeof destArray[0] === 'number') {
        const page = await doc.getPage(destArray[0] + 1);
        return page.ref;
    }
    return null;
}

const TYPE_LENGTHS = {
    XYZ: 3,
    Fit: 0,
    FitH: 1,
    FitV: 1,
    FitR: 4,
    FitB: 0,
    FitBH: 1,
    FitBV: 1
} as const;

function isLocation<
    K extends keyof typeof TYPE_LENGTHS,
    N extends number = typeof TYPE_LENGTHS[K],
>(ty: K, obj: Base<string, number[]>): obj is Base<K, Tuple<number, N>> {
    const len = TYPE_LENGTHS[ty];
    return obj.type === ty && obj.spec.length === len;
}

function getLocation(type: string, spec: number[]): PdfLocation | null {
    const obj = { type, spec };
    if (isLocation('XYZ', obj)) return obj;
    if (isLocation('Fit', obj)) return obj;
    if (isLocation('FitH', obj)) return obj;
    if (isLocation('FitV', obj)) return obj;
    if (isLocation('FitR', obj)) return obj;
    if (isLocation('FitB', obj)) return obj;
    if (isLocation('FitBH', obj)) return obj;
    if (isLocation('FitBV', obj)) return obj;
    return null;
}

function isSpecLike(list: any[]): list is number[] {
    return list?.every(v => !isNaN(v));
}

export async function getPdfDestination(
    document: PDFDocumentProxy, destination: string | any[] | null
): Promise<PdfDestination | null> {
    const destArray = await getDestinationArray(document, destination);
    const destRef = await getDestinationRef(document, destArray);
    if (!destRef || !destArray) return null;

    const pageIndex = await document.getPageIndex(destRef);
    const name = destArray[1].name;
    const rest = destArray.slice(2);
    const location = isSpecLike(rest) ? getLocation(name, rest) : null;

    return { pageIndex, location: location ?? { type: 'Fit', spec: [] } };
}

async function traverseOutline(document: PDFDocumentProxy, node: PdfOutlineNode) {
    node.destination = await getPdfDestination(document, node.dest);
    for (const item of node.items) {
        await traverseOutline(document, item);
    }
}

export async function getDocumentOutline(document: PDFDocumentProxy): Promise<PdfOutlineNode[]> {
    const items = (await document.getOutline()) ?? [];
    for (const item of items) {
        await traverseOutline(document, item);
    }
    return items;
}

export async function loadDocument(data: Blob) {
    const [proxy, document] = await Promise.all([
        data.arrayBuffer().then(buffer => pdfjs.getDocument(buffer).promise),
        data.arrayBuffer().then(buffer => PDFDocument.load(buffer, { ignoreEncryption: true }))
    ]);
    return {
        proxy,
        document
    };
}

export async function renderPageThumbnail(
    page: PDFPageProxy,
    options?: { width: number }
): Promise<string> {
    const vp = page.getViewport({ scale: 1 });
    const canvas = document.createElement('canvas');
    const aspect = vp.height / vp.width;
    canvas.width = options?.width ?? THUMBNAIL_WIDTH;
    canvas.height = canvas.width * aspect;
    const scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
    return await page.render({
        canvasContext: canvas.getContext('2d') as CanvasRenderingContext2D,
        viewport: page.getViewport({ scale })
    }).promise
        .catch((err) => console.trace(err))
        .then(() => canvas.toDataURL('image/png'));
}

export async function generatePdfPages(file: PdfFile) {
    const thumbnails: PdfPage[] = [];
    for (let pageNumber = 1; pageNumber <= file.proxy.numPages; pageNumber++) {
        const page = await file.proxy.getPage(pageNumber);
        // page.
        const thumbnail_url = await renderPageThumbnail(page);
        thumbnails.push({
            id: `${file.id}_p${pageNumber}`,
            type: 'page',
            file_id: file.id,
            page_number: pageNumber,
            thumbnail_url
        });
    }
    return thumbnails;
}

export async function generatePdfChapters(file: PdfFile): Promise<{
    chapters: PdfChapter[];
    pages: PdfPage[];
    outline: PdfOutlineNode[];
}> {
    const items = await getDocumentOutline(file.proxy);
    const pages = await generatePdfPages(file);
    let pageIndex = -1;
    let firstIteration = true;
    const chapters: PdfChapter[] = [];
    const setLastChapterEndPage = (pageNumber: number) => {
        const last = _.last(chapters);
        if (last) {
            last.thumbnail_url = pages[last.page_range.start - 1].thumbnail_url;
            last.page_range.end = pageNumber;
            last.pages = pages.slice(last.page_range.start - 1, pageNumber);
        }
    };
    for (const item of items) {
        if (!item.destination) { continue; }
        if (pageIndex !== item.destination.pageIndex) {
            if (firstIteration) {
                firstIteration = false;
                if (item.destination.pageIndex !== 0) {
                    chapters.push({
                        id: `${file.id}_c${chapters.length}`,
                        type: 'chapter',
                        file_id: file.id,
                        title: 'Prologue',
                        page_range: {
                            start: 1,
                            end: 1
                        },
                        thumbnail_url: '',
                        pages: []
                    });
                }
            }
            setLastChapterEndPage(item.destination.pageIndex);
            chapters.push({
                id: `${file.id}_c${chapters.length}`,
                type: 'chapter',
                file_id: file.id,
                title: item.title,
                page_range: {
                    start: item.destination.pageIndex + 1,
                    end: item.destination.pageIndex + 1
                },
                thumbnail_url: '',
                pages: []
            });
            pageIndex = item.destination.pageIndex;
        }
    }
    setLastChapterEndPage(file.proxy.numPages);
    return { chapters, pages, outline: items };
}

function isLinkAnnotation(annotation: PDFDict): boolean {
    return annotation.get(PDFName.of('Subtype'))?.toString() === '/Link';
}

function getDestRef(dest: PDFArray): PDFRef | undefined {
    const ref = dest.get(0);
    if (ref && ref instanceof PDFRef) {
        return ref;
    }
    return undefined;
}

function setDestRef(dest: PDFArray, ref: PDFRef) {
    dest.set(0, ref);
}

function getLinkDest(link: PDFDict): PDFArray | undefined {
    let dest = link.get(PDFName.of('Dest'));
    if (dest && dest instanceof PDFArray) {
        return dest;
    }
    const action = link.get(PDFName.of('A'));
    if (action instanceof PDFDict && action.get(PDFName.of('S'))?.toString() === '/GoTo') {
        dest = action.get(PDFName.of('D'));
        if (dest && dest instanceof PDFArray) {
            return dest;
        }
    }
    return undefined;
}

function getLinkRef(link: PDFDict): PDFRef | undefined {
    const dest = getLinkDest(link);
    if (dest) {
        return getDestRef(dest);
    }
    return undefined;
}

function setLinkRef(link: PDFDict, ref: PDFRef) {
    const dest = getLinkDest(link);
    if (dest) {
        setDestRef(dest, ref);
    }
}

function patchPdfLinks(
    sourceFile: PdfFile,
    targetDocument: PDFDocument,
    targetPages: PdfPage[],
    sourcePage: PDFPage,
    targetPage: PDFPage
) {
    const sourceAnnotations = sourcePage.node.Annots();
    if (!sourceAnnotations) {
        return;
    }
    for (let i = 0; i < sourceAnnotations.size(); i++) {
        try {
            const annotation = sourceAnnotations.lookupMaybe(i, PDFDict);
            if (!annotation || !isLinkAnnotation(annotation)) {
                continue;
            }
            const ref = getLinkRef(annotation);
            if (!ref) {
                continue;
            }
            const destSourcePageIndex = sourceFile.document.getPages()
                .findIndex(p => p.ref.toString() === ref.toString());
            if (destSourcePageIndex < 0) {
                continue;
            }
            const destSourcePage = sourceFile.pages[destSourcePageIndex];
            const destTargetPageIndex = targetPages.findIndex(p =>
                p.file_id === destSourcePage.file_id &&
                p.page_number === destSourcePage.page_number
            );
            if (destTargetPageIndex < 0) {
                continue;
            }
            const targetAnnotation = annotation.clone(targetDocument.context);
            const targetDestRef = targetDocument.getPage(destTargetPageIndex).ref;
            setLinkRef(targetAnnotation, targetDestRef);
            targetPage.node.Annots()?.push(targetAnnotation);
        } catch (err) {
            console.error(err);
        }
    }
}

function mergePdfOutlines(
    document: PDFDocument,
    files: PdfFile[],
    pages: PdfPage[]
) {
    interface PdfOutlineNodeWithTarget extends PdfOutlineNode {
        items: PdfOutlineNodeWithTarget[];
        dict?: PDFDict;
        ref?: PDFRef;
        targetPageIndex?: number;
    }

    const traverse = <T extends PdfOutlineNode>(
        node: T,
        parent: T | null,
        action: (node: T, parent: T | null, type: 'preorder' | 'postorder') => void
    ) => {
        action(node, parent, 'preorder');
        node.items.forEach((item) => {
            traverse(item as T, node, action);
        });
        action(node, parent, 'postorder');
    };

    let mergedOutline: PdfOutlineNodeWithTarget[] = [];
    for (const file of files) {
        const outline = clone(file.outline);
        for (const node of outline) {
            traverse(node, null, (node, _, type) => {
                if (type === 'postorder') {
                    return;
                }
                const pageIndex = pages.findIndex(p =>
                    p.file_id === file.id &&
                    p.page_number - 1 === node.destination?.pageIndex
                );
                if (pageIndex >= 0) {
                    (node as PdfOutlineNodeWithTarget).targetPageIndex = pageIndex;
                }
            });
        }
        mergedOutline.push(outline as any);
    }

    const outlineDict = document.context.obj({
        Type: 'Outlines'
    });
    const outlineDictRef = document.context.register(outlineDict);
    mergedOutline = mergedOutline.flat();
    mergedOutline = mergedOutline.filter(n => typeof n.targetPageIndex === 'number');
    mergedOutline.sort((a, b) => Number(a.targetPageIndex) - Number(b.targetPageIndex));
    for (const node of mergedOutline) {
        traverse<PdfOutlineNodeWithTarget>(node, null, (node, parent, type) => {
            if (typeof node.targetPageIndex !== 'number') { return; }
            if (type === 'preorder') {
                const targetPage = document.getPage(node.targetPageIndex);
                node.dict = document.context.obj({
                    Title: PDFString.of(node.title),
                    Parent: parent ? parent.ref : outlineDictRef,
                    Dest: [
                        targetPage.ref,
                        ...(node.destination?.location
                            ? [
                                node.destination.location.type,
                                ...node.destination.location.spec
                            ]
                            : ['XYZ', null, targetPage.getHeight(), null]
                        )
                    ]
                });
                node.ref = document.context.register(node.dict);
            } else if (type === 'postorder') {
                const first = node.items[0];
                const last = node.items[node.items.length - 1];
                if (first && last) {
                    node.dict?.set(PDFName.of('First'), first.ref as PDFRef);
                    node.dict?.set(PDFName.of('Last'), last.ref as PDFRef);
                    const count = (last.ref as PDFRef).objectNumber - (first.ref as PDFRef).objectNumber + 1;
                    node.dict?.set(PDFName.of('Count'), PDFNumber.of(count));
                }
            }
        });
    }
    for (let i = 0; i < mergedOutline.length; i++) {
        const node = mergedOutline[i];
        const next = mergedOutline[i + 1];
        if (next) {
            node.dict?.set(PDFName.of('Next'), next.ref as PDFRef);
            next.dict?.set(PDFName.of('Prev'), node.ref as PDFRef);
        }
        traverse<PdfOutlineNodeWithTarget>(node, null, (node, parent, type) => {
            if (type === 'preorder') {
                for (let i = 0; i <= node.items.length; i++) {
                    const current = node.items[i];
                    const next = node.items[i + 1];
                    if (next) {
                        current.dict?.set(PDFName.of('Next'), next.ref as PDFRef);
                        next.dict?.set(PDFName.of('Prev'), current.ref as PDFRef);
                    }
                }
            }
        });
    }
    const first = mergedOutline[0];
    const last = mergedOutline[mergedOutline.length - 1];
    if (first && last) {
        outlineDict.set(PDFName.of('First'), first.ref as PDFRef);
        outlineDict.set(PDFName.of('Last'), last.ref as PDFRef);
    }
    outlineDict.set(PDFName.of('Count'), PDFNumber.of(mergedOutline.length));
    document.catalog.set(PDFName.of('Outlines'), outlineDictRef);
}

export async function generatePdfFromPages(files: PdfFile[], items: PdfItem[]) {
    const result = await PDFDocument.create();
    const pages = items.reduce<PdfPage[]>(
        (arr, item) => [...arr, ...(item.type === 'page' ? [item] : item.pages)],
        []
    );
    for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const page = pages[pageIndex];
        const fileIndex = files.findIndex(f => f.id === page.file_id);
        if (fileIndex < 0) { continue; }
        const [copiedPage] = await result.copyPages(
            files[fileIndex].document,
            [page.page_number - 1]
        );
        result.addPage(copiedPage);
    }
    for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const page = pages[pageIndex];
        const fileIndex = files.findIndex(f => f.id === page.file_id);
        if (fileIndex < 0) { continue; }
        const file = files[fileIndex];
        const sourcePage = file.document.getPage(page.page_number - 1);
        const targetPage = result.getPage(pageIndex);
        patchPdfLinks(file, result, pages, sourcePage, targetPage);
    }
    // const chapters = items.filter((item) => item.type === 'chapter') as PdfChapter[];
    mergePdfOutlines(result, files, pages);
    // createPdfOutline(result, chapters, pages);
    return result;
}

const PDF_PREVIEWS: Record<string, Promise<string>> = {};

export async function generatePreviewForPage(file: PdfFile, page: PdfPage) {
    if (file.id !== page.file_id) {
        throw new Error('page is not part of specified file');
    }
    if (PDF_PREVIEWS[page.id] != null) {
        return await PDF_PREVIEWS[page.id];
    }
    const pageProxy = await file.proxy.getPage(page.page_number);
    const promise = renderPageThumbnail(pageProxy, { width: PREVIEW_WIDTH });
    PDF_PREVIEWS[page.id] = promise;
    return await promise;
}
