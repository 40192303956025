import React, { useState } from 'react';
import { type Language, type TranslationObject, useTranslation } from '@/composables/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNeoForm } from '@/composables/neoform';
import { getDecryptFormField, getGeneratedField } from '@/composables/api';
import { toast } from 'react-toastify';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { ButtonInfo } from '@/components/ui/button-info';
import { cn } from '@/lib/utils';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { EyeOpenIcon } from '@radix-ui/react-icons';
import { ButtonAsync } from '@/components/ui/button-async';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import useHtml from '@/composables/html';
import { useError } from '@/composables/error';
import { RequiredMarker } from '../ui/required-marker';
import { OptionalMarker } from '../ui/optional-marker';

export enum NeoFormTitleType {
    DEFAULT = 'default',
    HEADER = 'header',
    COLLAPSIBLE_HEADER = 'collapsible-header',
    LABEL = 'label'
}

const TITLE_ELEMENTS = {
    [NeoFormTitleType.DEFAULT]: 'h5',
    [NeoFormTitleType.HEADER]: 'h5',
    [NeoFormTitleType.COLLAPSIBLE_HEADER]: 'h5',
    [NeoFormTitleType.LABEL]: Label
};

interface Props {
    lang: Language;
    // TODO: Remove this prop
    visible: boolean;
    name: string;
    titleType?: NeoFormTitleType;
    title?: TranslationObject | string;
    description?: TranslationObject | string;
    info?: TranslationObject | string;
    generate?: {
        endpoint: string;
        prompt: string;
    };
    for?: string;
    required?: boolean;
    subtitle?: string;
}

export function NeoFormComponentPreface(props: Props) {
    const { t, to } = useTranslation('neoform');
    const { id, form, shouldEncrypt, component } = useNeoForm();
    const { handleNetworkError } = useError();
    const [isGenerateLoading, setIsGenerateLoading] = useState(false);
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const titleType = props.titleType || NeoFormTitleType.DEFAULT;
    const title = `${to(props.title, '', props.lang)}`;
    const description = to(props.description, '', props.lang);
    const sanitizedDescription = useHtml(description);
    const info = to(props.info, '', props.lang);
    const show = !!title || !!description;
    if (!props.visible || !show) {
        return null;
    }

    function handleGenerate() {
        if (!form?.info || !props.generate) {
            return;
        }
        setIsGenerateLoading(true);
        return form.saveForm()
            .then(() => getGeneratedField(props.generate?.endpoint ?? '', form.info?.task_id ?? ''))
            .then((res) => form?.set(props.name, res.data.med_generic))
            .catch(() => toast(t('messages.error'), { type: 'error' }))
            .finally(() => setIsGenerateLoading(false));
    }

    function handleDecryptField() {
        return getDecryptFormField({
            form_data_id: form?.info?.task_id ?? '',
            field_path: id
        })
            .then((res) => {
                form?.set(id, res.data.value);
                component?.setEncrypted?.(false);
            })
            .catch(handleNetworkError);
    }
    const labelMarker: 'required' | 'optional' | 'both' = form?.layout?.header?.config?.label_marker ?? '';
    const renderMarker = () => {
        const markerMapping = {
            required: props.required && <RequiredMarker />,
            optional: !props.required && <OptionalMarker />,
            both: props.required ? <RequiredMarker /> : <OptionalMarker />
        };
        return markerMapping[labelMarker] ?? null;
    };

    return (
        <HoverCard>
            <HoverCardTrigger className='tw-w-full'>
                <div className={cn('tw-mb-1', `neo-title-${titleType}`)}>
                    {title &&
                        <div className={cn('tw-flex tw-items-center')}>
                            {React.createElement(TITLE_ELEMENTS[titleType],
                                { htmlFor: props.for }, title, renderMarker())}
                            {props.subtitle && (
                                <span className='tw-ml-2 tw-font-semibold tw-text-gray-500'>
                                    {props.subtitle}
                                </span>
                            )}
                            {info && <ButtonInfo className="tw-ml-3" text={info} />}
                            {shouldEncrypt &&
                                <ButtonAsync
                                    size="icon" className="tw-ml-3"
                                    disabled={!component?.encrypted}
                                    onClick={handleDecryptField}
                                >
                                    <EyeOpenIcon />
                                </ButtonAsync>
                            }
                            {props.generate &&
                                <Button
                                    type="button"
                                    className="tw-ml-auto"
                                    disabled={isGenerateLoading}
                                    onClick={handleGenerate}
                                >
                                    {isGenerateLoading
                                        ? <Spinner
                                            className="tw-mr-2 tw-text-white"
                                        />
                                        : <FontAwesomeIcon
                                            className="tw-mr-2"
                                            size="lg"
                                            icon={faWandMagicSparkles}
                                        />
                                    }
                                    {t('generate-ai')}
                                </Button>
                            }
                        </div>
                    }
                    {description &&
                        <div
                            className="tw-mb-2 tw-text-sm tw-max-w-none tw-prose"
                            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                        />
                    }
                </div>
            </HoverCardTrigger>
            {shouldEncrypt &&
                <HoverCardContent side="top" align="start">
                    {t('encrypt.content')}
                </HoverCardContent>
            }
        </HoverCard>
    );
}
