import type { View } from '@/types/api/views';
import axios from 'axios';
import { useTranslation } from '@/composables/translation';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolderOpen, faListCheck, faUser } from '@fortawesome/free-solid-svg-icons';

export function useViews() {
    const { to } = useTranslation();
    function buildViewUrl(view: View) {
        return axios.getUri({
            baseURL: view.definition.url ?? '/',
            params: {
                ...view.definition.params,
                tab: to(view.title)
            }
        });
    }
    return {
        buildViewUrl
    };
}

export function getViewIcon(view: View): IconProp | undefined {
    switch (view.definition.url) {
    case '/dashboard':
        return faFolderOpen;
    case '/clients':
        return faUser;
    case '/forms':
        return faListCheck;
    }
    return undefined;
}
