import { type Tab } from '@/types/tabs';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface State {
    tabs: Tab[];
    history: Tab[];
}

interface Actions {
    addTab: (tab: Tab) => void;
    removeTab: (url: string) => Tab | undefined;
    reset: () => void;
}

export const useTabStore = create<State & Actions>()(
    devtools(
        persist(
            (set, get) => ({
                tabs: [] as Tab[],
                history: [] as Tab[],
                addTab: (tab) => {
                    const currentTabs = get().tabs;
                    const tabExists = currentTabs.some((t) => t.url === tab.url);
                    if (!tabExists) {
                        set({
                            tabs: [...currentTabs, tab]
                        });
                    }
                },
                removeTab: (url): Tab | undefined => {
                    const newTabs = [...get().tabs];
                    const tabIndex = newTabs.findIndex((tab) => tab.url === url);
                    if (newTabs.length > 1 && tabIndex >= 0) {
                        const removedTab = newTabs.splice(tabIndex, 1)[0];
                        const currentHistory = get().history;
                        const newHistory = [removedTab, ...currentHistory]
                            .slice(0, 8)
                            .filter((tab, index, self) => index === self.findIndex((t) => t.url === tab.url));
                        set({ tabs: newTabs, history: newHistory });
                        return newTabs[tabIndex] ?? newTabs[tabIndex - 1];
                    }
                    const tabs = get().tabs;
                    return tabs[tabIndex] ?? tabs[tabIndex - 1];
                },
                reset: () => {
                    const currentTabs = get().tabs;
                    const currentHistory = get().history;
                    const newHistory = [...currentTabs, ...currentHistory]
                        .slice(0, 8)
                        .filter((tab, index, self) => index === self.findIndex((t) => t.url === tab.url) &&
                        tab.url !== '/dashboard');
                    set({ tabs: [], history: newHistory });
                }
            }),
            {
                name: 'tab'
            }
        )
    )
);
