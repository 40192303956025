import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const Sheet = SheetPrimitive.Root;

const SheetTrigger = SheetPrimitive.Trigger;

const SheetClose = SheetPrimitive.Close;

const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({
    className,
    ...props
}, ref) => (
    <SheetPrimitive.Overlay
        className={cn(
            'tw-fixed tw-inset-0 tw-z-50 tw-bg-black/80 tw- data-[state=open]:tw-animate-in',
            'data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0',
            className
        )}
        {...props}
        ref={ref}
    />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
    'tw-fixed tw-z-50 tw-gap-4 tw-bg-background tw-p-6 tw-shadow-lg tw-transition tw-ease-in-out ' +
    'data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-duration-300 ' +
    'data-[state=open]:tw-duration-500',
    {
        variants: {
            side: {
                top: 'tw-inset-x-0 tw-top-0 tw-border-b data-[state=closed]:tw-slide-out-to-top ' +
                    'data-[state=open]:tw-slide-in-from-top',
                bottom:
                    'tw-inset-x-0 tw-bottom-0 tw-border-t data-[state=closed]:tw-slide-out-to-bottom ' +
                    'data-[state=open]:tw-slide-in-from-bottom',
                left: 'tw-inset-y-0 tw-left-0 tw-h-full tw-w-3/4 tw-border-r ' +
                    'data-[state=closed]:tw-slide-out-to-left data-[state=open]:tw-slide-in-from-left sm:tw-max-w-sm',
                right:
                    'tw-inset-y-0 tw-right-0 tw-h-full tw-w-3/4 tw-border-l ' +
                    'data-[state=closed]:tw-slide-out-to-right data-[state=open]:tw-slide-in-from-right sm:tw-max-w-sm'
            }
        },
        defaultVariants: {
            side: 'right'
        }
    }
);

interface SheetContentProps
    extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
        VariantProps<typeof sheetVariants> {
}

const SheetContent = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Content>,
    SheetContentProps
>(({
    side = 'right',
    className,
    children,
    ...props
}, ref) => (
    <SheetPortal>
        <SheetOverlay/>
        <SheetPrimitive.Content
            ref={ref}
            className={cn(sheetVariants({ side }), className)}
            {...props}
        >
            {children}
            <SheetPrimitive.Close
                className={cn(
                    'tw-absolute tw-right-4 tw-top-4 tw-rounded-sm tw-opacity-70 tw-ring-offset-background',
                    'tw-transition-opacity hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2',
                    'focus:tw-ring-ring focus:tw-ring-offset-2 disabled:tw-pointer-events-none',
                    'data-[state=open]:tw-bg-secondary'
                )}
            >
                <Cross2Icon className="tw-h-4 tw-w-4"/>
                <span className="tw-sr-only">Close</span>
            </SheetPrimitive.Close>
        </SheetPrimitive.Content>
    </SheetPortal>
));
SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetHeader = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            'tw-flex tw-flex-col tw-space-y-2 tw-text-center sm:tw-text-left',
            className
        )}
        {...props}
    />
);
SheetHeader.displayName = 'SheetHeader';

const SheetFooter = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            'tw-flex tw-flex-col-reverse sm:tw-flex-row sm:tw-justify-end sm:tw-space-x-2',
            className
        )}
        {...props}
    />
);
SheetFooter.displayName = 'SheetFooter';

const SheetTitle = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({
    className,
    ...props
}, ref) => (
    <SheetPrimitive.Title
        ref={ref}
        className={cn('tw-text-lg tw-font-semibold tw-text-foreground', className)}
        {...props}
    />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

const SheetDescription = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({
    className,
    ...props
}, ref) => (
    <SheetPrimitive.Description
        ref={ref}
        className={cn('tw-text-sm tw-text-muted-foreground', className)}
        {...props}
    />
));
SheetDescription.displayName = SheetPrimitive.Description.displayName;

export {
    Sheet,
    SheetPortal,
    SheetOverlay,
    SheetTrigger,
    SheetClose,
    SheetContent,
    SheetHeader,
    SheetFooter,
    SheetTitle,
    SheetDescription
};
