import React, { useCallback } from 'react';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { List } from '@/components/neoform/complex/List';
import { DynamicComponent } from '@/components/neoform/complex/DynamicComponent';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { Button } from '@/components/ui/button';

interface Props {
    componentCustom: string;
    addButtonText?: TranslationObject | string;
    fixedNumEntries?: number;
    noAdd?: boolean;
    noClose?: boolean;
}

export function DynamicList({
    fixedNumEntries,
    noAdd = false,
    noClose = false,
    ...props
}: NeoFormComponentProps & Props) {
    const { to } = useTranslation();
    const addButtonText = to(props.addButtonText);
    const DynamicComponentList = useCallback(List(DynamicComponent, {
        renderAddButton: addButtonText
            ? (props) => (
                <Button
                    type="button" variant="outline"
                    className="tw-text-primary tw-w-full"
                    {...props}
                >
                    {addButtonText}
                </Button>
            )
            : undefined,
        fixedNumEntries,
        noAdd,
        noClose,
        isDynamic: true
    }), [props.addButtonText, fixedNumEntries, noAdd, noClose]);
    return (
        <DynamicComponentList
            {...props}
            name={props.name}
            componentName={props.componentCustom}
            componentCustom={props.componentCustom}
        />
    );
}
