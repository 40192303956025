import * as React from 'react';

import { cn } from '@/lib/utils';

export interface TextareaProps
    extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    error?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({
        className,
        error,
        ...props
    }, ref) => {
        return (
            <textarea
                className={cn(
                    'tw-flex tw-min-h-[60px] tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-transparent',
                    'tw-px-3 tw-py-2 tw-text-sm tw-shadow-sm placeholder:tw-text-muted-foreground',
                    'focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring',
                    'disabled:tw-cursor-not-allowed disabled:tw-opacity-50 disabled:tw-bg-slate-100',
                    'aria-invalid:tw-border-destructive aria-invalid:tw-border-2',
                    'aria-invalid:focus-visible:tw-ring-destructive',
                    className
                )}
                ref={ref}
                {...props}
                {...(error && { 'aria-invalid': 'true' })}
            />
        );
    }
);
Textarea.displayName = 'Textarea';

export { Textarea };
