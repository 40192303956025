import React from 'react';
import { cn } from '@/lib/utils';
import { useDropzone } from 'react-dropzone';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useControllableState } from '@/composables/controllable';
import ObjectId from 'bson-objectid';
import { useTranslation } from '@/composables/translation';
import { Spinner } from '@/components/ui/spinner';

export interface UploadedFile {
    id: string;
    filename: string;
    content_type: string;
    data: Blob;
    url?: string;
}

interface Props {
    id?: string;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    children?: React.ReactNode;
    accept?: Record<string, string[]>;
    value?: UploadedFile[];
    onChange?: React.Dispatch<UploadedFile[]>;
    maxFiles?: number;
    multiple?: boolean;
}

const MESSAGE_DEFAULT = {
    en: '<b>Click to upload</b> or drag and drop files',
    fr: '<b>Cliquez pour téléverser</b> ou faites glisser et déposez des fichiers'
};

export function InputFile(props: Props) {
    const { to } = useTranslation();
    const [files, setFiles] = useControllableState<UploadedFile[]>([], props.value, props.onChange as any);
    const { getRootProps, getInputProps } = useDropzone({
        accept: props.accept,
        multiple: props.multiple,
        maxFiles: props.maxFiles,
        onDropAccepted: (acceptedFiles: File[]) => {
            setFiles(files.concat(acceptedFiles.map((f) => ({
                id: ObjectId().toHexString(),
                filename: f.name,
                content_type: f.type,
                data: f
            }))));
        }
    });
    const { onClick, ...rootProps } = getRootProps();
    const inputProps = getInputProps();
    return (
        <div className={cn(
            'tw-relative',
            props.className
        )}>
            <label
                className={cn(
                    'tw-self-stretch tw-p-16 tw-rounded-lg tw-min-h-[220px]',
                    'tw-border-dashed tw-border-2 tw-border-slate-300',
                    'hover:tw-bg-slate-100 hover:tw-border-slate-600',
                    'tw-flex tw-flex-col tw-items-center tw-justify-center',
                    'tw-cursor-pointer tw-text-slate-600 tw-text-center',
                    'aria-disabled:tw-pointer-events-none',
                    'aria-disabled:tw-opacity-40'
                )}
                htmlFor={props.id}
                {...rootProps}
                // eslint-disable-next-line
                aria-disabled={props.disabled || props.loading}
            >
                <input id={props.id} type="file" {...inputProps} />
                {!props.loading && <>
                    <FontAwesomeIcon
                        className="tw-text-5xl tw-text-slate-400 tw-mb-4"
                        icon={faCloudArrowUp}
                    />
                    {props.children
                        ? props.children
                        : <div dangerouslySetInnerHTML={{ __html: to(MESSAGE_DEFAULT) }}/>}
                </>}
            </label>
            {props.loading &&
                <div className={cn(
                    'tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center'
                )}>
                    <Spinner size="lg" className="tw-text-primary" />
                </div>
            }
        </div>
    );
}
