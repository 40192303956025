import React from 'react';
import { Outlet, redirectDocument } from 'react-router-dom';
import axios from 'axios';
import { useUserStore } from '@/store/user';
import { NavBar } from '@/components/NavBar';
import { getDefaultOrg } from '@/composables/org';
import { pushReturnUrl } from '@/composables/utils';
import { type LoaderFunction } from '@remix-run/router/utils';
import { Footer } from '@/components/Footer';
import { cn } from '@/lib/utils';
import { SidebarProvider } from '@/components/ui/sidebar';

interface PrivateLoaderOptions {
    user_type: 'admin' | 'customer';
    login_url: string;
}

export function PrivateLoader(opts: PrivateLoaderOptions): LoaderFunction {
    return ({ request }) => {
        const {
            token,
            user,
            logout
        } = useUserStore.getState();
        const url = new URL(request.url);
        const pathname = url.pathname + url.search;
        const org = getDefaultOrg(url);
        if (!token || !user?.user_id || user.type !== opts.user_type) {
            logout();
            pushReturnUrl(pathname);
            const uri = axios.getUri({
                baseURL: opts.login_url,
                params: {
                    org: (url.searchParams.get('org') ?? org) || undefined
                }
            });
            return redirectDocument(uri);
        }
        return null;
    };
}

export const PrivateLayoutLoader = PrivateLoader({
    user_type: 'admin',
    login_url: `${import.meta.env.VITE_API_URL}/v1/sso/login`
});

interface Props {
    fixed?: boolean;
}

export function PrivateLayout(props: Props) {
    return (
        <div className="tw-flex-1 tw-flex tw-flex-col tw-mt-[60px] print:tw-mt-0">
            <NavBar/>
            <SidebarProvider className={cn(
                'tw-flex-1 tw-flex tw-flex-col !tw-min-h-0',
                props.fixed && 'tw-max-h-[calc(100vh_-_104px)]'
            )}>
                <Outlet/>
            </SidebarProvider>
            <Footer/>
        </div>
    );
}
