import React, { useState } from 'react';
import {
    type UploadedFile,
    type UploadedFileBundle
} from '@/components/neoform/inputs/InputDropContainer';
import { FileItem } from '@/components/neoform/helper/FileItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { ReactSortable } from 'react-sortablejs';
import { clone } from '@/composables/utils';
import { useTranslation } from '@/composables/translation';
import { TextFieldEdit } from '@/components/TextFieldEdit';
import { Checkbox } from '@/components/ui/checkbox';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface Props {
    files: UploadedFile[];
    bundle: UploadedFileBundle;
    index: number;
    amend?: boolean;
    group?: string;
    readonly?: boolean;
    onChangeBundle?: (bundle: UploadedFileBundle) => void;
    onChangeFile?: (id: string, file: UploadedFile) => void;
    onRemoveBundle?: () => void;
    onRemoveFile?: (id: string) => void;
}

export function FileBundle(props: Props) {
    const {
        ct,
        t
    } = useTranslation('neoform.drop-container');
    const [isCollapsed, setIsCollapsed] = useState(false);
    const fileArr = props.bundle.fileList
        .map(fid => props.files.find(f => f._id === fid))
        .filter(f => f) as UploadedFile[];
    const list = clone(fileArr) as any[];
    return (
        <section>
            <div className={cn(
                'tw-border-solid tw-border tw-border-slate-300 tw-p-3',
                'tw-mb-1 tw-rounded-lg tw-flex tw-gap-2 tw-items-center'
            )}>
                <div
                    className="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
                    onClick={() => setIsCollapsed(v => !v)}
                >
                    <Button type="button" variant="ghost" size="icon" className="!tw-rounded-full">
                        <FontAwesomeIcon
                            width="1em"
                            icon={isCollapsed ? faChevronRight : faChevronDown}
                        />
                    </Button>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="tw-h-[20px]">
                                <Checkbox
                                    checked={props.bundle.include}
                                    onClick={e => e.stopPropagation()}
                                    onCheckedChange={(checked) => props.onChangeBundle?.({
                                        ...props.bundle,
                                        include: Boolean(checked)
                                    })}
                                />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent>
                            {t('select')}
                        </TooltipContent>
                    </Tooltip>
                    <TextFieldEdit
                        value={props.bundle.name}
                        onChange={(value) => props.onChangeBundle?.({
                            ...props.bundle,
                            name: value
                        })}
                        formatter={(name) =>
                            <span className="tw-text-primary">
                                {name} ({props.bundle.fileList.length})
                            </span>
                        }
                    />
                </div>
                {props.amend &&
                    <div className="!tw-ml-2 tw-flex tw-items-center tw-gap-2">
                        <Checkbox
                            id={`${props.bundle.id}-amend`}
                            checked={props.bundle.amend ?? false}
                            onCheckedChange={(checked) => props.onChangeBundle?.({
                                ...props.bundle,
                                amend: Boolean(checked)
                            })}
                        />
                        <Label htmlFor={`${props.bundle.id}-amend`}>
                            {ct('amend')}
                        </Label>
                    </div>
                }
                <span className="tw-ml-auto tw-text-muted-foreground tw-uppercase">
                    {t('bundle')}
                </span>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            type="button" variant="ghost" size="icon"
                            className="!tw-rounded-full tw-text-destructive"
                            onClick={() => props.onRemoveBundle?.()}
                        >
                            <FontAwesomeIcon icon={faCircleXmark}/>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        {t('remove')}
                    </TooltipContent>
                </Tooltip>
            </div>
            <div className={cn('tw-pl-4 md:tw-pl-10', isCollapsed && 'tw-hidden')}>
                <ReactSortable
                    group={props.group}
                    animation={150}
                    className="tw-flex tw-flex-col tw-gap-1"
                    list={list}
                    setList={(list) => props.onChangeBundle?.({
                        ...props.bundle,
                        fileList: list.map(i => i._id)
                    })}
                >
                    {fileArr.map(f =>
                        <FileItem
                            key={f._id}
                            file={f}
                            onChange={(value) => props.onChangeFile?.(f._id, value)}
                            onRemove={() => props.onRemoveFile?.(f._id)}
                        />
                    )}
                </ReactSortable>
            </div>
        </section>
    );
}
