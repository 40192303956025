import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendar,
    faEraser, faEye, faList, faPaperclip,
    faPaperPlane, faSave, faTrash,
    faUpload
} from '@fortawesome/free-solid-svg-icons';
import { type Language, useTranslation } from '@/composables/translation';
import { FolderContext } from '@/pages/psj/Folder';
import { toast } from 'react-toastify';
import { postAddNote, postUploadFile } from '@/composables/api';
import { useFolderStore } from '@/store/folders';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { Combobox } from '@/components/ui/combobox';
import { useUserStore } from '@/store/user';
import { useError } from '@/composables/error';
import { type PostAddNoteDto } from '@/types/api/note';
import { getInputFileList } from '@/composables/utils';
import { TextFieldEdit } from '@/components/TextFieldEdit';
import { BadgeCounter } from '@/components/ui/badge-counter';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog';
import { EnvelopeOpenIcon } from '@radix-ui/react-icons';
import { type Macro } from '@/types/api/macro';
import { type NextStepInstance } from '@/types/api/process';
import { Liquid } from 'liquidjs';
import axios from 'axios';
import { type File } from '@/types/api/files';
import { DateTime } from 'luxon';
import { useAssignment } from '@/composables/assignment';
import { useOverrideConfig } from '@/composables/override';
import { DropZoneWrapper } from '@/components/utils/DropZoneWrapper';
import { FilePreview } from '@/components/neoform/helper/FilePreview';
import { type CaseStatus } from '@/types/folder';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { NoteEmailDialog } from '@/components/psj/notes/NoteEmailDialog';
import { toolbarOptions } from '@/components/utils/toolbar-options';
import { ButtonAsync } from '@/components/ui/button-async';
import { STATUS_INFO } from '@/components/psj/StatusChip';
import { MenuButton } from '@/components/MenuButton';
import { ExistingFileAttachmentDialog } from './ExistingFileAttachmentDialog';

type PreviewFile = {
    mimeType: string;
    url?: string;
    name: string;
  } | null;

const engine = new Liquid();

export function NoteEdit() {
    const folder = useContext(FolderContext);
    const { handleNetworkError } = useError();
    const { to, ct, t } = useTranslation('psj.notes');
    const { t: t_status } = useTranslation('folder.status');
    const { lang, user } = useUserStore((state) => ({
        lang: state.lang,
        user: state.user
    }));
    const override = useOverrideConfig();

    const fileInput = useRef<HTMLInputElement | null>(null);
    const [type, setType] = useState<'private' | 'public' | 'email'>(
        override?.is_customer_portal ? 'public' : 'private'
    );
    const [status, setStatus] = useState<CaseStatus | null>(null);
    const [selectedMacro, setSelectedMacro] = useState<Macro | null>(null);
    const [selectedNextStep, setSelectedNextStep] = useState<NextStepInstance | null>(null);
    const [selectedAssignId, setSelectedAssignId] =
        useState<string | null>(user?.user_id ?? null);
    const [note, setNote] = useState('');
    const [notePostLoading, setNotePostLoading] = useState(false);
    const [attachments, setAttachments] = useState<File[]>([]);
    const [emailSubject, setEmailSubject] = useState<string>('');
    const [emailsTo, setEmailsTo] = useState<string[] | null>(null);
    const [emailsCc, setEmailsCc] = useState<string[] | null>(null);
    const [macroLang, setMacroLang] = useState<Language>(lang ?? 'fr');
    const [previewFile, setPreviewFile] = useState<PreviewFile>(null);
    const [openBifurcation, setOpenBifurcation] = useState(false);
    const [openExistingFile, setOpenExistingFile] = useState(false);
    const isPublic = type === 'public' || type === 'email';
    const numEmails = ((emailsTo?.length ?? 0) + (emailsCc?.length ?? 0));
    const isGlobalNextstep = folder?.case?.nextstep && typeof folder.case.nextstep.index !== 'number';
    const globalService = isGlobalNextstep
        ? JSON.stringify({
            _id: folder?.case?.nextstep?._id,
            title: folder?.case?.nextstep?.title,
            type: folder?.case?.nextstep?.definition?.calendar?.type,
            category: folder?.case?.nextstep?.definition?.calendar?.category,
            duration: folder?.case?.nextstep?.definition?.calendar?.duration,
            max_book: folder?.case?.nextstep?.definition?.calendar?.max_book
        })
        : undefined;
    const calendarUrl = axios.getUri({
        baseURL: `/calendar/book/${folder?.folder_id ?? ''}`,
        params: {
            ticket_id: folder?.folder_id,
            case_id: folder?.case?._id,
            domain_id: folder?.case?.product?._id,
            service_id: folder?.case?.nextstep?._id,
            groups: folder?.case?.groups.map(g => g._id),
            global_service: globalService
        }
    });

    const macroOptions = useMemo(
        () => [...(folder?.macros ?? [])],
        [folder?.macros, macroLang]
    );

    const {
        options: assignOptions,
        groupBy: assignGroupBy
    } = useAssignment({
        users: folder?.users ?? [],
        groups: folder?.groups ?? []
    });

    const selectedAssign = useMemo(
        () => assignOptions.find((a) => a.value === selectedAssignId),
        [assignOptions, selectedAssignId]
    );

    const {
        getNote,
        setStorageNote,
        removeNote
    } = useFolderStore((state) => ({
        getNote: state.getNote,
        setStorageNote: state.setNote,
        removeNote: state.removeNote
    }));

    const isPublicOptions = [
        {
            label: t('private-note'),
            value: 'private'
        },
        {
            label: t('public-note'),
            value: 'email'
        }
    ];

    useEffect(() => {
        handleGetLocalStorageNote();
    }, []);

    useEffect(() => {
        if (folder?.parties) {
            setEmailsTo(folder.parties.filter(p => p.type === 'client').map(p => p.email));
        }
    }, [folder?.parties]);

    useEffect(() => {
        const noteTimeout = setTimeout(() => {
            handleSetStorageNote();
        }, 2000);
        return () => clearTimeout(noteTimeout);
    }, [note]);

    useEffect(() => {
        if (folder?.case?.future_nextstep) {
            handleSelectNextStep(folder.case.future_nextstep, true);
        }
    }, [folder?.case?.future_nextstep]);

    useEffect(() => {
        const macroId = folder?.case?.nextstep?.definition?.folder?.open_macro;
        const macro = folder?.macros?.find(m => m._id === macroId);
        if (macro) {
            handleMacroSelectChange(macro);
        }
    }, [folder?.case, folder?.macros]);

    function handleReset() {
        setNote('');
        setAttachments([]);
        setSelectedMacro(null);
        handleSelectNextStep(null);
        setSelectedAssignId(null);
        setType(override?.is_customer_portal ? 'public' : 'private');
    }

    function handleSelectNextStep(ns: NextStepInstance | null, skipMacroSelection = false) {
        setStatus(ns?.definition?.folder?.status ?? null);
        if (!skipMacroSelection && ns?.definition?.folder?.macro) {
            handleMacroSelectChange(folder?.macros?.find(m => m._id === ns.definition.folder.macro) ?? null);
        }
        if (ns?.definition?.folder?.assign) {
            setSelectedAssignId(ns.definition.folder.assign);
        }
        setSelectedNextStep(ns);
    }

    function handlePreSubmit() {
        const isNextStepTarget = folder?.case?.future_nextstep?._id === selectedNextStep?._id;
        const isAllowedBifurcation = selectedNextStep?.definition?.folder?.bifurcation === true;
        if (isNextStepTarget || (!isNextStepTarget && isAllowedBifurcation)) {
            handleSubmit().then();
        } else {
            setOpenBifurcation(true);
        }
    }

    function handleSubmit(quick_note = false) {
        setNotePostLoading(true);
        const obj: PostAddNoteDto = {
            type: 'update',
            lang,
            is_public: isPublic,
            active: true,
            attachments,
            ...selectedMacro?.actions,
            origin: {
                source: override?.is_customer_portal ? 'portal' : 'neodesk'
            },
            action: {
                id: selectedMacro ? selectedMacro._id : (isPublic ? '02' : '01'),
                type: selectedMacro ? 'ma' : 'ca'
            },
            case: {
                _id: folder?.case?._id,
                ...(selectedAssign && {
                    assign: {
                        _id: selectedAssign.value,
                        type: selectedAssign.type,
                        name: selectedAssign.label
                    }
                }),
                ...(selectedNextStep && !quick_note && {
                    nextstep: selectedNextStep
                }),
                ...(selectedMacro && {
                    macro: selectedMacro
                }),
                ...selectedMacro?.actions?.case,
                ...(!quick_note && selectedNextStep?.definition?.case),
                ...(status && !quick_note && { status }),
                ...(isPublic && { status: 'open' })
            },
            note,
            ...(type === 'email' && {
                emails: {
                    subject: emailSubject,
                    to: emailsTo?.map((email) => ({ fullname: '', email })) ?? [],
                    cc: emailsCc?.map((email) => ({ fullname: '', email })) ?? []
                }
            })
        };
        if (obj.note === '<p><br></p>') {
            obj.note = '';
        }
        if (selectedNextStep && selectedNextStep._id !== folder?.case?.nextstep?._id) {
            obj.note += `<p>${to({
                en: 'Next step changed',
                fr: 'Changement d\'étape'
            })} : <b>${to(folder?.case?.nextstep?.title)} >>> ${to(selectedNextStep.title)}</b></p>`;
        }
        if (selectedAssign && selectedAssign.value !== folder?.case?.assign?._id) {
            obj.note += `<p>${to({
                en: 'Assignment changed',
                fr: 'Changement d\'assignation'
            })} : <b>${to(folder?.case?.assign?.name)} >>> ${selectedAssign.label}</b></p>`;
        }
        if (status && status !== folder?.case?.status) {
            obj.note += `<p>${to({
                en: 'Status changed',
                fr: 'Changement de statut'
            })} : <b>${
                t_status(STATUS_INFO[folder?.case?.status ?? 'open'].title)
            } >>> ${
                t_status(STATUS_INFO[status].title)
            }</b></p>`;
        }
        return postAddNote(obj)
            .then((res) => {
                const { data } = res;
                folder?.setNotes([data.note, ...folder.notes]);
                const history = folder?.case?.history ?? {
                    nextstep: [],
                    assign: [],
                    status: []
                };
                Object.keys(history)
                    .forEach((key) => {
                        if ((data?.note?.case as any)?.[key]) {
                            ((history as any)[key]).push({
                                data: (data.note.case as any)[key],
                                updated_at: DateTime.now().toISO()
                            });
                        }
                    });
                // @ts-expect-error 2345
                folder?.setCaseInfo({
                    ...folder.case,
                    ...data.note.case,
                    history
                });
                handleReset();
                toast(ct('messages.success'), { type: 'success' });
                removeNote(folder?.folder_id ?? '');
                folder?.getCase?.();
            })
            .catch(handleNetworkError)
            .finally(() => setNotePostLoading(false));
    }

    function handleGetLocalStorageNote() {
        const note = getNote(folder?.folder_id ?? '');
        if (note) {
            setNote(note);
        }
    }

    function handleSetStorageNote() {
        if (note && folder?.folder_id) {
            setStorageNote(folder?.folder_id, note);
        }
    }

    function handleMacroSelectChange(macro: Macro | null) {
        setSelectedMacro(macro);
        const template = to(macro?.content_template, '', macroLang);
        if (macro?.actions?.case?.status) {
            setStatus(macro.actions.case.status);
        }
        if (macro && template) {
            const note = engine.parseAndRenderSync(
                template,
                {
                    ...folder?.case,
                    input: folder?.input.data
                }
            );
            setNote(note);
            setType(macro.actions?.is_public ? 'email' : 'private');
        } else if (!macro) {
            setNote('');
        }
    }

    function handleClearQuill() {
        setNote('');
        removeNote(folder?.folder_id ?? '');
    }

    function handleUploadFiles(e: React.ChangeEvent<HTMLInputElement>) {
        const files = getInputFileList(e.target);
        return Promise.all(
            files.map((f) =>
                postUploadFile(f, {
                    folder_id: folder?.folder_id,
                    case_id: folder?.case?._id,
                    target: 'NEODESK_files',
                    category: 'other',
                    email: user?.email
                })
            )
        )
            .then((res) => {
                const newAttachments = res.map((r) => r.data);
                setAttachments([...attachments, ...newAttachments]);
            })
            .catch(handleNetworkError)
            .finally(() => {
                if (fileInput.current) {
                    fileInput.current.value = '';
                }
            });
    }

    function handleRemoveAttachment(id: string): void {
        const newAttachments = attachments.filter(f => f._id !== id);
        setAttachments(newAttachments);
    }

    return (
        <DropZoneWrapper handleFileUpload={handleUploadFiles} hoverBorder={false}>
            <Card>
                <CardContent className="!tw-p-3">
                    {!override?.is_customer_portal && <div className={cn(
                        'tw-flex tw-flex-wrap tw-justify-between',
                        '!tw-items-end tw-gap-x-15 tw-gap-y-3 tw-mb-2'
                    )}>
                        <div className="tw-flex tw-gap-2">
                            <Tabs
                                value={type}
                                onValueChange={(value) => setType(value as any)}
                            >
                                <TabsList>
                                    {isPublicOptions.map(({
                                        value,
                                        label
                                    }) => (
                                        <TabsTrigger key={value} value={value}>
                                            {label}
                                        </TabsTrigger>
                                    ))}
                                </TabsList>
                            </Tabs>
                            <NoteEmailDialog
                                value={{
                                    subject: emailSubject,
                                    to: emailsTo,
                                    cc: emailsCc
                                }}
                                onSubmit={({ to, cc, subject }) => {
                                    setEmailsTo(to);
                                    setEmailsCc(cc);
                                    setEmailSubject(subject);
                                }}
                            >
                                {type === 'email' &&
                                    <Tooltip>
                                        <BadgeCounter content={numEmails > 0 ? numEmails : undefined}>
                                            <DialogTrigger asChild>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        size="icon" variant="ghost"
                                                    >
                                                        <EnvelopeOpenIcon className="tw-size-4" />
                                                    </Button>
                                                </TooltipTrigger>
                                            </DialogTrigger>
                                        </BadgeCounter>
                                        <TooltipContent>
                                            {t('email-dialog.tooltip')}
                                        </TooltipContent>
                                    </Tooltip>
                                }
                            </NoteEmailDialog>
                        </div>
                        {macroOptions.length > 0 &&
                            <div className="tw-flex tw-items-end tw-gap-2">
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button
                                            className="tw-text-primary hover:tw-text-primary !tw-px-3 tw-uppercase"
                                            variant="outline"
                                            onClick={() => setMacroLang(macroLang === 'fr' ? 'en' : 'fr')}
                                        >
                                            {macroLang}
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {t('macros.tooltip')}
                                    </TooltipContent>
                                </Tooltip>
                                <div className="tw-flex tw-flex-col tw-w-56">
                                    <Label htmlFor="macro" className="tw-mb-1">{t('macros.label')}</Label>
                                    <Combobox
                                        id="macro"
                                        options={macroOptions}
                                        getOptionLabel={(opt) => to(opt.title, '', macroLang)}
                                        getValueLabel={(value) => to(value.title, '', macroLang)}
                                        clearable
                                        value={selectedMacro}
                                        onChange={handleMacroSelectChange}
                                    />
                                </div>
                            </div>}
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button
                                    className="tw-text-primary hover:tw-text-primary"
                                    variant="outline"
                                >
                                    <FontAwesomeIcon className="tw-mr-2" icon={faCalendar} />
                                    {t('calendar.tooltip')}
                                </Button>
                            </DialogTrigger>
                            <DialogContent className="md:tw-max-w-5xl tw-h-[90%] !tw-flex tw-flex-col !tw-gap-0">
                                <DialogHeader>
                                    <DialogTitle>{t('calendar.title')}</DialogTitle>
                                </DialogHeader>
                                <div className="tw-w-full tw-flex-1 tw-mt-4">
                                    <iframe
                                        className="tw-w-full tw-h-full"
                                        src={calendarUrl}
                                    />
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>}
                    <div className="tw-relative">
                        <ReactQuill
                            className={cn('tw-mb-1 contenteditable', isPublic && 'quill-public')}
                            modules={{ toolbar: toolbarOptions }}
                            onChange={(e) => {
                                setNote(e);
                            }}
                            value={note}
                        />
                        <div className="tw-flex !tw-absolute tw-top-[3px] tw-right-1">
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <MenuButton
                                        tooltip={to({ en: 'Add attachment', fr: 'Ajouter une pièce jointe' })}
                                        id='quill-attachment'
                                        options={[
                                            {
                                                label: to({ en: 'Existing file', fr: 'Fichier existant' }),
                                                onClick: () => setOpenExistingFile(true),
                                                value: 'existing_file',
                                                icon: faList
                                            },
                                            {
                                                label: to({ en: 'New file', fr: 'Nouveau fichier' }),
                                                onClick: () => fileInput.current?.click(),
                                                value: 'new_file',
                                                icon: faUpload
                                            }
                                        ]}
                                        renderButton={(props) => (
                                            <Button
                                                {...props}
                                                variant="ghost" size="icon"
                                                className="!tw-rounded-full tw-text-primary tw-text-lg"
                                            >
                                                <FontAwesomeIcon icon={faPaperclip} />
                                            </Button>
                                        )}
                                    />
                                </TooltipTrigger>
                                <TooltipContent>
                                    {t('quill.add-file')}
                                </TooltipContent>
                            </Tooltip>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        variant="ghost" size="icon"
                                        className="!tw-rounded-full tw-text-primary tw-text-lg"
                                        onClick={handleClearQuill}
                                    >
                                        <FontAwesomeIcon icon={faEraser} />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {t('quill.erase')}
                                </TooltipContent>
                            </Tooltip>
                            {!override?.is_customer_portal &&
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <ButtonAsync
                                            variant="ghost" size="icon"
                                            className="!tw-rounded-full tw-text-primary tw-text-lg"
                                            onClick={() => handleSubmit(true)}
                                        >
                                            <FontAwesomeIcon icon={faSave}/>
                                        </ButtonAsync>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {t('quick-note.tooltip')}
                                    </TooltipContent>
                                </Tooltip>
                            }
                        </div>
                    </div>
                    {attachments.length > 0 && <div className="tw-mt-2">
                        <h3 className="tw-text-primary tw-font-semibold">{t('attachments.title')}</h3>
                        <div className="tw-flex tw-flex-col tw-gap-2 tw-py-2">
                            {attachments.map((f) =>
                                <div key={f._id} className="tw-flex tw-items-center tw-gap-4">
                                    <TextFieldEdit
                                        value={f.name}
                                        onChange={(value) => {
                                            f.name = value;
                                            setAttachments([...attachments]);
                                        }}
                                    />
                                    <div className="tw-ml-auto tw-flex">
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Button
                                                    variant="ghost" size="icon"
                                                    className="!tw-rounded-full tw-text-primary"
                                                    onClick={() => setPreviewFile({
                                                        url: f?.url,
                                                        mimeType: f?.content_type ?? '',
                                                        name: f?.name
                                                    }
                                                    )}
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                            </TooltipTrigger>
                                            <TooltipContent>{t('attachments.preview')}</TooltipContent>
                                        </Tooltip>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Button
                                                    variant="ghost" size="icon"
                                                    className="!tw-rounded-full tw-text-danger"
                                                    onClick={() => handleRemoveAttachment(f._id)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </TooltipTrigger>
                                            <TooltipContent>{t('attachments.remove')}</TooltipContent>
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>}
                    <div className="tw-flex tw-gap-5 tw-justify-between tw-items-end">
                        {!override?.is_customer_portal && <div className="tw-flex tw-flex-col tw-w-2/5">
                            <Label htmlFor="nextsteps" className="tw-my-1">
                                {to({
                                    en: 'Next step',
                                    fr: 'L\'étape suivante'
                                })}
                            </Label>
                            <Combobox
                                id="nextsteps"
                                options={folder?.next_steps ?? []}
                                getOptionLabel={opt =>
                                    opt.index != null
                                        ? `${opt.index + 1}. ${to(opt.title)}`
                                        : to(opt.title)
                                }
                                getValueLabel={value =>
                                    value.index != null
                                        ? `${value.index + 1}. ${to(value.title)}`
                                        : to(value.title)
                                }
                                groupBy={opt => opt.index != null ? 'Processus' : 'Global'}
                                value={selectedNextStep}
                                onChange={(ns) => handleSelectNextStep(ns)}
                                clearable
                            />
                        </div>}
                        {!override?.is_customer_portal && <div className="tw-flex tw-flex-col tw-w-2/5">
                            <Label htmlFor="assign" className="tw-my-1">
                                {t('assign.label')}
                            </Label>
                            <Combobox
                                id="assign"
                                className="tw-min-w-sm"
                                options={assignOptions}
                                getOptionValue={opt => opt.value}
                                getOptionLabel={opt => opt.label}
                                groupBy={assignGroupBy}
                                value={selectedAssignId}
                                onChange={setSelectedAssignId}
                                clearable
                            />
                        </div>}
                        <Button
                            color="primary"
                            onClick={handlePreSubmit}
                            style={{ minWidth: '100px' }}
                            loading={!!folder?.loading || notePostLoading}
                            className={cn('tw-w-1/5', override?.is_customer_portal && 'tw-ml-auto tw-mt-2')}
                        >
                            <FontAwesomeIcon icon={faPaperPlane} className="tw-mr-2" />
                            {t('send')}
                        </Button>
                    </div>
                    <input
                        ref={fileInput}
                        type="file" className="tw-hidden"
                        multiple
                        onChange={handleUploadFiles}
                    />
                </CardContent>
            </Card>
            <FilePreview
                file={previewFile ?? { name: '', url: '', mimeType: '' }}
                onOpenChange={() => setPreviewFile(null)}
                open={previewFile !== null}
            />
            <ConfirmDialog
                title={t('bifurcation.title')}
                message={t('bifurcation.message')}
                open={openBifurcation}
                onOpenChange={setOpenBifurcation}
                onConfirm={handleSubmit}
            />
            <ExistingFileAttachmentDialog
                open={openExistingFile}
                setOpen={() => setOpenExistingFile(!openExistingFile)}
                setAttachments={(selectedFiles) => (setAttachments([...attachments, ...selectedFiles]))}
            />
        </DropZoneWrapper>
    );
}
