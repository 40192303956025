import React, { useCallback, useRef, type ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';
import type { FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useTranslation } from '@/composables/translation';
import { cn } from '@/lib/utils';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DropZoneWrapperProps {
    children: ReactNode;
    handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    hoverBorder?: boolean;
}

export const DropZoneWrapper: React.FC<DropZoneWrapperProps> = ({
    children,
    handleFileUpload,
    hoverBorder
}) => {
    const fileInput = useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation('file-input');
    const onDropAccepted = (acceptedFiles: File[]) => {
        const inputEvent = {
            target: {
                files: acceptedFiles
            }
        } as unknown as React.ChangeEvent<HTMLInputElement>;
        handleFileUpload(inputEvent);
    };

    const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
        rejectedFiles.forEach((fileRejection) => {
            const { errors, file } = fileRejection;
            errors.forEach((error) => {
                if (error.code === 'file-invalid-type') {
                    toast.error(t('invalid-file-type', { type: file.type }));
                } else {
                    toast.error(t('invalid-file-unknown-error', { name: file.name }));
                }
            });
        });
    }, []);

    const acceptedFileTypes = {
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'message/rfc822': ['.eml'],
        'application/vnd.ms-outlook': ['.msg'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'text/csv': ['.csv']
    };

    const { getRootProps, getInputProps, isDragActive, isFocused } = useDropzone({
        accept: acceptedFileTypes,
        onDropAccepted,
        onDropRejected,
        noClick: true
    });

    return (
        <div
            {...getRootProps()}
            className={cn(
                'tw-relative tw-rounded-lg tw-border-2 tw-border-dashed',
                hoverBorder && 'hover:!tw-border-gray-500',
                isDragActive && 'tw-border-green-600',
                !isDragActive && isFocused && hoverBorder
                    ? 'tw-border-grey'
                    : 'tw-border-transparent'
            )}
        >
            <input {...getInputProps()} ref={fileInput}/>
            {children}
            {isDragActive &&
                <div className={cn(
                    'tw-absolute tw-inset-0 tw-bg-white/70 tw-rounded-lg',
                    'tw-flex tw-flex-col tw-justify-center tw-items-center'
                )}>
                    <FontAwesomeIcon
                        className="tw-text-5xl tw-text-slate-400 tw-mb-4"
                        icon={faCloudArrowUp}
                    />
                    <span className="tw-text-lg tw-font-medium">
                        {t('drop-upload')}
                    </span>
                </div>
            }
        </div>
    );
};
