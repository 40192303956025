import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useError } from '@/composables/error';
import { useValidation } from '@/composables/validation';
import { useTranslation } from '@/composables/translation';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { Col, Row } from '@/components/ui/row';
import { CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useForm } from 'react-hook-form';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { type PostContactConfigDto } from '@/types/api/orgs';
import { getContactConfig, postContactConfig } from '@/composables/api';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';

export function ConfigureOrg() {
    const { t, ct } = useTranslation('settings.orgs');
    const error = useError();
    const { required } = useValidation();
    const form = useForm<PostContactConfigDto>();

    useEffect(() => {
        getContactConfig()
            .then(({ data }) => form.reset(data))
            .catch(error.handleNetworkError);
    }, []);

    function handleSubmit(formValues: PostContactConfigDto) {
        return postContactConfig(formValues)
            .then(() => {
                toast.success(ct('messages.success'));
            })
            .catch(error.handleNetworkError);
    }

    const grantOptions: string[] = ['password'];
    return (<>
        <CardHeader>
            <CardTitle>{t('modify-org')}</CardTitle>
        </CardHeader>
        <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <CardContent className="tw-flex tw-flex-col tw-gap-3">
                    <Alert variant="info">
                        <AlertTitle>{ct('alerts.warning')}</AlertTitle>
                        <AlertDescription>{t('azure-warning')}</AlertDescription>
                    </Alert>
                    <label className="tw-text-sm tw-font-medium">
                        {t('reception-email-config')}
                    </label>
                    <Row>
                        <Col col={12}>
                            <FormField
                                name="username"
                                rules={{ validate: { required } }}
                                render={({ field }) =>
                                    <FormItem className="tw-flex-1">
                                        <FormLabel>{t(field.name)}</FormLabel>
                                        <FormControl>
                                            <Input
                                                id={field.name}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col col={12}>
                            <FormField
                                name="client_id"
                                rules={{ validate: { required } }}
                                render={({ field }) =>
                                    <FormItem className="tw-flex-1">
                                        <FormLabel>{t(field.name)}</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="password"
                                                id={field.name}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col col={12}>
                            <FormField
                                name="client_secret"
                                rules={{ validate: { required } }}
                                render={({ field }) =>
                                    <FormItem className="tw-flex-1">
                                        <FormLabel>{t(field.name)}</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="password"
                                                id={field.name}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col col={12}>
                            <FormField
                                name="grant_type"
                                rules={{ validate: { required } }}
                                render={({
                                    field: {
                                        ref,
                                        ...field
                                    }
                                }) =>
                                    <FormItem className="tw-flex-1">
                                        <FormLabel>{t(field.name)}</FormLabel>
                                        <FormControl>
                                            <Select {...field} onValueChange={field.onChange}>
                                                <SelectTrigger>
                                                    <SelectValue id="grant_type" ref={ref}></SelectValue>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {grantOptions.map(o =>
                                                        <SelectItem key={o} value={o}>
                                                            {o}
                                                        </SelectItem>
                                                    )}
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col col={12}>
                            <FormField
                                name="password"
                                rules={{ validate: { required } }}
                                render={({ field }) =>
                                    <FormItem className="tw-flex-1">
                                        <FormLabel>{t(field.name)}</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="password"
                                                id={field.name}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col col={12}>
                            <FormField
                                name="scope"
                                rules={{ validate: { required } }}
                                render={({ field }) =>
                                    <FormItem className="tw-flex-1">
                                        <FormLabel>{t(field.name)}</FormLabel>
                                        <FormControl>
                                            <Input
                                                id={field.name}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col col={12}>
                            <FormField
                                name="tenant_id"
                                rules={{ validate: { required } }}
                                render={({ field }) =>
                                    <FormItem className="tw-flex-1">
                                        <FormLabel>{t(field.name)}</FormLabel>
                                        <FormControl>
                                            <Input
                                                id={field.name}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                        </Col>
                    </Row>

                </CardContent>
                <CardFooter className="tw-flex-col tw-gap-2">
                    <ButtonSubmit className="tw-self-stretch">
                        {t('submit')}
                    </ButtonSubmit>
                </CardFooter>
            </form>
        </Form>
    </>
    );
}
