import React, { type SetStateAction } from 'react';
import { useTranslation } from '@/composables/translation';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { useControllableState } from '@/composables/controllable';
import useHtml from '@/composables/html';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

interface Props {
    open?: boolean;
    title?: string;
    message: string;
    className?: string;
    cancelText?: string;
    confirmText?: string;
    confirmInputValue?: string;
    children?: React.ReactNode;
    onOpenChange?: React.Dispatch<SetStateAction<boolean>>;
    onConfirm?: () => (void | Promise<void>);
}

export function ConfirmDialog(props: Props) {
    const { ct, t } = useTranslation('validation');
    const form = useForm({
        defaultValues: {
            confirm: ''
        }
    });
    const [open, setOpen] = useControllableState(false, props.open, props.onOpenChange);
    const sanitizedMessage = useHtml(props.message);
    const confirm = form.watch('confirm');
    const isConfirmValidated = props.confirmInputValue
        ? confirm === props.confirmInputValue
        : true;
    async function handleConfirm() {
        try {
            await props.onConfirm?.();
            setOpen(false);
        } catch (err) {}
    }
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            {props.children}
            <DialogContent className={cn('!tw-max-w-md', props.className)}>
                <DialogHeader>
                    {props.title && <DialogTitle>{props.title}</DialogTitle>}
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={(event) => {
                        event.stopPropagation();
                        form.handleSubmit(handleConfirm)(event);
                    }}>
                        <div
                            className="tw-prose"
                            dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
                        >
                        </div>
                        {props.confirmInputValue && <FormField
                            name="confirm"
                            render={({ field, fieldState }) =>
                                <FormItem className={cn(
                                    'tw-mt-3 tw-p-2 tw-rounded-md',
                                    'tw-border tw-border-slate-200 tw-bg-slate-100',
                                    ''
                                )}>
                                    <FormLabel dangerouslySetInnerHTML={{
                                        __html: t('type-value-below', {
                                            value: props.confirmInputValue ?? ''
                                        })
                                    }} />
                                    <FormControl>
                                        <Input
                                            {...field}
                                            error={!!fieldState.error}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />}
                        <DialogFooter className="tw-pt-6">
                            <DialogClose asChild>
                                <Button variant="secondary">
                                    {props.cancelText ?? ct('cancel')}
                                </Button>
                            </DialogClose>
                            <ButtonSubmit variant="destructive" disabled={!isConfirmValidated}>
                                {props.confirmText ?? ct('confirm')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
