import { Combobox } from '@/components/ui/combobox';
import { DateTime } from 'luxon';
import _ from 'lodash';
import React from 'react';
import { useControllableState } from '@/composables/controllable';
import { cn } from '@/lib/utils';

const NOW = DateTime.now();
const YEARS_START = NOW.year - 100;
const YEARS_END = NOW.year + 100;

interface Props {
    className?: string;
    month?: number;
    year?: number;
    onChangeMonth?: React.Dispatch<React.SetStateAction<number>>;
    onChangeYear?: React.Dispatch<React.SetStateAction<number>>;
    allowNull?: boolean;
}

export function MonthPicker(props: Props) {
    const [month, setMonth] = useControllableState(
        props.allowNull ? null as unknown as number : 0,
        props.month, props.onChangeMonth
    );
    const [year, setYear] = useControllableState(
        props.allowNull ? null as unknown as number : 0,
        props.year, props.onChangeYear
    );
    return (
        <div className={cn('tw-flex', props.className)}>
            <Combobox
                className="!tw-w-[90px]"
                placeholder=""
                value={month}
                onChange={(value) => setMonth(Number(value))}
                getOptionLabel={
                    (opt) => DateTime.fromFormat(
                        _.padStart(String(opt + 1), 2, '0'),
                        'MM'
                    ).toFormat('MMM')
                }
                options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                tabIndex={-1}
            />
            <Combobox
                className="!tw-w-[90px]"
                placeholder=""
                value={year}
                onChange={(value) => setYear(Number(value))}
                options={_.range(YEARS_START, YEARS_END)}
                tabIndex={-1}
            />
        </div>
    );
}
