import React from 'react';
import {
    createBrowserRouter,
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';

import { RootLayout } from '@/layouts/RootLayout';
import { PublicLayout, PublicLayoutLoader } from '@/layouts/PublicLayout';
import { PrivateLayout, PrivateLayoutLoader, PrivateLoader } from '@/layouts/PrivateLayout';
import { SettingsLayout } from '@/layouts/SettingsLayout';

import { Login } from '@/pages/login/Login';
import { LogoutLoader } from '@/pages/login/Logout';
import { ForgotPassword } from '@/pages/login/ForgotPassword';
import { NotFound } from '@/pages/info/NotFound';
import { ThankYou } from '@/pages/info/ThankYou';
import { ResetPassword } from '@/pages/login/ResetPassword';
import { Billing } from '@/pages/settings/Billing';
import { Forms } from '@/pages/psj/Forms';
import { Forms as FormsSettings } from '@/pages/settings/Forms';
import { FormContainer } from '@/pages/old/FormContainer';
import { AuthSSOLoader } from '@/pages/login/AuthSSO';
import { UserSettings } from '@/pages/settings/UserSettings';
import { ChangePassword } from '@/pages/settings/ChangePassword';
import { NeoFormSend } from '@/pages/neoform/NeoFormSend';
import { Unauthorized } from '@/pages/info/Unauthorized';
import { Forbidden } from '@/pages/info/Forbidden';
import { ActionRequired } from '@/pages/info/ActionRequired';
import { Message } from '@/pages/info/Message';
import { ComingSoon } from '@/components/ComingSoon';
import { NeoFormPage } from '@/pages/neoform/NeoFormPage';
import { Booking } from '@/pages/calendar/Booking';
import { LawyerAvailabilities } from '@/pages/calendar/LawyerAvailabilities';

import trad from '@/components/old/trad';
import { WithHooks } from '@/components/utils/WithHooks';
import { useUserStore } from '@/store/user';
import { useDocumentStore } from '@/store/document';
import { useError } from '@/composables/error';
import { Feature, RequiredFeatureLoader } from '@/composables/features';
import { RedirectLoader } from '@/composables/utils';
import { IFrameLayout } from '@/layouts/IFrameLayout';
import { PSJLayout } from '@/layouts/PSJLayout';
import { LawyerSchedule } from '@/pages/calendar/LawyerSchedule';
import { FooterLayout } from '@/layouts/FooterLayout';
import { Users } from '@/pages/settings/Users';
import { Groups } from '@/pages/settings/Groups';
import { Processes } from '@/pages/settings/Processes';
import { LoginPsjLoader } from '@/pages/LoginPsj';
import { Dashboard } from '@/pages/psj/Dashboard';
import { Folder } from '@/pages/psj/Folder';
import { Products } from '@/pages/settings/Products';
import { Clients } from '@/pages/psj/Clients';
import { Views } from '@/pages/settings/Views';
import { ExternalFolders } from '@/pages/psj/ExternalFolders';
import { UnassignedNotes } from '@/pages/psj/UnassignedNotes';

import { Dashboard as PortalFolders } from '@/pages/portal/Dashboard';
import { PortalLayout } from '@/layouts/PortalLayout';
import { PortalPrivateLayout } from '@/layouts/PortalPrivateLayout';
import { Permission, RequiredPermissionLoader } from '@/composables/permissions';
import { Macros } from '@/pages/settings/Macros';
import { ConfigureOrg } from '@/pages/settings/ConfigureOrg';
import { Invoices } from '@/pages/settings/billing/Invoices';
import { PdfMerger } from '@/pages/tools/PdfMerger';
import { Organizations } from '@/pages/settings/Organizations';
import { UrlLoader } from '@/pages/Url';
import { TimeLogEntries } from '@/pages/psj/TimeLogEntries';

// *TODO: Remove both functions when old code is ported
function getFormContainerPublicProps() {
    const { lang, logout, setToken } = useUserStore(state => ({
        lang: state.lang,
        logout: state.logout,
        setToken: state.setToken
    }));
    const location = useLocation();
    const history = {
        push: useNavigate()
    };
    const { token } = useParams() as { token: string };
    const error = useError();
    return {
        error,
        lang,
        logout,
        setToken,
        location,
        history,
        identity: 'neolegal',
        public: true,
        token,
        trad
    };
}

function getFormContainerPrivateProps() {
    const {
        lang,
        token,
        user
    } = useUserStore(state => ({
        lang: state.lang,
        token: state.token,
        user: state.user
    }));
    const documentStore = useDocumentStore();
    const location = useLocation();
    const history = {
        push: useNavigate()
    };
    const params = useParams() as { name: string; task_id: string };
    const error = useError();
    return {
        error,
        documentStore,
        lang,
        location,
        history,
        identity: 'neolegal',
        public: false,
        formName: params.name,
        task_id: params.task_id,
        token,
        user,
        trad
    };
}

const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <RootLayout />,
            errorElement: <NotFound />,
            children: [
                {
                    path: '',
                    loader: RedirectLoader('/dashboard')
                },
                {
                    path: 'logout',
                    loader: LogoutLoader
                },
                {
                    path: 'url/:shorten_code',
                    loader: UrlLoader
                },
                {
                    path: 'calendar',
                    children: [
                        {
                            path: '',
                            loader: RedirectLoader('/calendar/availabilities')
                        },
                        {
                            path: 'book/:ticket_id',
                            element: <IFrameLayout />,
                            loader: PrivateLayoutLoader,
                            children: [
                                {
                                    path: '',
                                    element: <Booking />
                                }
                            ]
                        },
                        {
                            path: 'public/:token',
                            element: <IFrameLayout />,
                            children: [
                                {
                                    path: '',
                                    element: <Booking />
                                }
                            ]
                        }
                    ]
                },
                {
                    element: <PublicLayout />,
                    children: [
                        {
                            path: 'unauthorized',
                            element: <Unauthorized />
                        },
                        {
                            path: 'forbidden',
                            element: <Forbidden />
                        },
                        {
                            path: 'message',
                            element: <Message />
                        },
                        {
                            path: 'action-required',
                            element: <ActionRequired />
                        },
                        {
                            // *TODO: Add ticket and source params
                            path: 'thanks/:lang?',
                            element: <ThankYou />
                        },
                        {
                            path: 'merci/:lang?',
                            element: <ThankYou />
                        }
                    ]
                },
                {
                    element: <PublicLayout />,
                    loader: PublicLayoutLoader,
                    children: [
                        {
                            path: 'login',
                            element: <Login />
                        },
                        {
                            path: 'login-psj/:token',
                            loader: LoginPsjLoader
                        },
                        {
                            path: 'forgot-password',
                            element: <ForgotPassword />
                        },
                        {
                            path: 'forgot-password/:token',
                            element: <ResetPassword />
                        }
                    ]
                },
                {
                    element: <PrivateLayout />,
                    loader: PrivateLayoutLoader,
                    children: [
                        {
                            path: 'calendar/availabilities',
                            element: <LawyerAvailabilities />,
                            loader: RequiredPermissionLoader(Permission.CALENDAR_DISPONIBILITIES_SELECT)
                        },
                        {
                            path: 'calendar/schedule',
                            element: <LawyerSchedule />,
                            loader: RequiredPermissionLoader(Permission.CALENDAR_APPOINTMENT_SCHEDULE)
                        },
                        {
                            element: <PSJLayout />,
                            children: [
                                {
                                    path: 'folder/:folder_id/:tab?',
                                    loader: RequiredFeatureLoader(Feature.PSJ),
                                    element: <Folder />
                                },
                                {
                                    path: 'dashboard',
                                    loader: RequiredFeatureLoader(Feature.PSJ),
                                    element: <Dashboard />
                                },
                                {
                                    path: 'dashboard/external',
                                    loader: RequiredFeatureLoader(Feature.PSJ),
                                    element: <ExternalFolders />
                                },
                                {
                                    path: 'clients',
                                    loader: RequiredFeatureLoader(Feature.PSJ),
                                    element: <Clients />
                                },
                                {
                                    path: 'forms',
                                    loader: RequiredFeatureLoader(Feature.NEOFORM),
                                    element: <Forms />
                                },
                                {
                                    path: 'unassigned',
                                    loader: RequiredFeatureLoader(Feature.PSJ),
                                    element: <UnassignedNotes/>
                                },
                                {
                                    path: 'timelog/:case_id?',
                                    loader: RequiredFeatureLoader(Feature.PSJ),
                                    element: <TimeLogEntries />
                                }
                            ]
                        },
                        {
                            path: 'settings',
                            element: <SettingsLayout />,
                            children: [
                                {
                                    path: '',
                                    loader: RedirectLoader('/settings/account')
                                },
                                {
                                    path: 'account',
                                    element: <UserSettings />
                                },
                                {
                                    path: 'org',
                                    element: <ConfigureOrg />
                                },
                                {
                                    path: 'organizations',
                                    element: <Organizations />
                                },
                                {
                                    path: 'invoices',
                                    element: <Invoices />
                                },
                                {
                                    path: 'sso',
                                    element: <ComingSoon />
                                },
                                {
                                    path: 'billing',
                                    element: <Billing />
                                },
                                {
                                    path: 'password',
                                    element: <ChangePassword />
                                },
                                {
                                    path: 'users',
                                    element: <Users />
                                },
                                {
                                    path: 'groups',
                                    element: <Groups />
                                },
                                {
                                    path: 'processes',
                                    loader: RequiredFeatureLoader(Feature.PSJ),
                                    element: <Processes />
                                },
                                {
                                    path: 'products',
                                    loader: RequiredFeatureLoader(Feature.PSJ),
                                    element: <Products />
                                },
                                {
                                    path: 'views',
                                    loader: RequiredFeatureLoader(Feature.PSJ),
                                    element: <Views />
                                },
                                {
                                    path: 'macros',
                                    loader: RequiredFeatureLoader(Feature.PSJ),
                                    element: <Macros />
                                },
                                {
                                    path: 'forms',
                                    loader: RequiredFeatureLoader(Feature.NEOFORM),
                                    element: <FormsSettings />
                                }
                            ]
                        },
                        {
                            path: 'v1/form/:name/:task_id',
                            loader: RequiredFeatureLoader(Feature.NEOFORM),
                            element: <WithHooks
                                getProps={getFormContainerPrivateProps}
                                render={(props) => <FormContainer {...props} />}
                            />
                        },
                        {
                            path: 'form/:name/:task_id',
                            loader: RequiredFeatureLoader(Feature.NEOFORM),
                            element: <NeoFormPage />
                        },
                        {
                            path: 'form/:name/:task_id/send',
                            loader: RequiredFeatureLoader(Feature.NEOFORM),
                            element: <NeoFormSend />
                        },
                        ...(import.meta.env.VITE_ENV === 'dev'
                            ? [{
                                path: 'form/:name',
                                loader: RequiredFeatureLoader(Feature.NEOFORM),
                                element: <WithHooks
                                    getProps={() => useParams<{ name: string }>()}
                                    render={(props) => <NeoFormPage forceTestForm={props.name} />}
                                />
                            }]
                            : [])
                    ]
                },
                {
                    element: <PrivateLayout fixed />,
                    loader: PrivateLayoutLoader,
                    children: [
                        {
                            path: 'tools/pdf/:folder_id?',
                            element: <PdfMerger />
                        }
                    ]
                },
                {
                    path: 'portal',
                    element: <PortalLayout />,
                    children: [
                        {
                            path: '',
                            loader: RedirectLoader('/portal/dashboard')
                        },
                        {
                            loader: PrivateLoader({
                                user_type: 'customer',
                                login_url: '/portal/login'
                            }),
                            element: <PortalPrivateLayout />,
                            children: [
                                {
                                    path: 'dashboard',
                                    element: <PortalFolders />
                                },
                                {
                                    path: 'folder/:folder_id',
                                    element: <Folder />
                                }
                            ]
                        },
                        {
                            element: <PublicLayout />,
                            children: [
                                {
                                    path: 'login',
                                    element: <Login />
                                },
                                {
                                    path: 'forgot-password',
                                    element: <ForgotPassword />
                                },
                                {
                                    path: 'forgot-password/:token',
                                    element: <ResetPassword />
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'v1/public/:token',
                    element: <WithHooks
                        getProps={getFormContainerPublicProps}
                        render={(props) => <FormContainer {...props} />}
                    />
                },
                {
                    element: <FooterLayout />,
                    children: [
                        {
                            path: 'public/:token',
                            element: <NeoFormPage />
                        }
                    ]
                },
                {
                    path: 'sso/auth',
                    loader: AuthSSOLoader
                },
                {
                    path: 'not-found',
                    element: <NotFound />
                }
            ]
        }
    ]
);

export default router;
