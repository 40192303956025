import React, { useState } from 'react';

import {
    NeoFormComponentPreface,
    NeoFormTitleType
} from '@/components/neoform/NeoFormComponentPreface';
import { InputCheckbox } from '@/components/neoform/inputs/InputCheckbox';
import { InputCheckboxList } from '@/components/neoform/inputs/InputCheckboxList';
import { InputRadio } from '@/components/neoform/inputs/InputRadio';
import { InputSelect } from '@/components/neoform/inputs/InputSelect';
import { InputText } from '@/components/neoform/inputs/InputText';
import { InputNumber } from '@/components/neoform/inputs/InputNumber';
import { InputDateTime } from '@/components/neoform/inputs/InputDateTime';
import { InputContentEditable } from '@/components/neoform/inputs/InputContentEditable';
import { InputDropContainer } from '@/components/neoform/inputs/InputDropContainer';
import { InputTableSelect } from '@/components/neoform/inputs/InputTableSelect';
import { HTML } from '@/components/neoform/display/HTML';
import { PDFViewer } from '@/components/neoform/display/PDFViewer';

import { Address } from '@/components/neoform/complex/Address';
import { User } from '@/components/neoform/complex/User';
import { Admin } from '@/components/neoform/complex/Admin';
import { List } from '@/components/neoform/complex/List';
import { CategoryActions } from '@/components/neoform/complex/CategoryActions';
import { Stakeholder } from '@/components/neoform/complex/Stakeholder';
import { StakeholderRecursive } from '@/components/neoform/complex/StakeholderRecursive';
import { DynamicComponent } from '@/components/neoform/complex/DynamicComponent';
import { DynamicList } from '@/components/neoform/complex/DynamicList';

import { useUserStore } from '@/store/user';
import { NeoFormComponentContext } from '@/components/neoform/context/NeoFormComponentContext';
import { invokeFunction, useNeoForm } from '@/composables/neoform';
import { type TranslationObject } from '@/composables/translation';
import { BindProps } from '@/components/utils/BindProps';
import { type NeoFormFunction } from '@/types/neoform';
import { clone } from '@/composables/utils';
import { Stakeholder2 } from '@/components/neoform/complex/Stakeholder2';
import { UserChildDivorce } from '@/components/neoform/complex/UserChildDivorce';
import { UserEndos } from '@/components/neoform/complex/UserEndos';
import { UserEmail } from '@/components/neoform/complex/UserEmail';
import { UserChildInfoDivorce } from '@/components/neoform/complex/UserChildInfoDivorce';
import { ParentalTime } from '@/components/neoform/complex/ParentalTime';
import { UserPercent } from '@/components/neoform/complex/UserPercent';
import { Good } from '@/components/neoform/complex/Good';

import { Col } from '@/components/ui/row';
import _ from 'lodash';
import { type FieldError } from 'react-hook-form/dist/types';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../ui/collapsible';
import { cn } from '@/lib/utils';
import { ChevronDownIcon, ChevronRightIcon } from 'lucide-react';
import { InputBookingCalendar } from './complex/InputBookingCalendar';

const InputPhone = BindProps(InputText, { mask: '(000) 000-0000', validationRules: ['phone'] });
const InputTextArea = BindProps(InputText, { textarea: true });
const InputDate = BindProps(InputDateTime, { type: 'date' });
const InputTime = BindProps(InputDateTime, { type: 'time' });
const InputContentEditableTiny = BindProps(InputContentEditable, { type: 'tiny' });
const InputFields = List(InputText, {
    noCard: true,
    titleType: NeoFormTitleType.LABEL,
    newValue: () => ''
});
const InputDropContainerBundled = BindProps(InputDropContainer, { bundled: true });
const DropContainerPC = BindProps(InputDropContainer, { bundled: true, amend: true });
const Actionnaires2 = Stakeholder2;
const ParentalTimes = List(ParentalTime);
const UsersChildrenDivorce = List(UserChildDivorce, { newValue: () => ({ user: {} }) });
const UsersChildrenInfoDivorce = List(UserChildInfoDivorce);
const UsersEndos = List(UserEndos);
const UsersEmail = List(UserEmail);
const UsersPercent = List(UserPercent);
const Goods = List(Good);

export function Missing({ componentName }: NeoFormComponentProps) {
    return (
        <div className="tw-bg-red-500 tw-text-white tw-p-3">
            MISSING COMPONENT: <b><u>{componentName}</u></b>
        </div>
    );
}

export function None() { return null; }

export const NEO_FORM_COMPONENTS = {
    // TODO: Remove old name mappings
    InputBookingCalendar,
    RadioInput: InputRadio,
    Select2: InputSelect,
    Select2Creatable: InputSelect,
    SelectBootstrap: InputSelect,
    TextInput: InputText,
    TextAreaInput: InputTextArea,
    NumberInput: InputNumber,
    CheckInput: InputCheckbox,
    CheckInputColor: InputCheckbox,
    Time: InputTime,
    Phone: InputPhone,
    DateInput: InputDate,
    Calendar: InputDate,
    ContentEditable: InputContentEditable,
    ContentEditableTiny: InputContentEditableTiny,
    DropContainer: InputDropContainer,
    DropContainerPC,

    HTML,
    InputRadio,
    InputSelect,
    InputText,
    InputTextArea,
    InputPhone,
    InputNumber,
    InputCheckbox,
    InputCheckboxList,
    InputContentEditable,
    InputContentEditableTiny,
    InputDate,
    InputDateTime,
    InputTime,
    InputDropContainer,
    InputDropContainerBundled,
    InputTableSelect,
    PDFViewer,

    StakeholderRecursive,

    Address,
    User,
    Admin,
    Good,
    Goods,
    Actionnaire: Stakeholder,
    CategoryActions,
    Users: List(User),
    Admins: List(Admin),
    Actionnaires: List(Stakeholder),
    Actionnaires2,
    ParentalTimes,
    UsersChildrenDivorce,
    UsersChildrenInfoDivorce,
    UsersEndos,
    UsersEmail,
    UsersPercent,
    InputFields,
    // TODO: Fix typo
    InputFeilds: InputFields,

    DynamicComponent,
    DynamicList,
    Informative: None,
    Missing,
    None
};

export type NeoFormComponentName = keyof typeof NEO_FORM_COMPONENTS;

export interface NeoFormComponentProps {
    componentName: string;
    name: string;
    titleType?: NeoFormTitleType;
    title?: TranslationObject | string;
    description?: TranslationObject | string;
    info?: TranslationObject | string;
    showInOutline?: boolean;
    generate?: {
        endpoint: string;
        prompt: string;
    };
    default?: any;
    required?: boolean;
    readonly?: boolean;
    validationRules?: string[];
    onValidate?: NeoFormFunction;
}

export interface Props<T extends object = object> {
    name: string;
    componentName: NeoFormComponentName;
    componentFunction?: React.FC<T>;

    className?: string;
    col?: number | string;
    display?: boolean;
    displayPreface?: boolean;
    conditionalProps?: Record<string, NeoFormFunction>;
    fields?: Record<string, any>;
    group?: string;
    onChange?: NeoFormFunction;
    isDynamic?: boolean;

    [key: string]: any;
}

export function NeoFormComponent<
    Name extends NeoFormComponentName,
    InnerProps extends NeoFormComponentProps &
        React.ComponentProps<typeof NEO_FORM_COMPONENTS[Name]>
>(props: Props<InnerProps> & InnerProps) {
    const lang = useUserStore(state => state.lang);
    const {
        hookForm,
        form,
        component: parent,
        getChildFieldName,
        hasChildFieldPermission,
        parseConditionalFunction
    } = useNeoForm();

    const name = props.name;
    const groupPath = props.group?.split('.').map(s => s.trim()) ?? [];
    const fullFieldName = getChildFieldName(...groupPath, name);
    const fieldPath = [...(parent?.path ?? []), ...groupPath, name];
    const collapsedShownFieldsValues = props?.collapsedShownFields?.map(
        (field: string) => (`${fieldPath},${field}`)
    ) ?? [];
    const subtitle = hookForm.getValues(collapsedShownFieldsValues)?.join(' ') ?? '';
    const finalProps = { ...props };
    const [encrypted, setEncrypted] = useState(true);
    const [open, setOpen] = useState(props?.collapsible === 'open');

    const isCheckbox =
        props.componentName === 'CheckInput' ||
        props.componentName === 'CheckInputColor' ||
        props.componentName === 'InputCheckbox';
    const isHTML = props.componentName === 'HTML';
    const shouldRenderPreface =
        (!isCheckbox || (isCheckbox && !!finalProps.label)) &&
        !isHTML && props.displayPreface !== false;

    const { component, props: p, isDynamic } = getComponentFunctionAndProps(props.componentName);

    function getComponentFunctionAndProps(name: string):
        {
            component: React.FC<NeoFormComponentProps & InnerProps>;
            props: any;
            isDynamic: boolean;
        } {
        if (props.componentFunction) {
            return {
                component: props.componentFunction as React.FC<NeoFormComponentProps & InnerProps>,
                props: {},
                isDynamic: !!props.isDynamic
            };
        }
        if (name && (NEO_FORM_COMPONENTS as any)[name]) {
            return {
                component: (NEO_FORM_COMPONENTS as any)[name] as
                    React.FC<NeoFormComponentProps & InnerProps>,
                props: {},
                isDynamic: false
            };
        }
        return {
            component: NEO_FORM_COMPONENTS.DynamicComponent as
                unknown as React.FC<NeoFormComponentProps & InnerProps>,
            props: { componentCustom: name, copyPaste: props.copyPaste },
            isDynamic: true
        };
    }

    // Overridden inner component props
    if (parent?.fields?.[fullFieldName]) {
        Object.entries(parent?.fields?.[fullFieldName] || {})
            .forEach(([propName, prop]) => {
                (finalProps as any)[propName] = prop;
            });
    }

    // Props dependant on form data
    if (hookForm && props.conditionalProps) {
        Object.entries(props.conditionalProps).forEach(([name, value]) => {
            let {
                fn,
                dependencies
            } = parseConditionalFunction(
                `${fullFieldName}_prop_${name}`,
                value,
                ['data', 'formData', 'getErrors']
            );
            const parentPath = parent?.dataPath?.join('.');
            if (parentPath) {
                dependencies = dependencies?.map(d => `${parentPath}.${d}`);
            }
            if (dependencies) {
                hookForm.watch(dependencies);
            }
            const getErrors = (...ids: string[]): FieldError[] => {
                return ids.map((id) => _.get(
                    hookForm.formState.errors,
                    parentPath ? `${parentPath}.${id}` : id
                ) as FieldError)
                    .filter(Boolean);
            };
            // *NOTE: Passing a copy of the form data to avoid
            // it being changed directly by the prop function
            const data = clone(hookForm.getValues());
            const args = parentPath
                ? [clone(hookForm.getValues(parentPath)), data, getErrors]
                : [data, data, getErrors];
            const prop = invokeFunction(fn, args);
            if (prop !== undefined) {
                (finalProps as any)[name] = prop;
            }
        });
    }

    // Create on change handler
    if (form && finalProps.onChange) {
        parseConditionalFunction(
            `${fullFieldName}_onchange`,
            finalProps.onChange,
            ['value', 'data', 'set', 'setLocal']
        );
    }

    // Create on validate handler
    if (form && finalProps.onValidate) {
        parseConditionalFunction(
            `${fullFieldName}_onvalidate`,
            finalProps.onValidate,
            ['value', 'data']
        );
    }

    // Register field meta
    form?.setMeta?.([...fieldPath, 'meta'].filter((p) => !!p).join('.'), {
        componentName: finalProps.componentName,
        title: finalProps.title,
        description: finalProps.description,
        info: finalProps.info,
        showInOutline: finalProps.showInOutline
    });

    // Custom overrides
    if (props.componentName === 'Informative') {
        finalProps.titleType ??= NeoFormTitleType.HEADER;
    }
    if (props.titleType === 'header' && props.collapsible) {
        finalProps.titleType = NeoFormTitleType.COLLAPSIBLE_HEADER;
    }
    finalProps.readonly = parent?.readonly ?? finalProps.readonly;
    finalProps.required = finalProps.required ?? parent?.required;

    // Hide components you do not have permission to in public form
    if (
        form?.info?.is_public &&
        finalProps.display !== false &&
        !hasChildFieldPermission([name])
    ) {
        finalProps.display = false;
    }
    if (finalProps.display === false) {
        return null;
    }

    const {
        name: _name,
        componentFunction: _function,
        componentName,
        display,
        titleType,
        title,
        description,
        info,
        ...extraProps
    } = finalProps;

    // Map child fields to full field names
    const fields: Record<string, any> = {};
    Object.entries(props.fields ?? {})
        .forEach(([child, props]) => {
            fields[`${fullFieldName}.${child}`] = props;
        });

    const componentProps = {
        name,
        componentName,
        title,
        ...p,
        ...extraProps
    } as NeoFormComponentProps & InnerProps;

    const required = parent?.required ?? props.required;

    const preface = <NeoFormComponentPreface
        name={fullFieldName}
        lang={lang}
        visible={shouldRenderPreface}
        title={title}
        titleType={titleType}
        description={description}
        info={info}
        generate={finalProps.generate}
        for={fullFieldName}
        required={required}
        subtitle={open ? '' : subtitle}
    />;

    return (
        <NeoFormComponentContext.Provider
            value={{
                name,
                path: fieldPath,
                dataPath: isDynamic
                    ? fieldPath
                    : parent?.dataPath
                        ? parent?.dataPath
                        : undefined,
                fields: { ...parent?.fields, ...fields },
                readonly: parent?.readonly ?? props.readonly,
                required,
                encrypted,
                setEncrypted
            }}
        >
            <Col col={finalProps.col} className="tw-self-end">
                {props?.collapsible
                    ? (
                        <Collapsible
                            open={open}
                            onOpenChange={setOpen}
                            className={cn(
                                'tw-w-full',
                                (parent?.path?.length ?? 0) < 2 &&
                                    'tw-border tw-shadow-sm tw-border-gray-100 tw-rounded-lg tw-p-3'
                            )}
                        >
                            <CollapsibleTrigger
                                className={cn('tw-mb-1 tw-flex tw-items-center tw-gap-1 tw-w-full')}
                            >
                                {open
                                    ? <ChevronDownIcon className={cn(
                                        `chevron-${titleType}`
                                    )} />
                                    : <ChevronRightIcon className={cn(
                                        `chevron-${titleType}`
                                    )} />
                                }
                                {preface}
                            </CollapsibleTrigger>
                            <CollapsibleContent>
                                {React.createElement(component, componentProps)}
                            </CollapsibleContent>
                        </Collapsible>
                    )
                    : (
                        <>
                            {preface}
                            {React.createElement(component, componentProps)}
                        </>
                    )}
            </Col>
        </NeoFormComponentContext.Provider>
    );
}
