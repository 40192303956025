import React, { type ReactNode, useMemo, useState } from 'react';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import type { Stage } from '@/types/api/orgs';
import { getFieldError } from '@/composables/validation';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import ObjectId from 'bson-objectid';
import _ from 'lodash';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { cn } from '@/lib/utils';
import { ChevronDownIcon, ChevronRightIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { InputNumber } from '@/components/ui/input-number';
import { Col, Row } from '@/components/ui/row';
import { Label } from '@/components/ui/label';
import { type TranslationObject, useTranslation } from '@/composables/translation';

interface Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends React.ComponentProps<ControllerProps<TFieldValues, TName>['render']> {
    isUnitPriceTotal?: boolean;
    translations?: {
        stage?: string | TranslationObject;
        price?: string | TranslationObject;
    };
}

interface PriceBreakdownItem {
    value: number;
    quantity?: number;
}

interface PriceBreakdown {
    total: number;
    breakdown: PriceBreakdownItem[];
}

function priceBreakdown(qty: number, model: Stage[], isUnitPriceTotal = false): PriceBreakdown {
    if (isUnitPriceTotal) {
        const stage = _.findLast(model, s => s.start <= qty);
        if (!stage) {
            return {
                total: 0,
                breakdown: []
            };
        }
        return {
            total: stage.unit_price,
            breakdown: [{
                value: stage.unit_price
            }]
        };
    }
    const breakdown = model.reduce<PriceBreakdownItem[]>((acc, stage, idx, stages) => {
        const next = stages[idx + 1];
        if (stage.start <= qty) {
            const quantity = (_.min([qty, next ? (next.start - 1) : undefined]) ?? qty) -
                stage.start + (stage.start !== 0 ? 1 : 0);
            acc.push({
                value: stage.unit_price,
                quantity
            });
        }
        return acc;
    }, []);
    return {
        total: breakdown.reduce((acc, item) => acc + (item.quantity ?? 1) * item.value, 0),
        breakdown
    };
}

export function CrudBillingModel<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ field, formState, isUnitPriceTotal, translations }: Props<TFieldValues, TName>) {
    const { to, t } = useTranslation('settings.billing');
    const [quantity, setQuantity] = useState(NaN);
    const [open, setOpen] = useState(false);
    const schema = useMemo<CrudSchema<Stage>>(() => [
        {
            id: 'start',
            type: CrudInputType.NUMBER,
            name: to(translations?.stage, 'Palier'),
            required: true,
            col: 6,
            columnDef: {
                id: 'start',
                header: to(translations?.stage, 'Palier'),
                accessorKey: 'start'
            }
        },
        {
            id: 'unit_price',
            type: CrudInputType.NUMBER,
            name: to(translations?.price, 'Prix'),
            scale: 2,
            required: true,
            col: 6,
            columnDef: {
                id: 'unit_price',
                header: to(translations?.price, 'Prix'),
                accessorKey: 'unit_price',
                cell: ({ cell }) => Intl.NumberFormat('fr-CA', { style: 'currency', currency: 'CAD' })
                    .format(cell.getValue<number>())
            }
        }
    ], []);
    const price = useMemo(
        () => priceBreakdown(
            isNaN(quantity) ? 0 : quantity,
            field.value,
            isUnitPriceTotal
        ),
        [quantity, field.value, isUnitPriceTotal]
    );
    return (
        <div className="tw-flex tw-flex-col tw-gap-3">
            <CrudTable<Stage, 'id'>
                enableGlobalFilter={false}
                enableHiding={false}
                enableColumnFilters={false}
                enablePagination={false}
                enableCsvExport={false}
                idKey="id"
                list={field.value}
                error={!!getFieldError(field.name, formState)}
                schema={schema}
                onRead={() => Promise.resolve(field.value ?? [])}
                onCreate={(value) => {
                    field.onChange(_.sortBy([...(field.value ?? []), {
                        id: ObjectId().toHexString(),
                        ...value
                    }], 'start'));
                    return Promise.resolve();
                }}
                onUpdate={(value) => {
                    const obj = ((field.value ?? []) as Stage[])
                        .find((s) => s.id === value.id);
                    Object.assign(obj ?? {}, value);
                    field.onChange(_.sortBy([...(field.value ?? [])], 'start'));
                    return Promise.resolve();
                }}
                onDelete={(values) => {
                    field.onChange(
                        _.differenceBy(field.value ?? [], values, 'id')
                    );
                    return Promise.resolve();
                }}
            />
            <Collapsible open={open} onOpenChange={setOpen}>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <CollapsibleTrigger className={cn(
                            'tw-flex tw-gap-1 tw-items-center tw-text-sm',
                            'tw-text-muted-foreground tw-font-semibold hover:tw-underline'
                        )}>
                            {open
                                ? <ChevronDownIcon className="tw-relative tw-top-[1px]" />
                                : <ChevronRightIcon className="tw-relative tw-top-[1px]" />}
                            {t('estimate.title')}
                            <InfoCircledIcon className="tw-relative tw-top-[1px]" />
                        </CollapsibleTrigger>
                    </TooltipTrigger>
                    <TooltipContent>
                        {t('estimate.tooltip')}
                    </TooltipContent>
                </Tooltip>
                <CollapsibleContent className="tw-p-1">
                    <Row>
                        <Col col={3}>
                            <Label htmlFor={`${field.name}.qty`}>{t('quantity')}</Label>
                            <InputNumber
                                id={`${field.name}.qty`}
                                min={0}
                                value={quantity}
                                onChange={setQuantity}
                            />
                        </Col>
                        <Col className="tw-flex tw-items-end" col={9}>
                            <div className="tw-h-[36px] tw-leading-[31px] tw-text-lg tw-flex tw-gap-2">
                                <div>=</div>
                                <div>
                                    {Intl.NumberFormat('fr-CA', { style: 'currency', currency: 'CAD' })
                                        .format(price.total)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className={cn(
                        'tw-flex tw-text-sm tw-gap-2 tw-py-1',
                        (!!isUnitPriceTotal || price.total <= 0) && 'tw-hidden'
                    )}>
                        <div>=</div>
                        {(
                            [] as ReactNode[]
                        ).concat(
                            ...price.breakdown
                                .map((item, idx) => [
                                    <div key={`s${idx}`}>
                                        +
                                    </div>,
                                    <div key={idx}>
                                        {item.quantity != null ? `${item.quantity} x ` : ''}
                                        {Intl.NumberFormat('fr-CA', { style: 'currency', currency: 'CAD' })
                                            .format(item.value)}
                                    </div>
                                ])
                        ).slice(1)}
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </div>
    );
}
