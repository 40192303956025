import React, { type KeyboardEvent, useMemo, useRef, useState } from 'react';
import { Input, type InputProps } from '@/components/ui/input';
import { useControllableState } from '@/composables/controllable';
import { Badge } from './badge';
import { cn } from '@/lib/utils';
import { assignRef } from '@/composables/utils';
import { Cross1Icon } from '@radix-ui/react-icons';

interface Props extends Omit<InputProps, 'value' | 'onChange'> {
    value?: number;
    onChange?: React.Dispatch<React.SetStateAction<number>>;
}

const TIME_UNITS = [
    {
        unit: 'w',
        valueMinutes: 7 * 24 * 60
    },
    {
        unit: 'd',
        valueMinutes: 24 * 60
    },
    {
        unit: 'h',
        valueMinutes: 60
    },
    {
        unit: 'm',
        valueMinutes: 1
    }
] as const;

type UnitType = 'w' | 'd' | 'h' | 'm';
interface TimeUnit {
    unit: UnitType;
    value: number;
    total: number;
}

export const InputDuration = React.forwardRef<HTMLInputElement, Props>(
    ({ value, onChange, ...props }, ref) => {
        const inputRef = useRef<HTMLInputElement | null>(null);
        const [currentValue, setCurrentValue] = useControllableState(NaN, value, onChange);
        const [editValue, setEditValue] = useState('');
        const [isFocused, setIsFocused] = useState(false);

        const units = useMemo<TimeUnit[]>(() => {
            if (Number.isNaN(currentValue)) {
                return [];
            }
            const result: TimeUnit[] = [];
            let remaining = currentValue;
            for (const unit of TIME_UNITS) {
                const factor = Math.floor(remaining / unit.valueMinutes);
                if (factor > 0) {
                    const total = factor * unit.valueMinutes;
                    result.push({
                        unit: unit.unit,
                        value: factor,
                        total
                    });
                    remaining -= total;
                }
            }
            return result;
        }, [currentValue]);

        function parseValue(value: string) {
            const array = value.split(/\s+/).map<TimeUnit>((s) => {
                const match = s.match(/^([1-9][0-9]*)([wdhm])$/);
                if (!match) {
                    return undefined as unknown as TimeUnit;
                }
                const unit = TIME_UNITS.find(u => u.unit === match[2]);
                if (!unit) {
                    return undefined as unknown as TimeUnit;
                }
                const value = Number(match[1]);
                return {
                    unit: match[2] as any,
                    value,
                    total: unit.valueMinutes * value
                };
            }).filter(Boolean);
            if (array.length <= 0) {
                return NaN;
            }
            return array.reduce((sum, unit) => sum + unit.total, 0);
        }

        function handleBlur() {
            const value = parseValue(editValue);
            if (!Number.isNaN(value)) {
                setCurrentValue(value);
            }
            setIsFocused(false);
            setEditValue('');
        }

        function handleKeyDown(e: KeyboardEvent) {
            if (['Enter', 'Tab'].includes(e.key)) {
                e.preventDefault();
                e.stopPropagation();
                inputRef.current?.blur();
            }
        }

        return (
            <div className="tw-relative">
                <Input
                    ref={r => {
                        assignRef(ref, r);
                        assignRef(inputRef, r);
                    }}
                    {...props}
                    value={isFocused ? editValue : ''}
                    placeholder={isFocused || Number.isNaN(currentValue) ? 'ex: 1d 3h 45m' : undefined}
                    onChange={(e) => setEditValue(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
                <div className={cn(
                    'tw-absolute tw-inset-0 tw-p-2 tw-flex tw-items-center tw-gap-2 tw-pointer-events-none',
                    isFocused && 'tw-hidden'
                )}>
                    {units.map((unit, idx) => (
                        <Badge key={idx} size="sm">
                            {unit.value}{unit.unit}
                        </Badge>
                    ))}
                    {!Number.isNaN(currentValue) && units.length > 0 && <Cross1Icon
                        className={cn(
                            'tw-ml-auto tw-opacity-50 hover:tw-opacity-100',
                            'tw-cursor-pointer !tw-pointer-events-auto'
                        )}
                        onClick={() => setCurrentValue(NaN)}
                    />}
                </div>
            </div>
        );
    });
InputDuration.displayName = 'InputDuration';
