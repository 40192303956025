import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Button } from '@/components/ui/button';
import { useTranslation } from '@/composables/translation';
import { FolderContext } from '@/pages/psj/Folder';
import { getDocuments } from '@/composables/api';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useError } from '@/composables/error';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog';
import { type File } from '@/types/api/files';
import { FilePreview } from '@/components/neoform/helper/FilePreview';
import mime from 'mime-types';
import { type ColumnDef, type RowSelectionState } from '@tanstack/table-core';
import { DataTable } from '@/components/ui/data-table';
import { EyeOpenIcon } from '@radix-ui/react-icons';
import { DateCell } from '@/components/ui/cells';
import { PREVIEW_MIME_TYPES } from '../DocumentList';

interface ExistingFileAttachmentDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    setAttachments: (files: File[]) => void;
}

export function ExistingFileAttachmentDialog(props: ExistingFileAttachmentDialogProps) {
    const [files, setFiles] = useState<File[]>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const { t, to } = useTranslation('psj.documents');
    const context = useContext(FolderContext);
    const { handleNetworkError } = useError();
    useEffect(() => {
        getDocuments(context?.folder_id ?? '')
            .then((res) => setFiles(res.data))
            .catch(handleNetworkError);
    }, [context?.folder_id]);

    const columns: Array<ColumnDef<File>> = useMemo(() => [
        {
            id: 'preview',
            accessorKey: 'url',
            header: t('fields.preview'),
            cell: (props) => {
                const file = props.row.original;
                if (PREVIEW_MIME_TYPES.some(m => file.content_type?.startsWith(m))) {
                    return (
                        <FilePreview file={{
                            name: file.name,
                            mimeType: file.content_type ?? 'application/octet-stream',
                            url: file.url
                        }} >
                            <Tooltip>
                                <DialogTrigger asChild>
                                    <TooltipTrigger asChild>
                                        <Button
                                            type="button" variant="ghost" size="icon"
                                        >
                                            <EyeOpenIcon />
                                        </Button>
                                    </TooltipTrigger>
                                </DialogTrigger>
                                <TooltipContent>
                                    {t('actions.preview')}
                                </TooltipContent>
                            </Tooltip>
                        </FilePreview>
                    );
                }
                return null;
            }
        },
        {
            id: 'name',
            header: t('fields.name'),
            accessorKey: 'name'
        },
        {
            id: 'content_type',
            header: t('fields.content-type'),
            cell: (props) => (
                mime.extension(props.row.original.content_type ?? 'application/octet-stream') || ''
            ).toUpperCase()
        },
        {
            id: 'updated_at',
            header: t('fields.updated-at'),
            accessorKey: 'updated_at',
            cell: DateCell
        }
    ], []);

    const handleSubmit = () => {
        const selectedFiles = files.filter((file, index) => rowSelection[index]);
        props.setAttachments(selectedFiles);
        props.setOpen(false);
        setRowSelection({});
    };
    const selectedLength = Object.keys(rowSelection).length;

    return (
        <Dialog open={props.open} onOpenChange={props.setOpen}>
            <DialogContent className="!tw-min-w-[1000px] !tw-pb-0">
                <DialogHeader>
                    <DialogTitle>
                        {to({ en: 'Existing files', fr: 'Fichiers existants' })}
                    </DialogTitle>
                </DialogHeader>
                <DataTable<File, '_id'>
                    enableRowSelection
                    onRowSelectionChange={setRowSelection}
                    columns={columns}
                    data={files}
                    enableCsvExport={false}
                    initialState={{ columnVisibility: { _id: false } }}
                    state={{ rowSelection }}
                />
                <DialogFooter
                    className='tw-sticky tw-bottom-0 tw-p-4 tw-bg-white
                        tw-border-t tw-border-gray-200 tw-shadow-md'
                >
                    <Button disabled={!selectedLength} onClick={handleSubmit}>
                        {to({
                            en: `Attach files${selectedLength > 1 ? 's' : ''} (${selectedLength})`,
                            fr: `Attacher fichier${selectedLength > 1 ? 's' : ''} (${selectedLength})`
                        })}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
