import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { type GetPartyOptions, type Party, type UpdatePartyDto } from '@/types/api/party';
import {
    createCase, deleteParty,
    getCalendarConfig, getMacrosList,
    getOrgGroups,
    getOrgParties, getOrgUsers,
    postCreateParty,
    putUpdateParty
} from '@/composables/api';
import { CreateCaseDialog } from '@/components/psj/case/CreateCaseDialog';
import { type Paginated } from '@/types/api/pagination';
import { useQuery } from '@/composables/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@/components/ui/button';
import { faFolderOpen, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import type { User, UserGroup } from '@/types/api/user';
import type { CalendarConfig } from '@/types/api/calendar';
import { BindProps } from '@/components/utils/BindProps';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { PHONE_NUMBER_MASK, POSTAL_CODE_MASK } from '@/composables/masks';
import type { Macro } from '@/types/api/macro';
import type { PaginationState } from '@tanstack/react-table';
import { transformConfig } from '@/composables/calendar';
import { useValidation } from '@/composables/validation';
import axios from 'axios';

export function useClientSchema(): CrudSchema<UpdatePartyDto> {
    const { t } = useTranslation('settings.clients');
    const { email } = useValidation();
    return useMemo<CrudSchema<UpdatePartyDto>>(() => [
        {
            id: '_id',
            type: CrudInputType.TEXT,
            name: t('table.id'),
            readonly: true,
            update: false,
            columnDef: {
                id: '_id',
                header: t('table.id'),
                accessorKey: '_id'
            }
        },
        {
            id: 'fullname',
            type: CrudInputType.TEXT,
            name: t('table.full-name'),
            update: false,
            create: false,
            columnDef: {
                id: 'fullname',
                header: t('table.full-name'),
                accessorKey: 'fullname'
            }
        },
        {
            id: 'firstname',
            type: CrudInputType.TEXT,
            name: t('table.first-name'),
            col: 6
        },
        {
            id: 'lastname',
            type: CrudInputType.TEXT,
            name: t('table.last-name'),
            col: 6
        },
        {
            id: 'company',
            type: CrudInputType.TEXT,
            name: t('table.company'),
            columnDef: {
                id: 'company',
                header: t('table.company'),
                accessorKey: 'company'
            }
        },
        {
            id: 'phone',
            type: CrudInputType.TEXT,
            name: t('table.phone'),
            col: 4,
            columnDef: {
                id: 'phone',
                header: t('table.phone'),
                accessorKey: 'phone'
            },
            mask: PHONE_NUMBER_MASK
        },
        {
            id: 'extension',
            type: CrudInputType.NUMBER,
            name: t('table.extension'),
            col: 3
        },
        {
            id: 'email',
            type: CrudInputType.TEXT,
            name: t('table.email'),
            col: 5,
            columnDef: {
                id: 'email',
                header: t('table.email'),
                accessorKey: 'email'
            },
            validate: {
                custom: (value: any) => !value || email(value)
            }
        },
        {
            id: 'address.street',
            type: CrudInputType.TEXT,
            name: t('table.address.street')
        },
        {
            id: 'address.city',
            type: CrudInputType.TEXT,
            name: t('table.address.city'),
            col: 6
        },
        {
            id: 'address.postal_code',
            type: CrudInputType.TEXT,
            name: t('table.address.postal_code'),
            col: 6,
            mask: POSTAL_CODE_MASK
        },
        {
            id: 'address.country',
            type: CrudInputType.TEXT,
            name: t('table.address.country'),
            col: 6
        },
        {
            id: 'address.province',
            type: CrudInputType.TEXT,
            name: t('table.address.province'),
            col: 6
        }
    ], []);
}

export function Clients() {
    const { ct, t } = useTranslation('settings.clients');
    const query = useQuery<Paginated<GetPartyOptions>>();
    const [showCreate, setShowCreate] = useState(false);
    const [selected, setSelected] = useState<Party>();
    const [users, setUsers] = useState<User[]>([]);
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const [macros, setMacros] = useState<Macro[]>([]);
    const [config, setConfig] = useState<CalendarConfig>();
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10
    });
    const CreateDialogComponent = useMemo(
        () => BindProps(CreateCaseDialog, {
            client: selected,
            users,
            groups,
            macros
        }),
        [selected, users, groups, macros, config]
    );
    const schema = useClientSchema();
    useEffect(() => {
        getOrgUsers().then((res) => setUsers(res.data));
        getOrgGroups().then((res) => setGroups(res.data));
        getMacrosList({ limit: 10000 }).then((res) => setMacros(res.data));
        getCalendarConfig().then((res) => setConfig(transformConfig(res.data)));
    }, []);
    return (
        <main className="tw-min-h-full tw-p-4">
            <Card>
                <CardHeader>
                    <CardTitle>{t('title')}</CardTitle>
                </CardHeader>
                <CardContent>
                    <CrudTable<UpdatePartyDto, '_id'>
                        useServerSearch
                        singleItemDeletion
                        idKey="_id"
                        schema={schema}
                        initialState={{ columnVisibility: { _id: false } }}
                        actions={({ row }) => (
                            <>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button
                                            className="tw-text-muted-foreground"
                                            variant="ghost" size="icon"
                                            onClick={() => {
                                                setSelected(row.original);
                                                setShowCreate(true);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faFolderPlus} />
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {t('table.actions.create')}
                                    </TooltipContent>
                                </Tooltip>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button
                                            className="tw-text-muted-foreground"
                                            variant="ghost" size="icon"
                                            asChild
                                        >
                                            <Link to={axios.getUri({
                                                url: '/dashboard',
                                                params: {
                                                    tab: `${ct('folders')} — ${row.original.fullname}`,
                                                    party_id: row.original._id,
                                                    my_folders: false
                                                }
                                            })}>
                                                <FontAwesomeIcon icon={faFolderOpen} />
                                            </Link>
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {t('table.actions.open')}
                                    </TooltipContent>
                                </Tooltip>
                            </>
                        )}
                        readDeps={[query]}
                        onRead={({ search_value: search, search_type }) =>
                            getOrgParties({
                                ...query,
                                ...(search && {
                                    search_index: true,
                                    search_type,
                                    search_value: search
                                }),
                                offset: pagination.pageIndex * pagination.pageSize,
                                limit: pagination.pageSize
                            }).then((res) => {
                                setRowCount(res.data.nb_rows);
                                setPagination({
                                    pageSize: res.data.limit,
                                    pageIndex: pagination.pageIndex
                                });
                                return res.data.data;
                            })
                        }
                        onCreate={(value) => postCreateParty({
                            ...value,
                            fullname: value.firstname || value.lastname
                                ? `${value.firstname ?? ''} ${value.lastname ?? ''}`
                                : ''
                        }).then()}
                        onDelete={([value]) => deleteParty(value._id).then()}
                        onUpdate={(value) => putUpdateParty({
                            ...value,
                            fullname: value.firstname || value.lastname
                                ? `${value.firstname ?? ''} ${value.lastname ?? ''}`
                                : ''
                        }).then()}
                        state={{ pagination }}
                        rowCount={rowCount}
                        onPaginationChange={setPagination}
                        manualPagination
                    />
                    <CreateDialogComponent
                        open={showCreate}
                        onOpenChange={setShowCreate}
                        groups={groups}
                        calendarConfig={config}
                        onSubmit={(value) => createCase(value).then(() => {
                            setShowCreate(false);
                            toast(ct('messages.success'), { type: 'success' });
                        })}
                    />
                </CardContent>
            </Card>
        </main>
    );
}
