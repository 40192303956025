import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faSearch } from '@fortawesome/free-solid-svg-icons';
import { combine, type TranslationObject, useTranslation } from '@/composables/translation';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Cross1Icon, MagnifyingGlassIcon, OpenInNewWindowIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { CaseStatusCell, DateCell, FolderIdCellOptions, FormStatusCell, LinkCell } from '@/components/ui/cells';
import type { Party } from '@/types/api/party';
import { type ColumnDef, type PaginationState } from '@tanstack/react-table';
import { DataTable } from '@/components/ui/data-table';
import { useDebounced } from '@/composables/debounce';
import { getFolders, getFormsListSearch, getOrgParties } from '@/composables/api';
import { useError } from '@/composables/error';
import axios, { type AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';
import { type PaginatedApiResponse } from '@/types/api/response';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import type { FormListEntry } from '@/types/api/forms';
import { DateTime } from 'luxon';
import { getFormStatusFromList } from '@/composables/neoform';
import { useUserStore } from '@/store/user';

const SEARCH_TYPES = {
    folders: {
        baseURL: '/dashboard',
        label: combine('common.folders')
    },
    folders_notes: {
        baseURL: '/dashboard',
        label: combine('common.folders-by-notes')
    },
    clients: {
        baseURL: '/clients',
        label: combine('common.clients')
    },
    forms: {
        baseURL: '/forms',
        label: combine('common.forms')
    }
};

type SearchType = keyof typeof SEARCH_TYPES;

interface SearchOptions {
    type: SearchType;
    limit?: number;
    offset?: number;
    exact?: boolean;
}

export function SearchModal() {
    const { ct, t: t_dashboard, to } = useTranslation('dashboard');
    const { t: t_client } = useTranslation('settings.clients');
    const { t: t_forms } = useTranslation('forms');
    const { handleNetworkError } = useError();
    const { org_id } = useUserStore(state => ({ org_id: state.user?.org_id }));

    const [search, setSearch] = useState('');
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState<SearchType>('folders');
    const [exact, setExact] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10
    });

    const searchTypeOptions = SEARCH_TYPES[type];
    const searchFn = useCallback(_handleSearch, [setData]);
    const handleSearch = useDebounced({ func: searchFn, timeout: 1000 });

    const searchUrl = useMemo(() => axios.getUri({
        baseURL: searchTypeOptions.baseURL,
        params: {
            ...searchParams(search, { type, exact }),
            tab: `${to(searchTypeOptions.label)}: "${search}"`
        }
    }), [type, search]);

    const FolderIdCell = useMemo(() => LinkCell({
        ...FolderIdCellOptions,
        onClick: () => setOpen(false)
    }), [setOpen]);

    function createPartyFoldersLink(party: Party) {
        return axios.getUri({
            baseURL: '/dashboard',
            params: {
                party_id: party._id,
                my_folders: false,
                tab: `${ct('folders')} — ${party.fullname}`
            }
        });
    }

    const PartyNameCell = useMemo(() => LinkCell({
        link: ({ row }) => createPartyFoldersLink(row.original),
        onClick: () => setOpen(false)
    }), [setOpen]);

    useEffect(() => {
        if (!search) {
            setData([]);
        } else {
            handleSearch(search, {
                type,
                exact,
                limit: pagination.pageSize,
                offset: pagination.pageIndex * pagination.pageSize
            });
        }
    }, [search]);

    useEffect(() => {
        if (search) {
            searchFn(search, {
                type,
                exact,
                limit: pagination.pageSize,
                offset: pagination.pageIndex * pagination.pageSize
            });
        }
    }, [type, exact, pagination.pageSize, pagination.pageIndex]);

    const columns: Array<ColumnDef<any>> = useMemo(() => ({
        folders_notes: [
            {
                id: 'folder_id',
                accessorKey: 'folder_id',
                header: t_dashboard('table.id'),
                cell: FolderIdCell
            },
            {
                id: 'name',
                header: t_dashboard('table.name'),
                accessorKey: 'name'
            },
            {
                id: 'status',
                header: t_dashboard('table.status'),
                accessorKey: 'status',
                cell: CaseStatusCell
            },
            {
                id: 'main_client',
                header: t_dashboard('table.client'),
                accessorKey: 'main_client',
                cell: ({ cell }) => cell.getValue<Party | undefined>()?.fullname
            },
            {
                id: 'updated_at',
                header: t_dashboard('table.updated_at'),
                accessorKey: 'updated_at',
                cell: DateCell
            },
            {
                id: 'created_at',
                header: t_dashboard('table.created_at'),
                accessorKey: 'created_at',
                cell: DateCell
            }
        ],
        folders: [
            {
                id: 'folder_id',
                accessorKey: 'folder_id',
                header: t_dashboard('table.id'),
                cell: FolderIdCell
            },
            {
                id: 'name',
                header: t_dashboard('table.name'),
                accessorKey: 'name'
            },
            {
                id: 'status',
                header: t_dashboard('table.status'),
                accessorKey: 'status',
                cell: CaseStatusCell
            },
            {
                id: 'main_client',
                header: t_dashboard('table.client'),
                accessorKey: 'main_client',
                cell: ({ cell }) => cell.getValue<Party | undefined>()?.fullname
            },
            {
                id: 'updated_at',
                header: t_dashboard('table.updated_at'),
                accessorKey: 'updated_at',
                cell: DateCell
            },
            {
                id: 'created_at',
                header: t_dashboard('table.created_at'),
                accessorKey: 'created_at',
                cell: DateCell
            }
        ],
        clients: [
            {
                id: 'actions',
                header: 'Actions',
                size: 50,
                cell: ({ row }) => <>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button
                                className="tw-text-muted-foreground"
                                variant="ghost" size="icon" asChild
                            >
                                <Link
                                    to={createPartyFoldersLink(row.original)}
                                    onClick={() => setOpen(false)}
                                >
                                    <FontAwesomeIcon icon={faFolderOpen} />
                                </Link>
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {t_client('table.actions.open')}
                        </TooltipContent>
                    </Tooltip>
                </>
            },
            {
                id: 'fullname',
                header: t_client('table.full-name'),
                accessorKey: 'fullname',
                cell: PartyNameCell
            },
            {
                id: 'company',
                header: t_client('table.company'),
                accessorKey: 'company'
            },
            {
                id: 'phone',
                header: t_client('table.phone'),
                accessorKey: 'phone'
            },
            {
                id: 'email',
                header: t_client('table.email'),
                accessorKey: 'email'
            }
        ],
        forms: [
            {
                id: 'ticket_id',
                accessorKey: 'ticket_id',
                header: t_forms('table.folder'),
                cell: FolderIdCell
            },
            {
                id: 'title',
                accessorKey: 'title',
                header: t_forms('table.title'),
                cell: ({ cell }) => <Tooltip>
                    <TooltipTrigger asChild>
                        <p className="tw-max-w-[200px] tw-truncate">
                            {to(cell.getValue<TranslationObject>())}
                        </p>
                    </TooltipTrigger>
                    <TooltipContent align="start">
                        {to(cell.getValue<TranslationObject>())}
                    </TooltipContent>
                </Tooltip>,
                sortingFn: 'auto'
            },
            {
                id: 'status',
                accessorKey: 'status',
                header: t_forms('table.status'),
                cell: FormStatusCell,
                size: 50,
                filterFn: (row, id, filterValue) => {
                    const value = row.getValue<FormListEntry['status']>(id);
                    const [status] = getFormStatusFromList(value);
                    return !filterValue || status === filterValue;
                },
                sortingFn: (rowA, rowB, columnId) => {
                    const [statusA, progressA] = getFormStatusFromList(rowA.getValue(columnId));
                    const [statusB, progressB] = getFormStatusFromList(rowB.getValue(columnId));
                    const statusDiff = statusA - statusB;
                    if (statusDiff === 0) {
                        return (progressA ?? 0) - (progressB ?? 0);
                    }
                    return statusDiff;
                }
            },
            {
                id: 'date_created',
                accessorKey: 'date_created',
                header: t_forms('table.date'),
                filterFn: (row, columnId, filterValue: DateTime) => {
                    const value = row.getValue<string>(columnId);
                    const date = DateTime.fromISO(value).startOf('day');
                    return !filterValue?.isValid || filterValue.startOf('day').equals(date);
                },
                cell: DateCell
            },
            ...(org_id === '659f0e9eda489da3aa9c8a4c'
                ? [
                    {
                        id: 'client_data.folderNumber',
                        accessorKey: 'client_data.folderNumber',
                        header: t_forms('table.folderNumber')
                    },
                    {
                        id: 'client_data.debtor',
                        accessorKey: 'client_data.debtor',
                        header: t_forms('table.debtor')
                    }
                ] as Array<ColumnDef<any>>
                : [])
        ]
    } as Record<SearchType, Array<ColumnDef<any>>>)[type], [type]);

    function searchParams(search: string, opts: SearchOptions): any {
        return {
            search_index: true,
            search_type: opts.exact ? 'phrase' : 'text',
            search_value: search,
            limit: opts.limit,
            offset: opts.offset,
            ...(opts.type === 'folders_notes' && { search_in_notes: true })
        };
    }

    function initiateSearch(
        search: string,
        opts: SearchOptions
    ): Promise<AxiosResponse<PaginatedApiResponse<any>>> {
        const params = searchParams(search, opts);
        switch (opts.type) {
        case 'folders_notes':
            return getFolders({ ...params, search_in_notes: true })
                .then((res) => {
                    setRowCount(res.data.nb_rows);
                    if (pagination.pageSize !== res.data.limit) {
                        setPagination({
                            pageSize: res.data.limit,
                            pageIndex: pagination.pageIndex
                        });
                    }
                    return res;
                });
        case 'folders':
            return getFolders(params)
                .then((res) => {
                    setRowCount(res.data.nb_rows);
                    if (pagination.pageSize !== res.data.limit) {
                        setPagination({
                            pageSize: res.data.limit,
                            pageIndex: pagination.pageIndex
                        });
                    }
                    return res;
                });
        case 'clients':
            return getOrgParties(params)
                .then((res) => {
                    setRowCount(res.data.nb_rows);
                    if (pagination.pageSize !== res.data.limit) {
                        setPagination({
                            pageSize: res.data.limit,
                            pageIndex: pagination.pageIndex
                        });
                    }
                    return res;
                });
        case 'forms':
            return getFormsListSearch(params).then((res) => {
                res.data.data.forEach(f => {
                    f.date_created = DateTime
                        .fromFormat(f.date_created, 'yyyy-MM-dd HH:mm:ss')
                        .toISO();
                });
                // setRowCount(res.data.nb_rows);
                // setPagination({
                //     pageSize: res.data.limit,
                //     pageIndex: pagination.pageIndex
                // });
                return res;
            });
        }
    }

    function _handleSearch(search: string, opts: SearchOptions) {
        setLoading(true);
        initiateSearch(search, opts)
            .then((res) => setData(res.data.data))
            .catch(handleNetworkError)
            .finally(() => setLoading(false));
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button
                    variant="ghost" size="icon"
                    className={cn(
                        '!tw-size-12 tw-text-xl !tw-text-white',
                        '!tw-rounded-full hover:!tw-bg-black/15'
                    )}
                >
                    <FontAwesomeIcon icon={faSearch}/>
                </Button>
            </DialogTrigger>
            <DialogContent className="tw-flex tw-flex-col md:tw-max-w-5xl">
                <DialogHeader>
                    <DialogTitle>
                        {to({
                            en: 'Search',
                            fr: 'Recherche'
                        })}
                    </DialogTitle>
                </DialogHeader>
                <div className="tw-flex tw-gap-2">
                    <Select
                        value={type}
                        onValueChange={(value) => setType(value as SearchType)}
                    >
                        <SelectTrigger className="!tw-max-w-[160px]">
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {Object.entries(SEARCH_TYPES).map(([k, opts]) => (
                                    <SelectItem key={k} value={k}>
                                        {to(opts.label)}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                    <Input
                        className="tw-flex-1"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        placeholder={to({
                            en: 'Type to search...',
                            fr: 'Tapez pour rechercher...'
                        })}
                        prependIcon={<MagnifyingGlassIcon/>}
                        prependBorder={false}
                        appendIcon={
                            <Cross1Icon
                                className={cn(
                                    'tw-cursor-pointer tw-text-muted-foreground hover:tw-text-foreground',
                                    !search && 'tw-hidden'
                                )}
                                onClick={() => setSearch('')}
                            />
                        }
                        appendBorder={false}
                    />
                    <Button asChild>
                        <Link
                            to={searchUrl}
                            className={cn(!search && 'tw-pointer-events-none tw-opacity-50')}
                            onClick={() => setOpen(false)}
                        >
                            <OpenInNewWindowIcon className="tw-mr-2" />
                            Ouvrir comme vue
                        </Link>
                    </Button>
                </div>
                <div className="tw-ml-auto tw-flex tw-items-center">
                    <Checkbox
                        id="exact-search"
                        checked={exact}
                        onCheckedChange={(value) => setExact(Boolean(value))}
                    />
                    <Label className="tw-font-semibold tw-pl-2" htmlFor="exact-search">
                        {to({
                            en: 'Search entire exact phrase',
                            fr: 'Rechercher la phrase entière exacte'
                        })}
                    </Label>
                </div>
                <div className="tw-overflow-x-auto">
                    <DataTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        enableGlobalFilter={false}
                        enableColumnFilters={false}
                        enableHiding={false}
                        numSkeletonRows={3}
                        state={type !== 'forms' ? { pagination } : undefined}
                        rowCount={type !== 'forms' ? rowCount : undefined}
                        onPaginationChange={type !== 'forms' ? setPagination : undefined}
                        manualPagination={type !== 'forms'}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
}
