import React, { useMemo, useState } from 'react';
import { useTranslation } from '@/composables/translation';
import {
    Dialog, DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Eml } from '@/components/ui/eml';
import { Msg } from '@/components/ui/msg';
import { overrideUrlDomain } from '@/composables/url';

enum FilePreviewTab {
    DOCUMENT = 'document',
    TEXT = 'text',
    JSON = 'json'
}

interface Props {
    file: {
        url?: string;
        data?: string;
        mimeType: string;
        name: string;
    };
    meta?: {
        text?: string;
        json?: string;
    };
    hideControls?: boolean;
    open?: boolean;
    onOpenChange?: (value: boolean) => void;
    children?: React.ReactNode;
}

const CUSTOM_PREVIEWS: Record<string, React.FC<{ url: string }>> = {
    'message/rfc822': Eml,
    'application/vnd.ms-outlook': Msg
};

export function FilePreview(props: Props) {
    const { t } = useTranslation('neoform.preview');
    const [selected, setSelected] = useState(FilePreviewTab.DOCUMENT);
    const hasMeta = !!props.meta && (!!props.meta?.text || !!props.meta?.json);
    const url = props.file.data
        ? `data:${props.file.mimeType};base64,${props.file.data}`
        : props.file.url;
    const docxUrl = props.file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ? `https://docs.google.com/gview?url=${encodeURIComponent(props.file.url ?? '')}&embedded=true`
        : '';
    // eslint-disable-next-line
    const previewUrl = overrideUrlDomain(url || docxUrl);
    const PreviewComponent = useMemo(
        () => CUSTOM_PREVIEWS[props.file.mimeType],
        [props.file.mimeType]
    );
    return (
        <Dialog
            open={props.open}
            onOpenChange={props.onOpenChange}
        >
            {props.children}
            <DialogContent className="tw-h-[calc(100%_-_64px)] md:tw-max-w-5xl !tw-flex tw-flex-col">
                <DialogHeader>
                    <DialogTitle>{t('title')}</DialogTitle>
                    <DialogDescription>{props.file.name}</DialogDescription>
                </DialogHeader>
                <Tabs
                    className="tw-flex-1 tw-flex tw-flex-col"
                    defaultValue={FilePreviewTab.DOCUMENT}
                    value={selected}
                    onValueChange={(value) => setSelected(value as FilePreviewTab)}
                >
                    {hasMeta &&
                        <TabsList>
                            <TabsTrigger value={FilePreviewTab.DOCUMENT}></TabsTrigger>
                            <TabsTrigger value={FilePreviewTab.TEXT}></TabsTrigger>
                            <TabsTrigger value={FilePreviewTab.JSON}></TabsTrigger>
                        </TabsList>
                    }
                    <TabsContent
                        className={cn(
                            'tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-items-center',
                            !hasMeta && '!tw-mt-0'
                        )}
                        value={FilePreviewTab.DOCUMENT}
                    >
                        {!PreviewComponent && <iframe className="tw-w-full tw-h-full" src={previewUrl}/>}
                        {PreviewComponent && url && <PreviewComponent url={url} />}
                    </TabsContent>
                    <TabsContent value={FilePreviewTab.TEXT}></TabsContent>
                    <TabsContent value={FilePreviewTab.JSON}></TabsContent>
                </Tabs>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button>
                            OK
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
