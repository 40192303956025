import React, { useContext, useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import { faFolderTree, faListUl, faPlus, faPrint, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { MenuButton } from '@/components/MenuButton';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { CreateFormDialog } from '@/components/psj/form/CreateFormDialog';
import { FolderContext } from '@/pages/psj/Folder';
import { useUserStore } from '@/store/user';
import { deleteFolder, postCreateForm, postFormClients } from '@/composables/api';
import { useError } from '@/composables/error';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useOverrideConfig } from '@/composables/override';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { useTabStore } from '@/store/tabs';

export const TAB_OPTIONS = ['notes', 'documents'] as const;
export type TabValue = typeof TAB_OPTIONS[number];

interface Props {
    tab: TabValue;
    setTab: (value: TabValue) => void;
}

export function HeaderActionBar(props: Props) {
    const { ct, t } = useTranslation('psj.actions');
    const folder = useContext(FolderContext);
    const { handleNetworkError } = useError();
    const override = useOverrideConfig();
    const { lang } = useUserStore(state => ({ lang: state.lang }));
    const navigate = useNavigate();
    const location = useLocation();
    const tabStore = useTabStore();
    const options = [
        { label: t('communications'), value: 'notes' },
        { label: t('documents'), value: 'documents' }
    ] as const;
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showDeleteFolder, setShowDeleteFolder] = useState(false);
    const clientParty = folder?.case?.main_client;
    const masterFolderUrl = axios.getUri({
        url: '/dashboard',
        params: {
            ...(clientParty && {
                tab: `${t('master-folder.tab')}${clientParty.fullname}`,
                party_id: clientParty._id
            })
        }
    });

    function handleDeleteFolder() {
        return deleteFolder(folder?.folder_id ?? '')
            .then(() => {
                toast.success(ct('messages.success'));
                navigate('/dashboard');
                tabStore.removeTab(location.pathname + location.search);
            })
            .catch(handleNetworkError);
    }

    return (
        <Card className="print:tw-hidden">
            <CardContent className="!tw-p-3 tw-flex tw-gap-y-2 tw-flex-wrap tw-items-center tw-justify-between">
                {!override?.is_customer_portal && <Button
                    disabled={folder?.loading}
                    className="!tw-px-4" variant="outline"
                >
                    <Link to={masterFolderUrl}>
                        <FontAwesomeIcon
                            className="tw-mr-2"
                            icon={faFolderTree}
                        />
                        {t('master-folder.title')}
                    </Link>
                </Button>}
                <Tabs value={props.tab} onValueChange={(value) => props.setTab(value as TabValue)}>
                    <TabsList>
                        {options.map(({ label, value }) => (
                            <TabsTrigger key={value} value={value}>
                                {label}
                            </TabsTrigger>
                        ))}
                    </TabsList>
                </Tabs>
                <div className="tw-flex tw-gap-1">
                    {!override?.is_customer_portal && <>
                        <Button
                            variant="outline"
                            disabled={folder?.loading}
                        >
                            <Link to={axios.getUri({
                                baseURL: '/forms',
                                params: {
                                    tab: `${t('forms-list.tab')}${folder?.folder_id}`,
                                    folder_id: folder?.folder_id ?? ''
                                }
                            })}>
                                <FontAwesomeIcon className="tw-mr-2" icon={faListUl} />
                                {t('forms-list.title')}
                            </Link>
                        </Button>
                        <Button
                            variant="outline"
                            disabled={folder?.loading}
                            onClick={() => setShowCreateForm(true)}
                        >
                            <FontAwesomeIcon className="tw-mr-2" icon={faPlus} />
                            {t('create-form.title')}
                        </Button>
                    </>}
                    <MenuButton
                        id="account"
                        tooltip={ct('more-actions')}
                        renderButton={(props) => (
                            <Button
                                {...props}
                                disabled={folder?.loading}
                                variant="ghost" size="icon"
                                className="!tw-rounded-full !tw-text-xl"
                            >
                                <DotsVerticalIcon className="tw-size-5" />
                            </Button>
                        )}
                        options={[
                            {
                                value: 'print',
                                label: t('print.title'),
                                icon: faPrint,
                                onClick: () => window.print()
                            },
                            {
                                value: 'delete',
                                label: ct('delete'),
                                icon: faTrashAlt,
                                destructive: true,
                                onClick: () => setShowDeleteFolder(true)
                            }
                        ]}
                    />
                </div>
                {!override?.is_customer_portal && <CreateFormDialog
                    open={showCreateForm}
                    onOpenChange={setShowCreateForm}
                    onSubmit={(value) =>
                        postCreateForm(value as any)
                            .then((res) =>
                                postFormClients(
                                    res.data.task_id,
                                    (
                                        folder?.parties ?? []
                                    )
                                        .map(p => (
                                            {
                                                id: uuid(),
                                                firstname: p.firstname,
                                                lastname: p.lastname,
                                                email: p.email,
                                                subject: '',
                                                content: '',
                                                secure_2fa: true,
                                                permissions: null,
                                                init_public_url: 1
                                            }
                                        ))
                                ).then(() => res)
                            )
                            .then((res) => {
                                setShowCreateForm(false);
                                toast(ct('messages.success'), { type: 'success' });
                                const { task_id, form_name } = res.data;
                                window.open(`/form/${form_name}/${task_id}`, '_blank');
                            })
                            .catch(handleNetworkError)
                    }
                    value={{
                        ticket_id: folder?.folder_id,
                        case_id: folder?.case?._id,
                        lang
                    }}
                />}
                <ConfirmDialog
                    open={showDeleteFolder}
                    onOpenChange={setShowDeleteFolder}
                    className="!tw-max-w-xl"
                    title={t('delete.title')}
                    message={t('delete.message')}
                    confirmText={ct('delete')}
                    confirmInputValue={folder?.folder_id}
                    onConfirm={handleDeleteFolder}
                />
            </CardContent>
        </Card>
    );
}
