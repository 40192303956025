import React, { createContext, useEffect, useState, Children, useContext, useRef } from 'react';
import { getEventCoordinates } from '@/composables/utils';
import { cn } from '@/lib/utils';

interface Point2 {
    x: number;
    y: number;
}

interface Context {
    position: Point2;
    isDragging: boolean;
    dragStart: Point2;
    onDragStart: (e:
        React.MouseEvent<HTMLDivElement, MouseEvent> |
        React.TouchEvent<HTMLDivElement>
    ) => void;
}

const DraggableContext = createContext<Context | undefined>(undefined);

interface DraggableHandleProps {
    className?: string;
    children: React.ReactNode;
}

export function DraggableHandle(props: DraggableHandleProps) {
    if (Children.count(props.children) !== 1) {
        throw new Error('DraggableHandle must have exactly one child element');
    }
    const context = useContext(DraggableContext);
    if (!context) {
        throw new Error('DraggableHandle must be used within a DraggableContext');
    }
    return (
        <div
            className={cn('tw-touch-none', props.className)}
            onMouseDown={context.onDragStart}
            onTouchStartCapture={context.onDragStart}
        >
            {props.children}
        </div>
    );
}

interface DraggableProps {
    className?: string;
    children?: React.ReactNode;
    defaultPosition?: {
        left?: number;
        top?: number;
        right?: number;
        bottom?: number;
    };
}

export function Draggable(props: DraggableProps) {
    const ref = useRef<HTMLDivElement | null>(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (ref.current && props.defaultPosition) {
            let x = props.defaultPosition.left ?? 0;
            let y = props.defaultPosition.top ?? 0;
            if (props.defaultPosition.right) {
                x = window.innerWidth - ref.current.clientWidth - props.defaultPosition.right;
            }
            if (props.defaultPosition.bottom) {
                y = window.innerHeight - ref.current.clientHeight - props.defaultPosition.bottom;
            }
            setPosition({ x, y });
        }
    }, []);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent | TouchEvent) => {
            if (isDragging) {
                const { x, y } = getEventCoordinates(e);
                setPosition({
                    x: x - dragStart.x,
                    y: y - dragStart.y
                });
            }
        };

        const handleMouseUp = () => {
            setIsDragging(false);
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('touchmove', handleMouseMove);
            window.addEventListener('touchend', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchmove', handleMouseMove);
            window.removeEventListener('touchend', handleMouseUp);
        };
    }, [isDragging, dragStart]);

    const onDragStart = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>
    ) => {
        e.preventDefault();
        const { x, y } = getEventCoordinates(e as any);
        setIsDragging(true);
        setDragStart({
            x: x - position.x,
            y: y - position.y
        });
    };

    return (
        <DraggableContext.Provider
            value={{ position, isDragging, dragStart, onDragStart }}
        >
            <div
                ref={ref}
                className={cn('tw-select-none tw-absolute', props.className)}
                style={{
                    left: `${position.x}px`,
                    top: `${position.y}px`
                }}
            >
                {props.children}
            </div>
        </DraggableContext.Provider>
    );
}
