import React from 'react';
import { faFolderOpen, faHourglass, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@/composables/translation';
import { Badge, type BadgeProps } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { type CaseStatus } from '@/types/folder';

interface Props extends BadgeProps {
    status: CaseStatus;
}

export const STATUS_INFO = {
    open: {
        icon: faFolderOpen,
        title: 'open',
        color: 'success'
    },
    pending: {
        icon: faHourglass,
        title: 'pending',
        color: 'default'
    },
    closed: {
        icon: faLock,
        title: 'closed',
        color: 'destructive'
    },
    created: {
        icon: faFolderOpen,
        title: 'open',
        color: 'success'
    }
} as const;

export function StatusChip({ status, ...props }: Props) {
    const { t } = useTranslation('folder.status');
    const statusInfo = STATUS_INFO[status];
    return (
        <Badge
            size="md"
            {...props}
            variant={statusInfo?.color}
            className={cn('!tw-rounded-full', props.className)}
        >
            <div className="tw-flex tw-items-center">
                <FontAwesomeIcon
                    className="tw-mr-2"
                    icon={statusInfo?.icon}
                />
                <span className="tw-text-base">{t(statusInfo?.title)}</span>
            </div>
        </Badge>
    );
}
