import { combine } from '@/composables/translation';

export const ROLE_LIST = [
    'admin',
    'lawyer'
] as const;

export type Role = typeof ROLE_LIST[number];

export const ROLE_OPTIONS = ROLE_LIST.map((role) => ({
    value: role,
    label: combine(`common.roles.${role}`)
}));
