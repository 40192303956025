import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { type MutableRefObject, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Button } from './button';
import { PauseIcon, StopIcon, PlayIcon } from '@radix-ui/react-icons';
import { Duration } from 'luxon';

interface StopwatchApi {
    time: number;
    running: boolean;
    reset: () => void;
    setTime: (value: number) => void;
    setRunning: (running: boolean) => void;
}

interface StopEventOptions {
    time: number;
}

interface StopwatchProps {
    apiRef?: MutableRefObject<StopwatchApi>;
    className?: string;
    slots?: {
        start?: React.ReactNode;
        title?: React.ReactNode;
    };
    onStop?: (opts: StopEventOptions) => void;
}

export function Stopwatch(props: StopwatchProps) {
    const [time, setTime] = useState(0);
    const [running, setRunning] = useState(false);

    const duration = useMemo(
        () => Duration.fromDurationLike({ seconds: time }),
        [time]
    );

    function handleReset() {
        props.onStop?.({ time });
        setRunning(false);
        setTime(0);
    }

    useImperativeHandle(props.apiRef, () => ({
        time,
        running,
        reset: handleReset,
        setTime,
        setRunning
    }), [time, setTime, running, setRunning]);

    useEffect(() => {
        if (running) {
            const handler = () => {
                setTime((value) => value + 1);
            };
            const interval = setInterval(handler, 1000);
            return () => clearInterval(interval);
        }
    }, [running]);

    return (
        <Card className={props.className}>
            <CardContent className="!tw-p-0.5 tw-flex tw-items-center tw-gap-1">
                {props.slots?.start}
                {props.slots?.title}
                <div className="tw-px-2">
                    {duration.toFormat('hh:mm:ss')}
                </div>
                <Button
                    variant="ghost" size="icon"
                    className="tw-text-primary !tw-p-1"
                    onClick={() => setRunning(value => !value)}
                >
                    {running ? <PauseIcon/> : <PlayIcon/>}
                </Button>
                <Button
                    variant="ghost" size="icon"
                    className="tw-text-destructive !tw-p-1"
                    onClick={handleReset}
                >
                    <StopIcon/>
                </Button>
            </CardContent>
        </Card>
    );
}
