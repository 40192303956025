import * as React from 'react';
import { type DialogProps } from '@radix-ui/react-dialog';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { Command as CommandPrimitive } from 'cmdk';

import { cn } from '@/lib/utils';
import { Dialog, DialogContent } from '@/components/ui/dialog';

const Command = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({
    className,
    ...props
}, ref) => (
    <CommandPrimitive
        ref={ref}
        className={cn(
            'tw-flex tw-h-full tw-w-full tw-flex-col tw-overflow-hidden',
            'tw-rounded-md tw-bg-popover tw-text-popover-foreground',
            className
        )}
        {...props}
    />
));
Command.displayName = CommandPrimitive.displayName;

interface CommandDialogProps extends DialogProps {
}

const CommandDialog = ({
    children,
    ...props
}: CommandDialogProps) => {
    return (
        <Dialog {...props}>
            <DialogContent className="tw-overflow-hidden tw-p-0">
                <Command
                    className={cn(
                        '[&_[cmdk-group-heading]]:tw-px-2 [&_[cmdk-group-heading]]:tw-font-medium',
                        '[&_[cmdk-group-heading]]:tw-text-muted-foreground',
                        '[&_[cmdk-group]:not([hidden])_~[cmdk-group]]:tw-pt-0',
                        '[&_[cmdk-group]]:tw-px-2 [&_[cmdk-input-wrapper]_svg]:tw-h-5',
                        '[&_[cmdk-input-wrapper]_svg]:tw-w-5 [&_[cmdk-input]]:tw-h-12',
                        '[&_[cmdk-item]]:tw-px-2 [&_[cmdk-item]]:tw-py-3 [&_[cmdk-item]_svg]:tw-h-5',
                        '[&_[cmdk-item]_svg]:tw-w-5'
                    )}
                >
                    {children}
                </Command>
            </DialogContent>
        </Dialog>
    );
};

const CommandInput = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Input>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({
    className,
    ...props
}, ref) => (
    // eslint-disable-next-line react/no-unknown-property
    <div className="tw-flex tw-items-center tw-border-b tw-px-3" cmdk-input-wrapper="">
        <MagnifyingGlassIcon className="tw-mr-2 tw-h-4 tw-w-4 tw-shrink-0 tw-opacity-50"/>
        <CommandPrimitive.Input
            ref={ref}
            className={cn(
                'tw-flex tw-h-10 tw-w-full tw-rounded-md tw-bg-transparent tw-py-3 tw-text-sm',
                'tw-outline-none placeholder:tw-text-muted-foreground',
                'disabled:tw-cursor-not-allowed disabled:tw-opacity-50',
                className
            )}
            {...props}
        />
    </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({
    className,
    ...props
}, ref) => (
    <CommandPrimitive.List
        ref={ref}
        className={cn('tw-max-h-[300px] tw-overflow-y-auto tw-overflow-x-hidden', className)}
        {...props}
    />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Empty>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
    <CommandPrimitive.Empty
        ref={ref}
        className="tw-py-6 tw-text-center tw-text-sm"
        {...props}
    />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Group>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({
    className,
    ...props
}, ref) => (
    <CommandPrimitive.Group
        ref={ref}
        className={cn(
            'tw-overflow-hidden tw-p-1 tw-text-foreground [&_[cmdk-group-heading]]:tw-px-2',
            '[&_[cmdk-group-heading]]:tw-py-1.5 [&_[cmdk-group-heading]]:tw-text-xs',
            '[&_[cmdk-group-heading]]:tw-font-medium [&_[cmdk-group-heading]]:tw-text-muted-foreground',
            className
        )}
        {...props}
    />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({
    className,
    ...props
}, ref) => (
    <CommandPrimitive.Separator
        ref={ref}
        className={cn('tw--mx-1 tw-h-px tw-bg-border', className)}
        {...props}
    />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({
    className,
    ...props
}, ref) => (
    <CommandPrimitive.Item
        ref={ref}
        className={cn(
            'tw-relative tw-flex tw-cursor-default tw-select-none tw-items-center tw-rounded-sm tw-px-2',
            'tw-py-1.5 tw-text-sm tw-outline-none aria-selected:tw-bg-accent aria-selected:tw-text-accent-foreground',
            'aria-disabled:tw-pointer-events-none aria-disabled:tw-opacity-50',
            className
        )}
        {...props}
    />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <span
            className={cn(
                'tw-ml-auto tw-text-xs tw-tracking-widest tw-text-muted-foreground',
                className
            )}
            {...props}
        />
    );
};
CommandShortcut.displayName = 'CommandShortcut';

export {
    Command,
    CommandDialog,
    CommandInput,
    CommandList,
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandShortcut,
    CommandSeparator
};
