import { getNotesList, getOrgUsers } from '@/composables/api';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { type useTranslation } from '@/composables/translation';

interface Options {
    folder_id: string;
    case_id: string;
    to: ReturnType<typeof useTranslation<'common'>>['to'];
}

export async function downloadTimelogReport(opts: Options) {
    const users = await getOrgUsers();
    const notes = await getNotesList({
        id: opts.case_id,
        note_type: 'time_log',
        order: 1,
        limit: 10000
    });
    const config = mkConfig({ useKeysAsHeaders: true });
    const output = generateCsv(config)(notes.data.notes.map(n => {
        const user = users.data.find(u => u.id.$oid === n.metadata.user);
        return {
            folder_id: opts.folder_id,
            case_id: opts.case_id,
            activity: opts.to(n.metadata.activity.title),
            user: user ? `${user.firstname} ${user.lastname}` : null,
            date: n.metadata.date,
            duration: n.metadata.duration,
            billing_type: n.metadata.billing.type,
            billing_level: n.metadata.billing.level,
            billing_amount: n.metadata.billing.amount
        };
    }));
    download(config)(output);
}
