import React, { useEffect, useMemo } from 'react';
import { type FactoryOpts } from 'imask';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { useNeoForm } from '@/composables/neoform';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { useIMask } from 'react-imask';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { getFieldError, useValidation } from '@/composables/validation';
import { LockClosedIcon } from '@radix-ui/react-icons';

interface Props {
    placeholder?: TranslationObject;
    textarea?: boolean;
    mask?: FactoryOpts['mask'];
    uppercase?: boolean;
    definitions?: Record<string, RegExp | string>;
    validateMask?: boolean;
}

function validationRegexFromPatternMask(
    mask?: FactoryOpts['mask'],
    definitions?: Record<string, RegExp | string>
): RegExp | undefined {
    if (mask instanceof RegExp) {
        return mask;
    }
    if (typeof mask === 'string') {
        let result = mask.replaceAll(/[.*+?^${}()|[\]\\]/g, '\\$&')
            .replaceAll(/(?<!\\)\[(.*?)(?<!\\)]/g, '(:?$1)?')
            .replaceAll(/(?<!\\)\{(.*?)(?<!\\)}/g, '$1')
            .replaceAll(/(?<!\\)a/g, '');
        Object.entries(definitions ?? {})
            .forEach(([k, v]) => {
                const value = typeof v === 'string' ? v : v.toString().slice(1, -1);
                result = result.replaceAll(k, value);
            });
        result = result.replaceAll(/(?<!\\)0/g, '[0-9]')
            .replaceAll(/(?<!\\)a/g, '[a-zA-Z]')
            .replaceAll(/(?<!\\)\*/g, '.');
        return new RegExp(result);
    }
    return undefined;
}

export function InputText(props: NeoFormComponentProps & Props) {
    const { to } = useTranslation('validation');
    const {
        id,
        form,
        component,
        shouldEncrypt,
        getValidationRules
    } = useNeoForm();
    const { matchesOrEmpty } = useValidation();
    useEffect(() => {
        const value = form?.get(id);
        if (!value) {
            component?.setEncrypted?.(false);
        }
    }, []);
    const validate = getValidationRules(props);
    const validateMask = props.validateMask ?? true;
    const isEncrypted = shouldEncrypt && !!component?.encrypted;
    const maskValue = useMemo(
        () => typeof props.mask === 'string' && props.mask.startsWith('/')
            ? new RegExp(props.mask.substring(1, props.mask.length - 1))
            : props.mask,
        [props.mask]
    );
    const regex = useMemo(
        () => validationRegexFromPatternMask(maskValue, props.definitions),
        [maskValue, props.definitions]
    );
    if (maskValue && validateMask && regex) {
        validate.validateMask = matchesOrEmpty(regex);
    }
    const definitions: Record<string, RegExp> = Object.entries(props.definitions ?? {})
        .reduce((obj, [k, v]) => ({
            ...obj,
            [k]: typeof v === 'string' ? new RegExp(v) : v
        }), {});
    return (
        <FormField
            name={id}
            defaultValue={to(props.default)}
            render={({ field, formState }) => {
                const error = !!getFieldError(id, formState);
                const mask = props.mask
                    ? useIMask(
                        {
                            mask: maskValue,
                            definitions,
                            prepareChar: props.uppercase
                                ? (str: string) => str.toUpperCase()
                                : undefined
                        } as any,
                        {
                            onAccept: value => {
                                if (isEncrypted) {
                                    return;
                                }
                                field.onChange(value);
                                form?.set(id, value);
                            }
                        }
                    )
                    : undefined;
                if (props.mask && field.value !== mask?.value && !!field.value) {
                    mask?.setValue(field.value);
                }
                return (
                    <FormItem>
                        <FormControl>
                            {React.createElement(
                                (props.textarea ? Textarea : Input) as typeof Input,
                                {
                                    id,
                                    error,
                                    disabled: isEncrypted || props.readonly,
                                    placeholder: to(props.placeholder) || undefined,
                                    ...(!props.textarea && shouldEncrypt && {
                                        prependIcon: <LockClosedIcon />
                                    }),
                                    ...field,
                                    ref: (ref) => {
                                        if (mask) {
                                            mask.ref.current = ref;
                                        }
                                        field.ref(ref);
                                        form?.ref(id, ref);
                                    },
                                    value: isEncrypted ? '******' : (field.value ?? ''),
                                    onChange: (e) => {
                                        if (isEncrypted) {
                                            return;
                                        }
                                        const input = e.target;
                                        if (mask) {
                                            mask.setValue(input.value);
                                        } else {
                                            if (props.uppercase) {
                                                const start = input.selectionStart;
                                                const end = input.selectionEnd;
                                                input.value = input.value.toUpperCase();
                                                input.setSelectionRange(start, end);
                                            }
                                            field.onChange(input.value);
                                            form?.set(id, input.value);
                                        }
                                    }
                                }
                            )}
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                );
            }}
            rules={{ ...(!isEncrypted && { validate }) }}
        />
    );
}
