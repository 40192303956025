import React, { useRef, useState, useMemo } from 'react';
import { type FieldErrors, type SubmitHandler, useForm } from 'react-hook-form';
import _ from 'lodash';
import {
    type Context,
    type FormFunction,
    type FormInfo, type FormSaveConfig,
    NeoFormContext
} from '@/components/neoform/context/NeoFormContext';
import { postFormData } from '@/composables/api';
import { useMergeState } from '@/composables/merge';
import {
    type NeoFormLayout,
    type NeoFormMeta,
    type NeoFormSectionDef
} from '@/types/neoform';
import { clone } from '@/composables/utils';
import { invokeFunction } from '@/composables/neoform';
import { listErrors } from '@/composables/validation';
import { Form } from '@/components/ui/form';
import { toast } from 'react-toastify';
import { useTranslation } from '@/composables/translation';

export interface NeoFormRenderProps {
    sections: NeoFormSectionDef[];
}

export interface NeoFormProps {
    formRef?: React.MutableRefObject<Context | undefined>;
    render: React.FC<NeoFormRenderProps>;
    isPublicDefault?: boolean;
    onSubmit?: (data: any) => Promise<void> | void;
}

export function NeoForm(props: NeoFormProps) {
    const hookForm = useForm({ reValidateMode: 'onBlur' });
    const formElRef = useRef<HTMLFormElement | null>(null);
    const [loading, setLoading] = useState(true);
    const [layout, setLayout] = useState<NeoFormLayout>();
    const [dynamicComponents] = useState<Set<string>>(new Set<string>());
    const [dynamicComponentsMap] = useState<Record<string, string>>({});
    const [meta] = useState<NeoFormMeta>({});
    const [functions] = useState<Record<string, FormFunction>>({});
    const [refs] = useState<Record<string, any>>({});
    const encrypt = useMemo(() => (layout?.header?.config?.encrypt ?? []).map(
        (s: string) => new RegExp(`^${
            s.split('.')
                .map(p => p === '*' ? '[^.]*' : p)
                .join('\\.')
        }$`)
    ), [layout]);
    const [info, setInfo, , forceUpdate] = useMergeState<FormInfo>({
        is_public: props.isPublicDefault ?? false,
        ticket_id: '',
        task_id: '',
        form_id: '',
        target: '',
        name: '',
        actions: [],
        preview_url: '',
        status: {}
    });
    const { ct } = useTranslation();
    const contextValue = {
        loading,
        meta,
        layout,
        info,
        dynamicComponents,
        functions,
        encrypt,
        setLoading,
        setLayout,
        setMeta: setFieldMeta,
        setInfo,
        ref,
        get,
        set: setWithChange,
        watch,
        saveForm,
        submitForm,
        handleSubmit,
        forceUpdate
    };
    if (props.formRef) {
        props.formRef.current = { ...contextValue };
    }

    function setFieldMeta(key: string, value: any) {
        _.set(meta, key, value);
    }

    function ref<T>(key: string, value: T) {
        _.set(refs, key, value);
    }

    function set<T>(key: string, value: T) {
        hookForm.setValue(key, value);
    }

    function getDynamicComponent(key: string) {
        if (dynamicComponentsMap[key]) {
            return dynamicComponentsMap[key];
        }
        const result = [...dynamicComponents.keys()]
            .sort((a, b) => b.length - a.length)
            .find(k => key.startsWith(k));
        if (result) {
            dynamicComponentsMap[key] = result;
        }
        return result;
    }

    function handleOnChange(key: string) {
        const fn = functions[`${key}_onchange`]?.fn;
        const value = hookForm.getValues(key);
        const dynamicComponent = getDynamicComponent(key);
        const setLocal = dynamicComponent
            ? (key: string, value: any) => set(`${dynamicComponent}.${key}`, value)
            : set;
        // *NOTE: onChange handlers cannot trigger other onChange handlers,
        // hence passing `set` as opposed to `setWithChange`
        invokeFunction(fn, [
            value,
            clone(
                dynamicComponent
                    ? hookForm.getValues(dynamicComponent)
                    : hookForm.getValues()
            ),
            set,
            setLocal
        ]);
    }

    function setWithChange<T>(key: string, value: T) {
        set(key, value);
        handleOnChange(key);
    }

    function get<T>(keys: string): T {
        return hookForm.getValues(keys);
    }

    function watch<T>(keys: string): T {
        return hookForm.watch(keys);
    }

    function saveForm(config?: FormSaveConfig) {
        return postFormData(
            info.is_public, info.task_id,
            { form: info.name, ...hookForm.getValues() },
            meta, config
        );
    }

    function submitForm() {
        formElRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }

    function handleFormErrors(errors: FieldErrors) {
        const errorList = listErrors(errors);
        const error = errorList?.[0];
        const id = error?.id;
        const ref = _.get(refs, id);
        ref?.focus?.call(ref);
        ref?.select?.call(ref);
        setTimeout(() => {
            ref?.scrollIntoView?.call(ref, { behavior: 'smooth', block: 'center' });
        }, 500);
        toast.error(
            ct('messages.missing-fields'),
            {
                className: 'large-toast',
                bodyClassName: 'large-toast',
                autoClose: 7000
            }
        );
    }

    function handleSaveForm() {
        setLoading(true);
        return Promise.resolve(props.onSubmit?.(hookForm.getValues()))
            .then(() => {
                // This timeout is needed because calling hookForm.reset()
                // in the submit handler has no effect otherwise
                setTimeout(() => {
                    hookForm.reset({}, { keepValues: true });
                }, 0);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleSubmit(onValid: SubmitHandler<any>, bypassValidation = false) {
        return hookForm.handleSubmit(
            onValid,
            bypassValidation
                ? () => {
                    const data = hookForm.getValues();
                    hookForm.clearErrors();
                    return onValid(data);
                }
                : handleFormErrors
        );
    }

    return (
        <Form {...hookForm}>
            <form
                ref={formElRef}
                className="tw-flex-1"
                autoComplete="off"
                onSubmit={hookForm.handleSubmit(handleSaveForm, handleFormErrors)}
            >
                <NeoFormContext.Provider value={contextValue}>
                    <props.render sections={layout?.body ?? []} />
                </NeoFormContext.Provider>
            </form>
        </Form>
    );
}
