import React from 'react';
import { useNeoForm } from '@/composables/neoform';
import { FormField } from '@/components/ui/form';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import qs from 'query-string';

interface Props {
    height?: number;
}

export function PDFViewer(props: Props & NeoFormComponentProps) {
    const { id } = useNeoForm();
    return (
        <FormField
            name={id}
            defaultValue={props.default}
            render={({ field }) => (
                field.value
                    ? <iframe
                        src={`${field.value}#${qs.stringify({ toolbar: 0 })}`}
                        width="100%"
                        height={props.height ?? 500}
                    />
                    : <></>
            )}
        />
    );
}
