import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getNotesList, getOrgUsers, postUpdateNoteMetadata } from '@/composables/api';
import { type Note } from '@/types/api/note';
import { type CrudApi, CrudDialog, CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { type User } from '@/types/api/user';
import { DateCell, DateCellShort, TranslateCell } from '@/components/ui/cells';
import { useParams } from 'react-router-dom';
import { DateTime, Duration, Interval } from 'luxon';
import { BILLING_LEVEL_OPTIONS, BILLING_TYPE_OPTIONS } from '@/components/psj/folder/TimeLog';
import { combine, useTranslation } from '@/composables/translation';
import { MonthPicker } from '@/components/ui/month-picker';
import { newStateFromAction } from '@/composables/utils';
import { Button } from '@/components/ui/button';
import { CalendarIcon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useError } from '@/composables/error';

export function TimeLogEntries() {
    const { t, to, ct } = useTranslation('psj.time-log');
    const { case_id } = useParams<{ case_id: string }>();
    const { handleNetworkError } = useError();
    const crudApi = useRef<CrudApi>();
    const [notes, setNotes] = useState<Note[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [selected, setSelected] = useState<Note>();
    const [open, setOpen] = useState(false);
    const schema = useMemo<CrudSchema<Note>>(
        () => [
            {
                id: 'metadata.activity.title',
                type: CrudInputType.TEXT,
                name: t('schema.activity'),
                translate: true,
                columnDef: {
                    id: 'metadata.activity.title',
                    accessorKey: 'metadata.activity.title',
                    header: t('schema.activity'),
                    footer: ct('totals'),
                    cell: TranslateCell({ to })
                }
            },
            {
                id: 'metadata.date',
                type: CrudInputType.DATE,
                name: t('schema.date'),
                columnDef: {
                    id: 'metadata.date',
                    accessorKey: 'metadata.date',
                    header: t('schema.date'),
                    cell: DateCell,
                    filterFn: (row, id, filterValue: Date) => {
                        const filter = DateTime.fromJSDate(filterValue);
                        if (!filterValue || !filter.isValid) {
                            return true;
                        }
                        const value = row.getValue<string>(id);
                        const date = DateTime.fromISO(value);
                        if (!date.isValid) {
                            return false;
                        }
                        const interval = Interval.fromDateTimes(filter, filter.plus({ months: 1 }));
                        return interval.contains(date);
                    },
                    filter: {
                        id: 'metadata.date-filter',
                        type: CrudInputType.CUSTOM,
                        render: ({ field }) => <MonthPicker
                            allowNull
                            month={(field.value as Date)?.getMonth()}
                            onChangeMonth={(val) => {
                                const value = newStateFromAction(
                                    (field.value as Date)?.getMonth() ?? null,
                                    val
                                );
                                const date = field.value ?? DateTime.now().startOf('month').toJSDate();
                                date.setMonth(value);
                                field.onChange(date);
                            }}
                            year={(field.value as Date)?.getFullYear()}
                            onChangeYear={(val) => {
                                const value = newStateFromAction(
                                    (field.value as Date)?.getFullYear() ?? null,
                                    val
                                );
                                const date = field.value ?? DateTime.now().startOf('month').toJSDate();
                                date.setFullYear(value);
                                field.onChange(date);
                            }}
                        />
                    }
                }
            },
            {
                id: 'metadata.user',
                type: CrudInputType.SELECT,
                name: t('schema.user'),
                options: users,
                getOptionValue: (opt) => opt.id.$oid,
                getOptionLabel: (opt) => `${opt.firstname} ${opt.lastname}`,
                columnDef: {
                    id: 'metadata.user',
                    accessorKey: 'metadata.user',
                    header: t('schema.user'),
                    cell: ({ cell }) => {
                        const user = users.find(u => u.id.$oid === cell.getValue<string>());
                        if (!user) {
                            return '';
                        }
                        return `${user.firstname} ${user.lastname}`;
                    }
                }
            },
            {
                id: 'metadata.billing.type',
                type: CrudInputType.SELECT,
                name: t('schema.billing-type'),
                options: BILLING_TYPE_OPTIONS,
                getOptionValue: (opt) => opt.value,
                getOptionLabel: (opt) => to(opt.label),
                columnDef: {
                    id: 'metadata.billing.type',
                    accessorKey: 'metadata.billing.type',
                    header: t('schema.billing-type'),
                    cell: ({ cell }) => to(BILLING_TYPE_OPTIONS.find(
                        t => t.value === cell.getValue<string>()
                    )?.label)
                }
            },
            {
                id: 'metadata.billing.level',
                type: CrudInputType.SELECT,
                name: t('schema.billing-level'),
                options: BILLING_LEVEL_OPTIONS,
                getOptionValue: (opt) => opt.value,
                getOptionLabel: (opt) => to(opt.label),
                columnDef: {
                    id: 'metadata.billing.level',
                    accessorKey: 'metadata.billing.level',
                    header: t('schema.billing-level'),
                    cell: ({ cell }) => to(BILLING_LEVEL_OPTIONS.find(
                        l => l.value === cell.getValue<string>()
                    )?.label)
                }
            },
            {
                id: 'metadata.duration',
                type: CrudInputType.NUMBER,
                name: t('schema.duration'),
                columnDef: {
                    id: 'metadata.duration',
                    accessorKey: 'metadata.duration',
                    header: t('schema.duration'),
                    footer: ({ table, column }) => {
                        const minutes = table.getRowModel().rows.reduce(
                            (sum, row) => sum + row.getValue<number>(column.id),
                            0
                        );
                        return Duration.fromDurationLike({ minutes })
                            .normalize()
                            .rescale()
                            .toHuman({ unitDisplay: 'short' });
                    },
                    cell: ({ cell }) => {
                        const minutes = cell.getValue<number>();
                        return Duration.fromDurationLike({ minutes })
                            .normalize()
                            .rescale()
                            .toHuman({ unitDisplay: 'short' });
                    }
                }
            },
            {
                id: 'hourly_amount',
                type: CrudInputType.NUMBER,
                columnDef: {
                    id: 'hourly_amount',
                    accessorFn: (row) => row.metadata?.billing?.type === 'hourly'
                        ? row.metadata?.billing?.amount
                        : 0,
                    header: 'Taux horaire ($/hr)'
                }
            },
            {
                id: 'total_amount',
                type: CrudInputType.NUMBER,
                columnDef: {
                    id: 'total_amount',
                    accessorFn: (row) => row.metadata?.billing?.type === 'hourly'
                        ? row.metadata.billing.amount * (row.metadata.duration / 60)
                        : 0,
                    header: 'Montant total ($)',
                    footer: ({ table, column }) => {
                        return table.getRowModel().rows.reduce(
                            (sum, row) => sum + row.getValue<number>(column.id),
                            0
                        );
                    }
                }
            },
            {
                id: 'metadata.billing.date',
                type: CrudInputType.DATE,
                name: t('schema.billing-date'),
                columnDef: {
                    id: 'metadata.billing.date',
                    accessorKey: 'metadata.billing.date',
                    header: t('schema.billing-date'),
                    cell: DateCellShort
                }
            }
        ],
        [users]
    );
    const TimeLogEntryDialog = useMemo(() => CrudDialog<Note, '_id'>({
        idKey: '_id',
        schema: [
            {
                id: 'metadata.billing.date',
                type: CrudInputType.DATE,
                name: ct('date'),
                defaultValue: DateTime.now().startOf('day')
            }
        ]
    }), []);
    useEffect(() => {
        getOrgUsers()
            .then((res) => setUsers(res.data));
    }, []);
    return (
        <main className="tw-min-h-full tw-p-4">
            <Card>
                <CardHeader>
                    <CardTitle>{t('title')}</CardTitle>
                </CardHeader>
                <CardContent>
                    <CrudTable<Note, '_id'>
                        idKey="_id"
                        schema={schema}
                        apiRef={crudApi}
                        list={notes}
                        onChangeList={setNotes}
                        actions={({ row }) => <>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button variant="ghost" size="icon" onClick={() => {
                                        setOpen(true);
                                        setSelected(row.original);
                                    }}>
                                        <CalendarIcon />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {t('actions.billing-date')}
                                </TooltipContent>
                            </Tooltip>
                        </>}
                        onRead={() =>
                            getNotesList({
                                id: case_id,
                                note_type: 'time_log'
                            }).then((res) => res.data.notes)
                        }
                    />
                    <TimeLogEntryDialog
                        translation={{
                            title: combine('psj.time-log.schema.billing-date'),
                            submit: combine('common.save')
                        }}
                        value={selected}
                        open={open}
                        onOpenChange={setOpen}
                        onSubmit={(values) => {
                            if (!selected) {
                                return Promise.reject();
                            }
                            const metadata = _.merge(selected.metadata, values.metadata);
                            return postUpdateNoteMetadata({ note_id: values._id, metadata })
                                .then(() => {
                                    setOpen(false);
                                    toast.success(ct('messages.success'));
                                    crudApi.current?.refreshList();
                                })
                                .catch(handleNetworkError);
                        }}
                    />
                </CardContent>
            </Card>
        </main>
    );
}
