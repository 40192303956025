import React from 'react';
import { Label, type LabelProps } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import { RequiredMarker } from '@/components/ui/required-marker';
import { OptionalMarker } from '@/components/ui/optional-marker';

interface Props extends LabelProps {
    required?: boolean;
    optional?: boolean;
}

export function SectionLabel({ required, optional, children, ...props }: Props) {
    return (
        <Label
            {...props}
            className={cn(
                'tw-font-medium tw-text-primary !tw-cursor-auto',
                props.className
            )}
        >
            {children}
            {required && <RequiredMarker/>}
            {optional && <OptionalMarker/>}
        </Label>
    );
}
