import { type Language } from '@/composables/translation';
import { type DateTime } from 'luxon';

export interface Org {
    _id: string;
    title: string;
    name: string;
    active: boolean;
    description: string;
    folder: string;
    env: OrgEnv;
    lang: Language;
    contract: OrgContract;
    is_elawyer: boolean;
}

export interface OrgContract {
    start_date: string | DateTime;
    end_date?: string | DateTime;
    duration_months: number;
    free_months?: number;
    billing: {
        start_date: string | DateTime;
        invoice_to: InvoiceTo;
        features_invoice: InvoiceFeature[];
    };
}

export interface InvoiceTo {
    name: string;
    email: string;
    phone: string;
    address: {
        city: string;
        state: string;
        postal_code: string;
        country: string;
        line1: string;
    };
}

export interface InvoiceFeature {
    name: string;
    description?: string;
    start_date?: string | DateTime;
    rules: InvoiceRule[];
}

export interface InvoiceRule {
    name: string;
    definition: any;
    stages: Stage[];
}

export interface Stage {
    id: string;
    start: number;
    unit_price: number;
}

export interface PostContactConfigDto {
    client_id: string;
    client_secret: string;
    grant_type: string;
    password: string;
    scope: string;
    tenant_id: string;
    username: string;
}

export interface UserInfos {
    firstname?: string;
    lastname?: string;
    email?: string;
    external_id?: string | null;
    role?: string;
}

interface OrgEnv {
    notification_email?: string;
    features: string[];
}

export interface PostCreateOrgDto {
    notify?: string | null;
    user_infos: UserInfos | null;
    is_externe: boolean;
    org: Omit<Org, '_id'>;
}

export interface PostUpdateOrgDto extends Org {}

export interface GetOrganizationsOptions {}

export const roleOptions = [
    'lawyer',
    'sales',
    'elawyer',
    'agent',
    'legalintern',
    'notary',
    'group'
] as const;

export type Role = typeof roleOptions[number];
