import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { getFormUrl, useNeoForm } from '@/composables/neoform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFloppyDisk, faFolderOpen, faLock, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from '@/composables/translation';
import { generateDocument, getFile, postFormStatus, postGenerateWord } from '@/composables/api';
import { toast } from 'react-toastify';
import { LanguageSelect } from '@/components/neoform/helper/LanguageSelect';
import { delay, downloadBlob, openFileURL } from '@/composables/utils';
import { useDocumentStore } from '@/store/document';
import { ActionBar } from '@/components/ActionBar';
import { Separator } from '@/components/ui/separator';
import { type NeoFormPDFOption, NeoFormStatus } from '@/types/neoform';
import { DocumentStatus } from '@/types/document';
import { Feature, useFeatures } from '@/composables/features';
import { Link, useNavigate } from 'react-router-dom';
import { ConfirmDialogButton } from '@/components/ConfirmDialogButton';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { ButtonSubmit } from '@/components/ui/button-submit';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { CaretSortIcon, CheckIcon, DownloadIcon, EyeOpenIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import { useError } from '@/composables/error';
import { faFilePdf, faFileWord } from '@fortawesome/free-regular-svg-icons';
import { CrudDialog, CrudInputType, type CrudSchema, type UpdateDialogProps } from '@/components/ui/crud-table';
import { InputCopy } from '@/components/ui/input-copy';
import { sanitizeHtml } from '@/composables/html';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface Props {
    className?: string;
}

interface SaveDialogValues {
    task_id: string;
    ticket_id: string;
    form_id: string;
    form_name: string;
    form_url: string;
}

export function NeoFormActionBar(props: Props) {
    const {
        t,
        to,
        ct
    } = useTranslation('neoform');
    const { form } = useNeoForm();
    const { isFeatureEnabled } = useFeatures();
    const documentStore = useDocumentStore();
    const navigate = useNavigate();
    const { handleNetworkError } = useError();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [loading, setLoading] = useState(false);
    const [docType, setDocType] = useState('');
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [saveDialogOpen, setSaveDialogOpen] = useState<Record<string, boolean>>({});
    const [saveDialogValues, setSaveDialogValues] = useState<SaveDialogValues>();

    useEffect(() => {
        if (form && loading && docType) {
            const meta = documentStore.getDocumentMeta(form.info?.task_id ?? '', docType);
            if (meta?.status === DocumentStatus.COMPLETED) {
                form.setInfo({
                    preview_open: true,
                    preview_url: meta.url
                });
                form.forceUpdate();
                setLoading(false);
                setDocType('');
            } else if (meta?.status === DocumentStatus.FAILED) {
                setLoading(false);
                setDocType('');
                toast(t('messages.error'), { type: 'error' });
            }
        }
    }, [documentStore.documents]);

    function handleDocumentActionClick(option: NeoFormPDFOption) {
        form?.handleSubmit(() => handleDocumentAction(option), option.bypassFormValidation ?? false)();
    }

    async function handleDocumentAction(option: NeoFormPDFOption) {
        if (!form?.info) return;
        const docType = option.name;
        setLoading(true);
        try {
            await form.saveForm({
                document_type: docType,
                captcha_token: await executeRecaptcha?.('submit')
            });
            if (!option.hasGenDocument) {
                toast(t('messages.saved'), { type: 'success' });
                setLoading(false);
                return;
            }
            const res = await generateDocument(
                form.info.task_id,
                docType,
                form?.info?.is_public ?? false,
                option?.forceGenDocument ?? false
            );
            if (option.saveDialog) {
                setSaveDialogOpen((state) => ({
                    ...state,
                    [option.name]: true
                }));
                setLoading(false);
                setSaveDialogValues({
                    form_url: window.location.href,
                    form_name: form?.info?.name ?? '',
                    task_id: form?.info?.task_id ?? '',
                    form_id: form?.info?.form_id ?? '',
                    ticket_id: form?.info?.ticket_id ?? ''
                });
            } else if (form?.info?.is_public && option.closePublic) {
                setLoading(false);
                navigate('/thanks');
            } else if (option.download) {
                const [url] = res.data.data;
                openFileURL(url);
                setLoading(false);
            } else if (res.data?.meta?.generate_merge_document) {
                documentStore.addDocument(form.info.task_id, docType);
                setDocType(docType);
                // form.setInfo({
                //     preview_url: res.data?.data?.[0],
                //     preview_count: (form?.info?.preview_count ?? 0) + 1
                // });
            } else {
                await delay(500);
                form.setInfo({
                    preview_open: true,
                    preview_url: res.data?.data?.[0],
                    preview_count: (form?.info?.preview_count ?? 0) + 1
                });
                toast(t('messages.saved'), { type: 'success' });
                setLoading(false);
            }
        } catch (err) {
            handleNetworkError(err);
            setLoading(false);
        }
    }

    function handleChangeFormStatus(status: NeoFormStatus) {
        if (!form?.info) return;
        setLoading(true);
        postFormStatus(form.info.task_id, status)
            .then(() => {
                form?.setInfo({ status: { ...form.info?.status, lawyer: status } });
                const message = status === NeoFormStatus.CLOSED
                    ? t('messages.closed')
                    : t('messages.open');
                toast(message, { type: 'success' });
            })
            .catch(() => toast(t('messages.error'), { type: 'error' }))
            .finally(() => setLoading(false));
    }

    function handleTogglePreview() {
        form?.setInfo({ preview_open: !form?.info?.preview_open });
    }

    function handleDownloadPdf() {
        if (!form?.info?.preview_url) {
            return;
        }
        setDownloadLoading(true);
        getFile(form.info.preview_url)
            .then((res) => downloadBlob(
                res.data,
                res.headers['content-disposition']
                    ?.match(/filename="([^"]*)"/)?.[1] ?? 'preview.pdf'
            ))
            .catch(handleNetworkError)
            .finally(() => setDownloadLoading(false));
    }

    function handleDownloadWord() {
        if (!form?.info?.preview_url) {
            return;
        }
        let url = form.info.preview_url;
        if (import.meta.env.VITE_ENV === 'dev') {
            url = url.replace(
                /^http:\/\/localhost:8057/,
                'https://api-stg.neodoc.app'
            );
        }
        setDownloadLoading(true);
        postGenerateWord(url)
            .then((res) => downloadBlob(
                res.data,
                res.headers['content-disposition']
                    ?.match(/filename="([^"]*)"/)?.[1] ?? 'preview.doc'
            ))
            .catch(handleNetworkError)
            .finally(() => setDownloadLoading(false));
    }

    const title = to(form?.info?.title);
    const isFormLoading = loading || form?.loading;
    const isFormClosed = form?.info?.status?.lawyer === NeoFormStatus.CLOSED;
    const isFormPublic = !!form?.info?.is_public;
    const hasMultipleLang = (form?.layout?.header?.formLanguage?.length ?? 2) > 1;
    const showFormSave =
        !isFormPublic || (isFormPublic && !form?.layout?.header?.config?.hide_public_save);
    const formActions = useMemo(
        () => (form?.info?.actions ?? [])
            .filter(a =>
                a.show !== false &&
                (!isFormPublic || (isFormPublic && a.public)) &&
                (isFormPublic || (!isFormPublic && a.admin !== false))
            ),
        [form, isFormPublic]
    );
    const saveDialogComponents = useMemo<Record<string, React.FC<UpdateDialogProps<SaveDialogValues>>>>(
        () => formActions.filter(a => a.saveDialog).reduce((obj, action) => ({
            ...obj,
            [action.name]: CrudDialog<SaveDialogValues, 'task_id'>({
                idKey: 'task_id',
                schema: [
                    ...(action.saveDialog?.message
                        ? [{
                            id: 'description',
                            type: CrudInputType.CUSTOM,
                            render: () => <div
                                className="tw-prose tw-text-muted-foreground"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(to(action.saveDialog?.message))
                                }}
                            />
                        }]
                        : []
                    ) as CrudSchema<SaveDialogValues>,
                    {
                        id: 'form_url',
                        type: CrudInputType.CUSTOM,
                        name: ct('url'),
                        create: action.saveDialog?.fields?.includes('form_url') ?? false,
                        render: ({ field }) => <InputCopy {...field} />
                    }
                ],
                cancel: !action.saveDialog?.cancel ? false : undefined
            })
        }), {}),
        [formActions]
    );
    const showSecondaryActionBar = !isFormLoading && (
        (isFormPublic && !!form?.info?.preview_url) ||
            !isFormPublic
    );
    const {
        name: formName,
        task_id
    } = form?.info ?? {};

    return (
        <ActionBar
            loading={isFormLoading}
            className={cn('!tw-flex-col tw-items-stretch', props.className, {
                'tw-top-0': isFormPublic,
                'tw-top-[60px]': !isFormPublic
            })}
        >
            <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-2">
                {isFormPublic && hasMultipleLang &&
                    <LanguageSelect label />
                }
                <Tooltip>
                    <TooltipTrigger asChild>
                        <h4 className={cn(
                            'tw-flex-1 tw-self-start tw-mr-2 tw-mb-0 md:tw-min-w-[200px] tw-leading-[36px]',
                            'tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-font-medium tw-text-xl'
                        )}>
                            {title}
                        </h4>
                    </TooltipTrigger>
                    <TooltipContent side="bottom" align="start">
                        {title}
                    </TooltipContent>
                </Tooltip>
                <div className={cn(
                    'tw-hidden tw-ml-auto md:tw-flex tw-flex-wrap',
                    'tw-items-center tw-justify-end tw-gap-2'
                )}>
                    {!isFormPublic &&
                        <>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        type="button" size="icon"
                                        disabled={isFormLoading}
                                        className="!tw-text-lg"
                                        variant={isFormClosed ? 'success' : 'warning'}
                                        onClick={() => handleChangeFormStatus(
                                            isFormClosed
                                                ? NeoFormStatus.OPEN
                                                : NeoFormStatus.CLOSED
                                        )}
                                    >
                                        <FontAwesomeIcon
                                            icon={!isFormClosed ? faFolderOpen : faCheck}
                                        />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent side="bottom">
                                    {isFormClosed ? t('open-form') : t('close-form')}
                                </TooltipContent>
                            </Tooltip>
                            <Separator orientation="vertical" className="!tw-h-auto tw-self-stretch" />
                        </>
                    }
                    {!isFormLoading && !isFormPublic &&
                        <>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        variant="outline" size="icon"
                                        className="!tw-text-base !tw-bg-slate-100 hover:!tw-bg-slate-200"
                                        asChild
                                    >
                                        <Link to={`${getFormUrl(formName ?? '', task_id)}/send`}>
                                            <FontAwesomeIcon icon={faUsers} />
                                        </Link>
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent side="bottom">
                                    {t('client-list.tooltip')}
                                </TooltipContent>
                            </Tooltip>
                            <Separator orientation="vertical" className="!tw-h-auto tw-self-stretch" />
                        </>
                    }
                    {showFormSave &&
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <ButtonSubmit
                                    type="submit"
                                    disabled={isFormLoading}
                                >
                                    <FontAwesomeIcon
                                        className="tw-mr-2 !tw-text-lg"
                                        icon={faFloppyDisk}
                                    />
                                    {ct('submit')}
                                </ButtonSubmit>
                            </TooltipTrigger>
                            <TooltipContent side="bottom">
                                {ct('submit')}
                            </TooltipContent>
                        </Tooltip>
                    }
                    {formActions.map((a) =>
                        <Tooltip key={a.name}>
                            <TooltipTrigger asChild>
                                {a.warningAlert
                                    ? <ConfirmDialogButton
                                        onConfirm={() => handleDocumentActionClick(a)}
                                        title={to(a.warningAlert?.title)}
                                        message={to(a.warningAlert?.message)}
                                        confirmText={to(a.warningAlert?.confirmText)}
                                    >
                                        <Button
                                            type="button"
                                            variant="outline"
                                            disabled={isFormLoading}
                                            className={cn(
                                                'tw-bg-slate-100 hover:bg-slate-200',
                                                a.color && `tw-text-${a.color}`
                                            )}
                                        >
                                            <i className={cn(
                                                'tw-text-lg', a.icon, { 'tw-mr-2': !a.shorten }
                                            )} />
                                            {!a.shorten && to(a.title)}
                                        </Button>
                                    </ConfirmDialogButton>
                                    : <Button
                                        type="button"
                                        variant="outline"
                                        disabled={isFormLoading}
                                        className={cn(
                                            'tw-bg-slate-100 hover:tw-bg-slate-200',
                                            a.color && `tw-text-${a.color}`
                                        )}
                                        onClick={() => handleDocumentActionClick(a)}
                                    >
                                        <i className={cn(
                                            'tw-text-lg', a.icon, { 'tw-mr-2': !a.shorten }
                                        )} />
                                        {!a.shorten && to(a.title)}
                                    </Button>
                                }
                            </TooltipTrigger>
                            {a.shorten && <TooltipContent>
                                {to(a.title)}
                            </TooltipContent>}
                        </Tooltip>
                    )}
                </div>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            type="button"
                            className="md:!tw-hidden"
                        >
                            <TriangleDownIcon className="tw-mr-2" />
                            Actions
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="tw-mx-2">
                        {!isFormPublic && <>
                            <DropdownMenuItem
                                disabled={isFormLoading}
                                onClick={() => handleChangeFormStatus(
                                    isFormClosed
                                        ? NeoFormStatus.OPEN
                                        : NeoFormStatus.CLOSED
                                )}
                            >
                                <FontAwesomeIcon
                                    className={cn(
                                        'tw-mr-3',
                                        isFormClosed && 'tw-text-success',
                                        !isFormClosed && 'tw-text-warning'
                                    )}
                                    icon={isFormClosed ? faFolderOpen : faLock}
                                    width="1em"
                                />
                                {isFormClosed ? t('open-form') : t('close-form')}
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                        </>}
                        {!isFormPublic && <>
                            <DropdownMenuItem
                                disabled={isFormLoading}
                                asChild
                            >
                                <Link to={`${getFormUrl(formName ?? '', task_id)}/send`}>
                                    <FontAwesomeIcon
                                        icon={faUsers}
                                        className="tw-mr-3 tw-text-success"
                                        width="1em"
                                    />
                                    {t('client-list.title')}
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                        </>}
                        {showFormSave &&
                            <DropdownMenuItem
                                disabled={isFormLoading}
                                onClick={() => form?.submitForm()}
                            >
                                <FontAwesomeIcon
                                    className="tw-mr-3 tw-text-primary"
                                    icon={faFloppyDisk}
                                    width="1em"
                                />
                                {ct('save')}
                            </DropdownMenuItem>
                        }
                        {/* *NOTE: Do not remove this comment */}
                        {/* tw-text-info tw-text-success tw-text-warning tw-text-danger */}
                        {formActions.map((a) => {
                            const button = (
                                <DropdownMenuItem
                                    key={!a.warningAlert ? a.name : undefined}
                                    disabled={isFormLoading}
                                    onClick={() => handleDocumentActionClick(a)}
                                >
                                    <i className={cn(
                                        a.icon, `tw-text-${a.color}`, 'tw-w-[1em] tw-mr-3'
                                    )}/>
                                    {to(a.title)}
                                </DropdownMenuItem>
                            );
                            return (
                                a.warningAlert
                                    ? <ConfirmDialogButton
                                        key={a.name}
                                        title={to(a.warningAlert?.title)}
                                        message={to(a.warningAlert?.message)}
                                        confirmText={to(a.warningAlert?.confirmText)}
                                    >
                                        {button}
                                    </ConfirmDialogButton>
                                    : button
                            );
                        })}
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
            {showSecondaryActionBar && <>
                <Separator className="!tw-w-auto tw-mx-[-20px]" />
                <div className="tw-flex tw-items-center">
                    {!isFormPublic && <Tooltip>
                        <TooltipTrigger
                            className="tw-text-primary"
                            asChild
                        >
                            <Button
                                className="!tw-p-0 !tw-h-auto"
                                type="button" variant="link" asChild
                            >
                                <div className="tw-relative tw-top-[1px]">
                                    <FontAwesomeIcon
                                        icon={faFolderOpen}
                                        className="tw-mr-2"
                                    />
                                    {isFeatureEnabled(Feature.PSJ)
                                        ? <Link to={`/folder/${form?.info?.ticket_id}`}>
                                            {form?.info?.ticket_id}
                                        </Link>
                                        : <span>
                                            {form?.info?.ticket_id}
                                        </span>
                                    }
                                </div>
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="bottom" align="start" sideOffset={10}>
                            {t('case-id')}
                        </TooltipContent>
                    </Tooltip>}
                    {form?.info?.preview_url && <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                className="tw-ml-auto"
                                variant="outline"
                                loading={downloadLoading}
                            >
                                {t('preview.options')}
                                <CaretSortIcon className="tw-text-muted-foreground tw-ml-2"/>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="!tw-min-w-60" align="end">
                            <DropdownMenuGroup>
                                <DropdownMenuItem onClick={handleTogglePreview}>
                                    <EyeOpenIcon
                                        className="tw-mr-2 tw-w-[1em] tw-text-muted-foreground"
                                    />
                                    {t('preview.toggle')}
                                    {form?.info?.preview_open &&
                                        <CheckIcon className="tw-ml-auto"/>
                                    }
                                </DropdownMenuItem>
                                {!isFormPublic && <DropdownMenuSub>
                                    <DropdownMenuSubTrigger>
                                        <DownloadIcon
                                            className="tw-mr-2 tw-w-[1em] tw-text-muted-foreground"
                                        />
                                        {t('download.title')}
                                    </DropdownMenuSubTrigger>
                                    <DropdownMenuSubContent>
                                        <DropdownMenuItem
                                            disabled={downloadLoading}
                                            onClick={handleDownloadPdf}
                                        >
                                            <FontAwesomeIcon
                                                className="tw-text-muted-foreground tw-mr-2"
                                                width="1em"
                                                icon={faFilePdf}
                                            />
                                            {t('download.pdf.title')}
                                            <DropdownMenuShortcut>
                                                {t('download.pdf.description')}
                                            </DropdownMenuShortcut>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            disabled={downloadLoading}
                                            onClick={handleDownloadWord}
                                        >
                                            <FontAwesomeIcon
                                                className="tw-text-muted-foreground tw-mr-2"
                                                width="1em"
                                                icon={faFileWord}
                                            />
                                            {t('download.word.title')}
                                            <DropdownMenuShortcut>
                                                {t('download.word.description')}
                                            </DropdownMenuShortcut>
                                        </DropdownMenuItem>
                                    </DropdownMenuSubContent>
                                </DropdownMenuSub>}
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>}
                </div>
            </>}
            {formActions.filter(a => a.saveDialog)
                .map(a => React.createElement(
                    saveDialogComponents[a.name],
                    {
                        key: a.name,
                        open: saveDialogOpen[a.name] ?? false,
                        onOpenChange: (value) => setSaveDialogOpen((state) => ({
                            ...state,
                            [a.name]: value
                        })),
                        onSubmit: () => {
                            setSaveDialogOpen((state) => ({
                                ...state,
                                [a.name]: false
                            }));
                            return Promise.resolve();
                        },
                        translation: {
                            title: a.saveDialog?.title,
                            submit: a.saveDialog?.submit
                        },
                        value: saveDialogValues
                    }
                ))}
        </ActionBar>
    );
}
