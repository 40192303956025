import {
    CrudInputType,
    type CrudSchema,
    type CrudSelectInputOptions,
    CrudTable
} from '@/components/ui/crud-table';
import type { NextStepInstance } from '@/types/api/process';
import { getFieldError } from '@/composables/validation';
import ObjectId from 'bson-objectid';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { clone } from '@/composables/utils';
import { getNextSteps } from '@/composables/api';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import {
    CALENDAR_AVAILABILITY_LABELS,
    CALENDAR_AVAILABILITY_VALUES,
    type CalendarAvailabilityType, type CalendarConfig, type Category, type Duration
} from '@/types/api/calendar';
import { type User, type UserGroup } from '@/types/api/user';
import { useUserStore } from '@/store/user';
import { type ControllerProps } from 'react-hook-form/dist/types/controller';
import { type Macro } from '@/types/api/macro';
import { useGroups } from '@/composables/groups';
import { useAssignment } from '@/composables/assignment';
import { type CaseStatus } from '@/types/folder';

interface Props {
    context: Parameters<ControllerProps<any, any>['render']>[0];
    calendarConfig?: CalendarConfig;
    macros: Macro[];
    users: User[];
    groups: UserGroup[];
}

export function CrudNextSteps(props: Props) {
    const { t: t_status } = useTranslation('folder.status');
    const { to, t } = useTranslation('psj.next-steps.crud-dialog');
    const { lang } = useUserStore((state) => ({ lang: state.lang }));
    const { domains, defaultDomain } = useGroups({ groups: props.groups ?? [] });
    const {
        options: assignOptions,
        getOptionValue: assignOptionValue,
        getOptionLabel: assignOptionLabel,
        groupBy: assignGroupBy
    } = useAssignment({
        users: props.users ?? [],
        groups: props.groups ?? []
    });
    const defaultDomainId = defaultDomain?._id.$oid;

    const schema = useMemo<CrudSchema<NextStepInstance>>(() => [
        {
            id: 'parent',
            type: CrudInputType.SELECT,
            name: t('fields.parent-ns'),
            onChange: (form, value) => {
                form.setValue('title', value.title);
                form.setValue('domain', value.domain ?? defaultDomainId);
                form.setValue('definition', clone(value.definition));
            },
            getOptionsAsync: (title: string) => getNextSteps({ lang, title }).then((res) => res.data),
            getOptionLabel: opt => to(opt.title),
            getValueLabel: value => to(value.title)
        } as CrudSelectInputOptions<NextStepInstance, NextStepInstance, NextStepInstance>,
        {
            id: 'title',
            type: CrudInputType.TEXT,
            name: t('fields.title'),
            col: 7,
            translate: true,
            required: true,
            columnDef: {
                id: 'title',
                header: t('fields.title'),
                accessorKey: 'title',
                cell: ({ cell }) => to(cell.getValue<TranslationObject>())
            }
        },
        {
            id: 'domain',
            type: CrudInputType.SELECT,
            name: t('fields.domain'),
            col: 5,
            required: true,
            defaultValue: defaultDomainId,
            options: domains,
            getOptionValue: (opt) => opt._id.$oid,
            getOptionLabel: opt => to(opt.name),
            columnDef: {
                id: 'domain',
                header: t('fields.domain'),
                accessorKey: 'domain',
                cell: ({ cell }) => to(domains?.find(d => d._id.$oid === cell.getValue<string>())?.name)
            }
        },
        {
            id: 'definition.folder',
            type: CrudInputType.HEADER,
            name: t('fields.folder.title')
        },
        {
            id: 'definition.folder.status',
            type: CrudInputType.SELECT,
            name: t('fields.folder.status'),
            col: 6,
            options: ['open', 'closed', 'pending'],
            getOptionLabel: (opt) => opt === 'created' ? t_status('open') : t_status(opt),
            clearable: true
        } as CrudSelectInputOptions<NextStepInstance, CaseStatus>,
        {
            id: 'definition.folder.assign',
            type: CrudInputType.SELECT,
            name: t('fields.folder.assign'),
            col: 6,
            options: assignOptions,
            getOptionValue: assignOptionValue,
            getOptionLabel: assignOptionLabel,
            groupBy: assignGroupBy,
            clearable: true
        } as CrudSelectInputOptions<NextStepInstance, typeof assignOptions[number], string>,
        {
            id: 'definition.folder.macro',
            type: CrudInputType.SELECT,
            name: t('fields.folder.macro'),
            col: 6,
            options: props.macros,
            getOptionValue: opt => opt._id,
            getOptionLabel: opt => to(opt.title),
            clearable: true
        } as CrudSelectInputOptions<NextStepInstance, Macro, string>,
        {
            id: 'definition.folder.open_macro',
            type: CrudInputType.SELECT,
            name: t('fields.folder.open-macro'),
            col: 6,
            options: props.macros,
            getOptionValue: opt => opt._id,
            getOptionLabel: opt => to(opt.title),
            clearable: true
        } as CrudSelectInputOptions<NextStepInstance, Macro, string>,
        {
            id: 'definition.folder.required_fields',
            type: CrudInputType.SELECT,
            name: t('fields.folder.required-fields'),
            options: [],
            multiple: true
        },
        {
            id: 'definition.folder.bifurcation',
            type: CrudInputType.CHECKBOX,
            name: t('fields.folder.bifurcation')
        },
        {
            id: 'definition.calendar',
            type: CrudInputType.HEADER,
            name: t('fields.calendar.title')
        },
        {
            id: 'definition.calendar.type',
            type: CrudInputType.SELECT,
            col: 6,
            name: t('fields.calendar.type'),
            clearable: true,
            options: CALENDAR_AVAILABILITY_VALUES,
            getOptionValue: (opt) => opt,
            getOptionLabel: (opt) => to(CALENDAR_AVAILABILITY_LABELS[opt]),
            getValueLabel: (opt) => to(CALENDAR_AVAILABILITY_LABELS[opt as CalendarAvailabilityType])
        } as CrudSelectInputOptions<NextStepInstance, CalendarAvailabilityType, number>,
        {
            id: 'definition.calendar.category',
            type: CrudInputType.SELECT,
            col: 6,
            name: t('fields.calendar.category'),
            options: props.calendarConfig?.categories ?? [],
            getOptionValue: (opt) => opt.id,
            getOptionLabel: (opt) => to(opt.name)
        } as CrudSelectInputOptions<NextStepInstance, Category, number>,
        {
            id: 'definition.calendar.duration',
            type: CrudInputType.SELECT,
            name: t('fields.calendar.duration'),
            col: 4,
            options: props.calendarConfig?.durations ?? [],
            getOptionValue: (opt) => opt.value,
            getOptionLabel: opt => to(opt.label)
        } as CrudSelectInputOptions<NextStepInstance, Duration, number>,
        {
            id: 'definition.calendar.max_book.per_day',
            type: CrudInputType.NUMBER,
            name: t('fields.calendar.max-book.day'),
            col: 4
        },
        {
            id: 'definition.calendar.max_book.per_week',
            type: CrudInputType.NUMBER,
            name: t('fields.calendar.max-book.week'),
            col: 4
        }
    ], [defaultDomainId, props.calendarConfig, lang]);

    const { field, formState } = props.context;

    return (
        <CrudTable<NextStepInstance, '_id'>
            idKey="_id"
            schema={schema}
            enablePagination={false}
            enableRowDragging
            enableRowNumbering
            enableSorting={false}
            enableHiding={false}
            list={field.value}
            error={!!getFieldError(field.name, formState)}
            onChangeList={field.onChange}
            onCreate={(value) => {
                const ns: NextStepInstance = {
                    _id: new ObjectId().toHexString(),
                    ...value,
                    definition: value.definition ?? {}
                };
                field.onChange?.([
                    ...(field.value ?? []),
                    ns
                ]);
                return Promise.resolve();
            }}
            onUpdate={(value) => {
                const obj = (field.value ?? [])
                    .find((v: NextStepInstance) => v._id === value._id);
                Object.assign(obj, value);
                field.onChange?.([
                    ...(field.value ?? [])
                ]);
                return Promise.resolve();
            }}
            onDelete={(values) => {
                field.onChange?.(
                    _.differenceBy(field.value ?? [], values, '_id')
                );
                return Promise.resolve();
            }}
        />
    );
}
