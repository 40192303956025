import React, { useEffect, useMemo, useState } from 'react';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import {
    getCalendarConfig,
    getMacrosList,
    getOrgGroups,
    getOrgUsers,
    getProcessList,
    postCreateNextStepMulti,
    postCreateProcess, putUpdateNextStepMulti,
    putUpdateProcess
} from '@/composables/api';
import { type NextStepInstance, type Process, type UpdateProcessDto } from '@/types/api/process';
import { CheckboxCell, DateCell } from '@/components/ui/cells';
import { UpdateProcessDialog } from '@/components/psj/process/UpdateProcessDialog';
import { BindProps } from '@/components/utils/BindProps';
import { type User, type UserGroup } from '@/types/api/user';
import _ from 'lodash';
import { toast } from 'react-toastify';
import type { WithOptional } from '@/types/utils';
import { useError } from '@/composables/error';
import { type CalendarConfig } from '@/types/api/calendar';
import type { Macro } from '@/types/api/macro';
import { transformConfig } from '@/composables/calendar';

export function Processes() {
    const { t, to } = useTranslation('settings.processes');
    const { handleNetworkError } = useError();
    const schema: CrudSchema<Process> = useMemo<CrudSchema<Process>>(() => [
        {
            id: '_id',
            type: CrudInputType.TEXT,
            name: 'ID',
            columnDef: {
                id: '_id',
                header: 'ID',
                accessorKey: '_id'
            }
        },
        {
            id: 'active',
            type: CrudInputType.CHECKBOX,
            name: 'Actif',
            columnDef: {
                id: 'active',
                header: 'Actif',
                accessorKey: 'active',
                cell: CheckboxCell
            }
        },
        {
            id: 'title',
            type: CrudInputType.TEXT,
            name: 'Nom',
            columnDef: {
                id: 'title',
                header: 'Nom',
                accessorKey: 'title',
                cell: ({ cell }) => to(cell.getValue<TranslationObject>())
            }
        },
        {
            id: 'ns_list',
            type: CrudInputType.TEXT,
            name: 'Étapes',
            columnDef: {
                id: 'ns_list',
                header: 'Étapes',
                accessorKey: 'ns_list',
                cell: ({ cell }) => cell.getValue<NextStepInstance[]>().length
            }
        },
        {
            id: 'updated_at',
            type: CrudInputType.TEXT,
            name: 'Date de modification',
            columnDef: {
                id: 'updated_at',
                header: 'Date de modification',
                accessorKey: 'updated_at',
                cell: DateCell
            }
        },
        {
            id: 'created_at',
            type: CrudInputType.TEXT,
            name: 'Date de création',
            columnDef: {
                id: 'created_at',
                header: 'Date de création',
                accessorKey: 'created_at',
                cell: DateCell
            }
        }
    ], []);
    const [users, setUsers] = useState<User[]>([]);
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const [macros, setMacros] = useState<Macro[]>([]);
    const [config, setConfig] = useState<CalendarConfig>();
    const [processes, setProcesses] = useState<Process[]>([]);
    const DialogComponent = useMemo(
        () => BindProps(UpdateProcessDialog, {
            users,
            groups,
            macros,
            calendarConfig: config
        }),
        [users, groups, macros, config]
    );

    useEffect(() => {
        getOrgUsers().then((res) => setUsers(res.data));
        getOrgGroups().then((res) => setGroups(res.data));
        getMacrosList({ limit: 10000 }).then((res) => setMacros(res.data));
        getCalendarConfig().then((res) => setConfig(transformConfig(res.data)));
    }, []);

    function onSubmit(value: WithOptional<Process, '_id'>) {
        const isUpdateDialog = !!value._id;
        const existingProcess = isUpdateDialog ? processes.find(p => p._id === value._id) : undefined;
        const existingNsIds = existingProcess?.ns_list?.map(ns => ns._id) ?? [];
        const nextstepList = value.ns_list.map((ns, index) => ({ ...ns, index }));
        const [createOrUpdateList, unchangedList] = _.partition(nextstepList, (ns) => {
            if (!ns.parent) {
                return true;
            }
            if (typeof ns.parent === 'string') {
                return true;
            }
            return !_.isEqual(ns.definition, ns.parent.definition) ||
                !_.isEqual(ns.title, ns.parent.title) ||
                ns.domain !== ns.parent.domain;
        });
        const [updateList, createList] = _.partition(
            createOrUpdateList,
            (ns) => existingNsIds.includes(ns._id)
        );
        const process = {
            ...(isUpdateDialog && { _id: value._id }),
            title: value.title,
            active: value.active,
            ns_list:
                createList.concat(
                    unchangedList.map(ns => ({ ...ns, _id: (ns.parent as NextStepInstance)._id })),
                    updateList.map(ns => ({ ...ns, _id: (ns.parent as NextStepInstance)._id }))
                )
                    .map(ns => ({ _id: ns._id, index: ns.index }))
        } as UpdateProcessDto;
        return Promise.all([
            ...(createList.length > 0
                ? [postCreateNextStepMulti(createList.map(({ index, parent, ...ns }) => ns))]
                : []),
            ...(updateList.length > 0
                ? [putUpdateNextStepMulti(updateList.map(({ index, parent, ...ns }) => ns))]
                : [])
        ])
            .then(() => isUpdateDialog ? putUpdateProcess(process) : postCreateProcess(process))
            .then(() => {
                toast(isUpdateDialog ? 'Updated' : 'Created', { type: 'success' });
            })
            .catch(handleNetworkError);
    }

    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CrudTable<Process, '_id'>
                    idKey="_id"
                    schema={schema}
                    initialState={{ columnVisibility: { _id: false } }}
                    list={processes}
                    onChangeList={setProcesses}
                    onRead={() => getProcessList()
                        .then(res => {
                            for (const process of res.data) {
                                process.ns_list.sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
                                    .forEach(ns => { delete ns.index; });
                            }
                            return res.data;
                        })}
                    onCreate={onSubmit}
                    onUpdate={onSubmit}
                    dialogComponent={DialogComponent}
                />
            </CardContent>
        </>
    );
}
