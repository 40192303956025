import React, { useEffect, useMemo, useState } from 'react';
import { useNeoForm } from '@/composables/neoform';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { newStateFromAction } from '@/composables/utils';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { DataTable } from '@/components/ui/data-table';
import { type AccessorKeyColumnDef } from '@tanstack/react-table';
import { getFieldError } from '@/composables/validation';
import { type ControllerProps } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import _ from 'lodash';

interface Props {
    columns: Array<AccessorKeyColumnDef<any> & { header: TranslationObject | string }>;
    dataPath: string;
    enablePagination?: boolean;
    maxSelectionCount?: number;
    forceSingleSelect?: boolean;
}

export function InputTableSelect(props: NeoFormComponentProps & Props) {
    const { to } = useTranslation('neoform.table');
    const { hookForm, form, id, getValidationRules } = useNeoForm();
    const validate = getValidationRules(props);
    const columns = useMemo(
        () => props.columns.map(c => ({ ...c, header: to(c.header) })),
        [props.columns]
    );
    const forceSingleSelect = props.forceSingleSelect ?? true;
    const selection: number[] = hookForm.watch(id) ?? [];
    const rowSelection = useMemo(
        () => selection.reduce((obj, id) => ({ ...obj, [id]: true }), {}),
        [selection]
    );
    const [isForcedSelection, setIsForcedSelection] = useState(false);
    const [data, setData] = useState<any[]>(getTableData());

    useEffect(() => {
        const { unsubscribe } = hookForm.watch(() => {
            const newData = getTableData();
            const isEqual = _.isEqual(
                data,
                newData
            );
            if (!isEqual) {
                setData(newData);
            }
        });
        return () => unsubscribe();
    }, [data]);

    useEffect(() => {
        if (forceSingleSelect && data.length === 1) {
            hookForm.setValue(id, [0]);
            setIsForcedSelection(true);
        } else if (isForcedSelection && data.length !== 1) {
            hookForm.setValue(id, []);
            setIsForcedSelection(false);
        }
    }, [data]);

    function getTableData() {
        const list: any[] = hookForm.getValues(props.dataPath) ?? [];
        return (list ?? [])
            .filter(obj => !obj.deleted)
            .map<any>((obj) => _.pick(obj, columns.map(c => c.accessorKey)));
    }

    function renderTable({ field, formState }: Parameters<ControllerProps['render']>[0]) {
        const error = !!getFieldError(id, formState);
        return (
            <FormItem
                ref={(ref) => {
                    field.ref(ref);
                    form?.ref(id, ref);
                }}
            >
                <FormControl>
                    <DataTable
                        error={error}
                        columns={columns}
                        data={data}
                        state={{
                            rowSelection
                        }}
                        maxSelectionCount={props.maxSelectionCount}
                        enablePagination={props.enablePagination ?? false}
                        enableRowSelection
                        enableRowSelectionCheckboxLabel
                        enableColumnFilters={false}
                        enableHiding={false}
                        enableCsvExport={false}
                        enableGlobalFilter={false}
                        onRowSelectionChange={(value) => {
                            if (forceSingleSelect && getTableData().length === 1) {
                                return;
                            }
                            const newSelection = newStateFromAction(rowSelection, value);
                            const savedSelection = Object.entries(newSelection)
                                .filter(([_, selected]) => selected)
                                .map(([id]) => Number(id));
                            field.onChange(savedSelection);
                            form?.set(id, savedSelection);
                            setIsForcedSelection(false);
                        }}
                    />
                </FormControl>
                <FormMessage />
            </FormItem>
        );
    }

    return (
        <FormField
            name={id}
            render={renderTable}
            rules={{ validate }}
        />
    );
}
