import { type TranslationObject, useTranslation } from '@/composables/translation';
import { CrudInputType, type CrudSchema } from '@/components/ui/crud-table';
import { useMemo } from 'react';

export function useActivitySchema(): CrudSchema<UpdateActivityDto> {
    const { t } = useTranslation('psj.time-log.activities');
    return useMemo(() => [
        {
            id: 'name',
            type: CrudInputType.TEXT,
            name: t('name')
        },
        {
            id: 'title',
            type: CrudInputType.TEXT,
            name: t('title'),
            translate: true
        }
    ], []);
}

export interface Activity {
    _id: string;
    type: 'ns' | 'time-activity';
    name?: string;
    title: TranslationObject;
}

export interface CreateActivityDto extends Omit<Activity, 'id' | 'type'> {}

export interface UpdateActivityDto extends Omit<Activity, 'type'> {}

export interface GetActivitiesOptions {
    case_id?: string;
}
