import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { cn } from '@/lib/utils';
import { FocusScope } from '@radix-ui/react-focus-scope';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverContent = React.forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({
    className,
    align = 'center',
    sideOffset = 4,
    ...props
}, ref) => (
    <PopoverPrimitive.Portal>
        <FocusScope asChild loop trapped>
            <PopoverPrimitive.Content
                ref={ref}
                align={align}
                sideOffset={sideOffset}
                className={cn(
                    'tw-z-50 tw-w-72 tw-rounded-md tw-border tw-bg-popover tw-p-4 tw-text-popover-foreground',
                    'data-[state=open]:tw-animate-in tw-shadow-md tw-outline-none',
                    'data-[state=closed]:tw-animate-out',
                    'data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0',
                    'data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95',
                    'data-[side=bottom]:tw-slide-in-from-top-2 data-[side=left]:tw-slide-in-from-right-2',
                    'data-[side=right]:tw-slide-in-from-left-2 data-[side=top]:tw-slide-in-from-bottom-2',
                    className
                )}
                {...props}
            />
        </FocusScope>
    </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };
