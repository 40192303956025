import './suppress-errors';
import './luxon';

import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import router from '@/router';
import store from '@/store/old/store';
import { useUserStore } from '@/store/user';

// import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/style/global.scss';
import 'react-toastify/dist/ReactToastify.css';
import { TooltipProvider } from '@/components/ui/tooltip';

import Sortable, { MultiDrag } from 'sortablejs';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

Sortable.mount(new MultiDrag());

function App() {
    const lang = useUserStore(state => state.lang);
    return (
        <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}>
            <TooltipProvider>
                <Provider store={store}>
                    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={lang}>
                        <RouterProvider router={router} />
                    </LocalizationProvider>
                </Provider>
            </TooltipProvider>
        </GoogleReCaptchaProvider>
    );
}

if (import.meta.env.VITE_ENV !== 'dev') {
    Sentry.init({
        dsn: 'https://89a3141b90ef53de43fd6914879f34e4@o4506316961480704.ingest.us.sentry.io/4507107913433088',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        environment: import.meta.env.VITE_ENV,
        // Session Replay
        // This sets the sample rate at 10%. You may want to change it to 100%
        // while in development and then sample at a lower rate in production.
        replaysSessionSampleRate: 0.1,
        // If you're not already sampling the entire session, change the sample
        // rate to 100% when sampling sessions where errors occur.
        replaysOnErrorSampleRate: 1.0
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(<App />);
